import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { DOMAIN } from "../../config/Url"
import { Base64Decode } from "../../modules/helper"
import { publicUserOperations } from "../../redux/publicUser"
import Button from "../Button/Button"
import Popup from "../Popup"

const PopupLegal = ({
    closePopup,
    onGetLegalInfoByType,
    getLegalInfoByType,
    lang,
    id
}) => {

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')

    useEffect(()=>{
        onGetLegalInfoByType({DOMAIN, DEFAULT_COLUMN:lang, DOCUMENT_TYPE:id})
    }, [])

    useEffect(()=>{
        if(Object.keys(getLegalInfoByType).length > 0){
            const {DESCRIPTION_ITEM, TITLE_ITEM} = getLegalInfoByType
            setTitle(Base64Decode(TITLE_ITEM))
            setDesc(Base64Decode(DESCRIPTION_ITEM))
        }
    }, [getLegalInfoByType])

    return (
        <Popup overflow title={title} width={'1000px'} closePopup={closePopup}>
            <p style={{margin: '8px 20px'}} dangerouslySetInnerHTML={{__html:desc.replace(/\n/g, "<br/> ")}}></p>
            <div className="text-center">
                <Button className="button-16"  onClick={()=>{closePopup({status:0})}}>close</Button>
                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{closePopup({status:0})}}>Close</button> */}
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang: state.config.LANG,
    getLegalInfoByType:state.publicUser.getLegalInfoByType,
})

const mapDispatchToProps = {
    onGetLegalInfoByType: publicUserOperations.getLegalInfoByType,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupLegal);
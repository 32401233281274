// import { combineReducers, createStore } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import phonebookReducer from './../redux/phonebook/phonebook-reducer'
import { authReducers } from './../redux/auth';
import { modelReducers } from './../redux/model';
import { jsonReducers } from './../redux/json';
import { configReducers } from './config';
import { publicUserReducers } from './publicUser';
import { privateUserReducers } from './privateUser';
import logger from 'redux-logger'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import {Map} from 'immutable';
// import {
//   Models as PlannerModels,
//   reducer as PlannerReducer,
//   ReactPlanner,
//   Plugins as PlannerPlugins,
// } from 'react-planner';

// import {Catalog} from 'react-planner';

// import {BoxGeometry, MeshBasicMaterial, Mesh, BoxHelper} from 'three';

// const rootPhonebookReducer = combineReducers({
//     phonebook:phonebookReducer
// })
// const store = createStore(rootPhonebookReducer, composeWithDevTools());

const middleware = [...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }), logger]

// const persistConfig = {
//     key: 'phonebook',
//     storage,
//     blacklist:['filter']
//   }


// const authPersistConfig = {
//   key:'auth',
//   storage,
//   whitelist:['token', 'fullAdminName', 'balance', 'isMarketplace']
// }

// const jsonPersistConfig = {
//   key:'json',
//   storage,
//   whitelist:['result']
// }

const configPersistConfig = {
  key:'config',
  storage,
  whitelist:[
    'lang', 
    'currency', 
    'showFilter', 
    'pinCodeStatus',
    'isAuthenticated',
    'token',
    'resultUser',
    'orderId',
    'flag'
  ]
}

// const modelPersistConfig = {
//   key:'model',
//   storage,
//   whitelist:[],
// }

const publicUserPersistConfig = {
  key:'publicUser',
  storage,
  whitelist:[],
}

const privateUserPersistConfig = {
  key:'privateUser',
  storage,
  whitelist:[],
}

   

const store = configureStore({
    // reducer:{
    //     phonebook:phonebookReducer
    // },
    reducer:{
      // model:modelReducers,
      // model:persistReducer(modelPersistConfig, modelReducers),
      // auth:persistReducer(authPersistConfig, authReducers),
      // json:persistReducer(jsonPersistConfig, jsonReducers),publicUserReducers
      config:persistReducer(configPersistConfig, configReducers),
      publicUser:persistReducer(publicUserPersistConfig, publicUserReducers),
      privateUser:persistReducer(privateUserPersistConfig, privateUserReducers),
    },
    middleware,
    devTools:process.env.NODE_ENV === 'development'
})

const persistor = persistStore(store)

export default {store, persistor};

import { Base64 } from "js-base64";
const isBase64 = (str) => {
    if(str.match('/^[a-zA-Z0-9\/\r\n+]*={0,2}$/')) return false

    const decode = Base64.decode(str);
    if(decode === false) return false;

    if(Base64.encode(decode) !== str) return false;

    return true
}

export const Base64Decode = (title) => {

    if(title === ''){
        return('');
    }

    try{
        if(isBase64(title))
            return(Base64.decode(title))
        else return(title);
        
    }catch(e){
        return(title)
    }

}

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return {sizeView:'', size:0};
    const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return {
        sizeView:`${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`,
        size:Number((bytes / (1024 ** i)).toFixed(2))
    };
}
export const replaceII = (text) =>{
    if(!Boolean(text)) return '<div />';
    if(text === '') return '<div />';
    if(text === true || text === false) return '<div />';
     return text.replace(/i/g, '<span style="color:#4572C2">i</span>');
}
export const autoHtml = () => {
    const html = document.querySelector('body')
    
}
const reverseString = str => {
    if (str === "")
      return "";
    else
      return reverseString(str.substr(1)) + str.charAt(0);
  }

export const setPricePoint = price => {

    const pr =  Math.floor(Number(price))
    const double = Number((Number(price) - pr).toFixed(2)) * 100;

    let arr  = []

    const copyPrice = reverseString(String(pr))

    for(let i = 0; i < copyPrice.length; i = i + 3 ){
        arr.push(reverseString(copyPrice.slice(i, i+3)))
    }

    return `${arr.reverse().join('.')}${double === 0 ? '' : `${['', Number(double)].join('.')}`}`
}

import axios from 'axios';
import {PUBLIC_USER} from './../config/Url'
import { configActions } from './config';

export const instance = axios.create({
    baseURL: PUBLIC_USER,
    headers: { "Access-Control-Allow-Origin": "*", }
});

export const publicRequest = (state, dispatch, {disableToken=false}={}) => {

        
        instance.interceptors.request.use(config => {
            dispatch(configActions.configHttpRequest())
            if(disableToken) config.headers.Authorization = Boolean(state().config.token.access_token) ? `Bearer ${state().config.token.access_token}` : '';
            if(!disableToken) config.headers.Authorization = ``;
            // config.headers.Authorization = ``;
            return config;
        
        }, error => {

            dispatch(configActions.configHttpError(error))
            return Promise.reject(error);
        });
        
        // instance.interceptors.response.use(response => {
        //     dispatch(configActions.configHttpSuccess(response.data))

        //     return response;
        // }, error => {

        //     if(error.response.status === 401){
        //         dispatch(configActions.status401())
        //         return
        //     }

        //     // console.log('public', error);
        //     dispatch(configActions.configHttpError(error))
        //     return Promise.reject(error);
        // });

        instance.interceptors.response.use(response => {

            const {status} = response
            const originalRequest = response.config;
            if(status === 401){
                dispatch(configActions.status401())

                return instance(originalRequest);
            }
            dispatch(configActions.configHttpSuccess(response.data))
            return response;
        }, error => {
            const status = error.response.status
            const originalRequest = error.config;
            // console.log('public error', error.response.status);
            if(status === 401){
                dispatch(configActions.status401())
                return instance(originalRequest);
            }

            dispatch(configActions.configHttpError(error))
            return Promise.reject(error);
        });

}


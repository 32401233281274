import {createAction} from '@reduxjs/toolkit';

export default{

    // sendTestEmailRequest:createAction('model/sendTestEmailRequest'),
    // sendTestEmailSuccess:createAction('model/sendTestEmailSuccess'),
    // sendTestEmailError:createAction('model/sendTestEmailError'),

    getUserAddressRequest:createAction('model/getUserAddressRequest'),
    getUserAddressSuccess:createAction('model/getUserAddressSuccess'),
    getUserAddressError:createAction('model/getUserAddressError'),

    updateUserAddressRequest:createAction('model/updateUserAddressRequest'),
    updateUserAddressSuccess:createAction('model/updateUserAddressSuccess'),
    updateUserAddressError:createAction('model/updateUserAddressError'),

    getUserProfileRequest:createAction('model/getUserProfileRequest'),
    getUserProfileSuccess:createAction('model/getUserProfileSuccess'),
    getUserProfileError:createAction('model/getUserProfileError'),

    getUserOrdersHistoryRequest:createAction('model/getUserOrdersHistoryRequest'),
    getUserOrdersHistorySuccess:createAction('model/getUserOrdersHistorySuccess'),
    getUserOrdersHistoryError:createAction('model/getUserOrdersHistoryError'),

    updateUserProfileRequest:createAction('model/updateUserProfileRequest'),
    updateUserProfileSuccess:createAction('model/updateUserProfileSuccess'),
    updateUserProfileError:createAction('model/updateUserProfileError'),

    getCartRequest:createAction('model/getCartRequest'),
    getCartSuccess:createAction('model/getCartSuccess'),
    getCartError:createAction('model/getCartError'),

    getAddProductToCartRequest:createAction('model/getAddProductToCartRequest'),
    getAddProductToCartSuccess:createAction('model/getAddProductToCartSuccess'),
    getAddProductToCartError:createAction('model/getAddProductToCartError'),

    logoutUserRequest:createAction('model/logoutUserRequest'),
    logoutUserSuccess:createAction('model/logoutUserSuccess'),
    logoutUserError:createAction('model/logoutUserError'),

    updateVendorProfileRequest:createAction('model/updateVendorProfileRequest'),
    updateVendorProfileSuccess:createAction('model/updateVendorProfileSuccess'),
    updateVendorProfileError:createAction('model/updateVendorProfileError'),

    getVendorProfileRequest:createAction('model/getVendorProfileRequest'),
    getVendorProfileSuccess:createAction('model/getVendorProfileSuccess'),
    getVendorProfileError:createAction('model/getVendorProfileError'),

    updateVendorPasswordRequest:createAction('model/updateVendorPasswordRequest'),
    updateVendorPasswordSuccess:createAction('model/updateVendorPasswordSuccess'),
    updateVendorPasswordError:createAction('model/updateVendorPasswordError'),

    getVendorProductsWithFilterRequest:createAction('model/getVendorProductsWithFilterRequest'),
    getVendorProductsWithFilterSuccess:createAction('model/getVendorProductsWithFilterSuccess'),
    getVendorProductsWithFilterError:createAction('model/getVendorProductsWithFilterError'),

    getNewProductIdRequest:createAction('model/getNewProductIdRequest'),
    getNewProductIdSuccess:createAction('model/getNewProductIdSuccess'),
    getNewProductIdError:createAction('model/getNewProductIdError'),
    getNewProductIdClear:createAction('model/getNewProductIdClear'),

    updateProductInfoGeneralRequest:createAction('model/updateProductInfoGeneralRequest'),
    updateProductInfoGeneralSuccess:createAction('model/updateProductInfoGeneralSuccess'),
    updateProductInfoGeneralError:createAction('model/updateProductInfoGeneralError'),

    getProductInfoGeneralRequest:createAction('model/getProductInfoGeneralRequest'),
    getProductInfoGeneralSuccess:createAction('model/getProductInfoGeneralSuccess'),
    getProductInfoGeneralError:createAction('model/getProductInfoGeneralError'),
    getProductInfoGeneralClear:createAction('model/getProductInfoGeneralClear'),

    removeProductImageRequest:createAction('model/removeProductImageRequest'),
    removeProductImageSuccess:createAction('model/removeProductImageSuccess'),
    removeProductImageError:createAction('model/removeProductImageError'),

    getProductImagesRequest:createAction('model/getProductImagesRequest'),
    getProductImagesSuccess:createAction('model/getProductImagesSuccess'),
    getProductImagesError:createAction('model/getProductImagesError'),
    getProductImagesClear:createAction('model/getProductImagesClear'),

    createUpdateProductImageRequest:createAction('model/createUpdateProductImageRequest'),
    createUpdateProductImageSuccess:createAction('model/createUpdateProductImageSuccess'),
    createUpdateProductImageError:createAction('model/createUpdateProductImageError'),

    removeProductByIdRequest:createAction('model/removeProductByIdRequest'),
    removeProductByIdSuccess:createAction('model/removeProductByIdSuccess'),
    removeProductByIdError:createAction('model/removeProductByIdError'),

    getMeasuresRequest:createAction('model/getMeasuresRequest'),
    getMeasuresSuccess:createAction('model/getMeasuresSuccess'),
    getMeasuresError:createAction('model/getMeasuresError'),

    updateSpecialPriceProductOrOptionRequest:createAction('model/updateSpecialPriceProductOrOptionRequest'),
    updateSpecialPriceProductOrOptionSuccess:createAction('model/updateSpecialPriceProductOrOptionSuccess'),
    updateSpecialPriceProductOrOptionError:createAction('model/updateSpecialPriceProductOrOptionError'),

    getProductSettingsDiscountRequest:createAction('model/getProductSettingsDiscountRequest'),
    getProductSettingsDiscountSuccess:createAction('model/getProductSettingsDiscountSuccess'),
    getProductSettingsDiscountError:createAction('model/getProductSettingsDiscountError'),

    setProductPercentCachbackRequest:createAction('model/setProductPercentCachbackRequest'),
    setProductPercentCachbackSuccess:createAction('model/setProductPercentCachbackSuccess'),
    setProductPercentCachbackError:createAction('model/setProductPercentCachbackError'),

    createFeedbackRequest:createAction('model/createFeedbackRequest'),
    createFeedbackSuccess:createAction('model/createFeedbackSuccess'),
    createFeedbackError:createAction('model/createFeedbackError'),

    createFeedbackLikeRequest:createAction('model/createFeedbackLikeRequest'),
    createFeedbackLikeSuccess:createAction('model/createFeedbackLikeSuccess'),
    createFeedbackLikeError:createAction('model/createFeedbackLikeError'),

    createFeedbackDislikeRequest:createAction('model/createFeedbackDislikeRequest'),
    createFeedbackDislikeSuccess:createAction('model/createFeedbackDislikeSuccess'),
    createFeedbackDislikeError:createAction('model/createFeedbackDislikeError'),

    getCartRequest:createAction('model/getCartRequest'),
    getCartSuccess:createAction('model/getCartSuccess'),
    getCartError:createAction('model/getCartError'),

    addProductToCartRequest:createAction('model/addProductToCartRequest'),
    addProductToCartSuccess:createAction('model/addProductToCartSuccess'),
    addProductToCartError:createAction('model/addProductToCartError'),

    removeProductFromCartRequest:createAction('model/removeProductFromCartRequest'),
    removeProductFromCartSuccess:createAction('model/removeProductFromCartSuccess'),
    removeProductFromCartError:createAction('model/removeProductFromCartError'),

    updateProductQtyInCartRequest:createAction('model/updateProductQtyInCartRequest'),
    updateProductQtyInCartSuccess:createAction('model/updateProductQtyInCartSuccess'),
    updateProductQtyInCartError:createAction('model/updateProductQtyInCartError'),

    proceedToCheckoutRequest:createAction('model/proceedToCheckoutRequest'),
    proceedToCheckoutSuccess:createAction('model/proceedToCheckoutSuccess'),
    proceedToCheckoutError:createAction('model/proceedToCheckoutError'),

    getReloadHistoryRequest:createAction('model/getReloadHistoryRequest'),
    getReloadHistorySuccess:createAction('model/getReloadHistorySuccess'),
    getReloadHistoryError:createAction('model/getReloadHistoryError'),

    reloadProceedToCheckoutRequest:createAction('model/reloadProceedToCheckoutRequest'),
    reloadProceedToCheckoutSuccess:createAction('model/reloadProceedToCheckoutSuccess'),
    reloadProceedToCheckoutError:createAction('model/reloadProceedToCheckoutError'),

    paymentSystemsCoinsAmountRequest:createAction('model/paymentSystemsCoinsAmountRequest'),
    paymentSystemsCoinsAmountSuccess:createAction('model/paymentSystemsCoinsAmountSuccess'),
    paymentSystemsCoinsAmountError:createAction('model/paymentSystemsCoinsAmountError'),

    transactionBuyCoinsRequest:createAction('model/transactionBuyCoinsRequest'),
    transactionBuyCoinsSuccess:createAction('model/transactionBuyCoinsSuccess'),
    transactionBuyCoinsError:createAction('model/transactionBuyCoinsError'),

    getPaypalClientIdRequest:createAction('model/getPaypalClientIdRequest'),
    getPaypalClientIdSuccess:createAction('model/getPaypalClientIdSuccess'),
    getPaypalClientIdError:createAction('model/getPaypalClientIdError'),

    transactionBuyCoinsPaypalRequest:createAction('model/transactionBuyCoinsPaypalRequest'),
    transactionBuyCoinsPaypalSuccess:createAction('model/transactionBuyCoinsPaypalSuccess'),
    transactionBuyCoinsPaypalError:createAction('model/transactionBuyCoinsPaypalError'),

    getBraintreeTokenRequest:createAction('model/getBraintreeTokenRequest'),
    getBraintreeTokenSuccess:createAction('model/getBraintreeTokenSuccess'),
    getBraintreeTokenError:createAction('model/getBraintreeTokenError'),

    getSquareupSettingsRequest:createAction('model/getSquareupSettingsRequest'),
    getSquareupSettingsSuccess:createAction('model/getSquareupSettingsSuccess'),
    getSquareupSettingsError:createAction('model/getSquareupSettingsError'),

    getStripTokenRequest:createAction('model/getStripTokenRequest'),
    getStripTokenSuccess:createAction('model/getStripTokenSuccess'),
    getStripTokenError:createAction('model/getStripTokenError'),

    getStripClientSecretRequest:createAction('model/getStripClientSecretRequest'),
    getStripClientSecretSuccess:createAction('model/getStripClientSecretSuccess'),
    getStripClientSecretError:createAction('model/getStripClientSecretError'),

    transactionBuyCoinsStripRequest:createAction('model/transactionBuyCoinsStripRequest'),
    transactionBuyCoinsStripSuccess:createAction('model/transactionBuyCoinsStripSuccess'),
    transactionBuyCoinsStripError:createAction('model/transactionBuyCoinsStripError'),

    transactionBuyCoinsSquareupRequest:createAction('model/transactionBuyCoinsSquareupRequest'),
    transactionBuyCoinsSquareupSuccess:createAction('model/transactionBuyCoinsSquareupSuccess'),
    transactionBuyCoinsSquareupError:createAction('model/transactionBuyCoinsSquareupError'),

    transactionBuyCoinsBraintreeRequest:createAction('model/transactionBuyCoinsBraintreeRequest'),
    transactionBuyCoinsBraintreeSuccess:createAction('model/transactionBuyCoinsBraintreeSuccess'),
    transactionBuyCoinsBraintreeError:createAction('model/transactionBuyCoinsBraintreeError'),

    saveProceedToCheckoutRequest:createAction('model/saveProceedToCheckoutRequest'),
    saveProceedToCheckoutSuccess:createAction('model/saveProceedToCheckoutSuccess'),
    saveProceedToCheckoutError:createAction('model/saveProceedToCheckoutError'),

    checkoutCryptoOrderRequest:createAction('model/checkoutCryptoOrderRequest'),
    checkoutCryptoOrderSuccess:createAction('model/checkoutCryptoOrderSuccess'),
    checkoutCryptoOrderError:createAction('model/checkoutCryptoOrderError'),

    userTransactionPaypalRequest:createAction('model/userTransactionPaypalRequest'),
    userTransactionPaypalSuccess:createAction('model/userTransactionPaypalSuccess'),
    userTransactionPaypalError:createAction('model/userTransactionPaypalError'),

    userTransactionSquareupRequest:createAction('model/userTransactionSquareupRequest'),
    userTransactionSquareupSuccess:createAction('model/userTransactionSquareupSuccess'),
    userTransactionSquareupError:createAction('model/userTransactionSquareupError'),

    userTransactionBraintreeRequest:createAction('model/userTransactionBraintreeRequest'),
    userTransactionBraintreeSuccess:createAction('model/userTransactionBraintreeSuccess'),
    userTransactionBraintreeError:createAction('model/userTransactionBraintreeError'),

    userTransactionStripRequest:createAction('model/userTransactionStripRequest'),
    userTransactionStripSuccess:createAction('model/userTransactionStripSuccess'),
    userTransactionStripError:createAction('model/userTransactionStripError'),

    userTransactionCoinsRequest:createAction('model/userTransactionCoinsRequest'),
    userTransactionCoinsSuccess:createAction('model/userTransactionCoinsSuccess'),
    userTransactionCoinsError:createAction('model/userTransactionCoinsError'),

    updateProductDeliveryParametersRequest:createAction('model/updateProductDeliveryParametersRequest'),
    updateProductDeliveryParametersSuccess:createAction('model/updateProductDeliveryParametersSuccess'),
    updateProductDeliveryParametersError:createAction('model/updateProductDeliveryParametersError'),

    getProductDeliveryParametersRequest:createAction('model/getProductDeliveryParametersRequest'),
    getProductDeliveryParametersSuccess:createAction('model/getProductDeliveryParametersSuccess'),
    getProductDeliveryParametersError:createAction('model/getProductDeliveryParametersError'),

    getOrdersHistoryRequest:createAction('model/getOrdersHistoryRequest'),
    getOrdersHistorySuccess:createAction('model/getOrdersHistorySuccess'),
    getOrdersHistoryError:createAction('model/getOrdersHistoryError'),

    getShareSalesProfitListRequest:createAction('model/getShareSalesProfitListRequest'),
    getShareSalesProfitListSuccess:createAction('model/getShareSalesProfitListSuccess'),
    getShareSalesProfitListError:createAction('model/getShareSalesProfitListError'),

    createShareSalesProfitRequest:createAction('model/createShareSalesProfitRequest'),
    createShareSalesProfitSuccess:createAction('model/createShareSalesProfitSuccess'),
    createShareSalesProfitError:createAction('model/createShareSalesProfitError'),

    getOrdersDeliveryRequest:createAction('model/getOrdersDeliveryRequest'),
    getOrdersDeliverySuccess:createAction('model/getOrdersDeliverySuccess'),
    getOrdersDeliveryError:createAction('model/getOrdersDeliveryError'),

    getDeliveryTypesRequest:createAction('model/getDeliveryTypesRequest'),
    getDeliveryTypesSuccess:createAction('model/getDeliveryTypesSuccess'),
    getDeliveryTypesError:createAction('model/getDeliveryTypesError'),

    updateOrdersDeliveryInfoRequest:createAction('model/updateOrdersDeliveryInfoRequest'),
    updateOrdersDeliveryInfoSuccess:createAction('model/updateOrdersDeliveryInfoSuccess'),
    updateOrdersDeliveryInfoError:createAction('model/updateOrdersDeliveryInfoError'),

    updateOrdersDeliveryStatusSendRequest:createAction('model/updateOrdersDeliveryStatusSendRequest'),
    updateOrdersDeliveryStatusSendSuccess:createAction('model/updateOrdersDeliveryStatusSendSuccess'),
    updateOrdersDeliveryStatusSendError:createAction('model/updateOrdersDeliveryStatusSendError'),

    getDeliveryInfoRequest:createAction('model/getDeliveryInfoRequest'),
    getDeliveryInfoSuccess:createAction('model/getDeliveryInfoSuccess'),
    getDeliveryInfoError:createAction('model/getDeliveryInfoError'),

    getUserOrderByIdRequest:createAction('model/getUserOrderByIdRequest'),
    getUserOrderByIdSuccess:createAction('model/getUserOrderByIdSuccess'),
    getUserOrderByIdError:createAction('model/getUserOrderByIdError'),

    getBalancesInfoRequest:createAction('model/getBalancesInfoRequest'),
    getBalancesInfoSuccess:createAction('model/getBalancesInfoSuccess'),
    getBalancesInfoError:createAction('model/getBalancesInfoError'),

    getPayoutMethodNamesRequest:createAction('model/getPayoutMethodNamesRequest'),
    getPayoutMethodNamesSuccess:createAction('model/getPayoutMethodNamesSuccess'),
    getPayoutMethodNamesError:createAction('model/getPayoutMethodNamesError'),

    getPayoutMethodTypesRequest:createAction('model/getPayoutMethodTypesRequest'),
    getPayoutMethodTypesSuccess:createAction('model/getPayoutMethodTypesSuccess'),
    getPayoutMethodTypesError:createAction('model/getPayoutMethodTypesError'),

    getPayoutMethodsRequest:createAction('model/getPayoutMethodsRequest'),
    getPayoutMethodsSuccess:createAction('model/getPayoutMethodsSuccess'),
    getPayoutMethodsError:createAction('model/getPayoutMethodsError'),

    createUpdatePayoutMethodRequest:createAction('model/createUpdatePayoutMethodRequest'),
    createUpdatePayoutMethodSuccess:createAction('model/createUpdatePayoutMethodSuccess'),
    createUpdatePayoutMethodError:createAction('model/createUpdatePayoutMethodError'),

    createPayoutRequest:createAction('model/createPayoutRequest'),
    createPayoutSuccess:createAction('model/createPayoutSuccess'),
    createPayoutError:createAction('model/createPayoutError'),

    getInfoRequest:createAction('model/getInfoRequest'),
    getInfoSuccess:createAction('model/getInfoSuccess'),
    getInfoError:createAction('model/getInfoError'),

    vendorGetReloadHistoryRequest:createAction('model/vendorGetReloadHistoryRequest'),
    vendorGetReloadHistorySuccess:createAction('model/vendorGetReloadHistorySuccess'),
    vendorGetReloadHistoryError:createAction('model/vendorGetReloadHistoryError'),

    vendorTransactionBuyCoinsBraintreeRequest:createAction('model/vendorTransactionBuyCoinsBraintreeRequest'),
    vendorTransactionBuyCoinsBraintreeSuccess:createAction('model/vendorTransactionBuyCoinsBraintreeSuccess'),
    vendorTransactionBuyCoinsBraintreeError:createAction('model/vendorTransactionBuyCoinsBraintreeError'),

    vendorTransactionBuyCoinsPaypalRequest:createAction('model/vendorTransactionBuyCoinsPaypalRequest'),
    vendorTransactionBuyCoinsPaypalSuccess:createAction('model/vendorTransactionBuyCoinsPaypalSuccess'),
    vendorTransactionBuyCoinsPaypalError:createAction('model/vendorTransactionBuyCoinsPaypalError'),

    vendorTransactionBuyCoinsSquareupRequest:createAction('model/vendorTransactionBuyCoinsSquareupRequest'),
    vendorTransactionBuyCoinsSquareupSuccess:createAction('model/vendorTransactionBuyCoinsSquareupSuccess'),
    vendorTransactionBuyCoinsSquareupError:createAction('model/vendorTransactionBuyCoinsSquareupError'),

    vendorTransactionBuyCoinsStripRequest:createAction('model/vendorTransactionBuyCoinsStripRequest'),
    vendorTransactionBuyCoinsStripSuccess:createAction('model/vendorTransactionBuyCoinsStripSuccess'),
    vendorTransactionBuyCoinsStripError:createAction('model/vendorTransactionBuyCoinsStripError'),

    vendorTransactionBuyCoinsRequest:createAction('model/vendorTransactionBuyCoinsRequest'),
    vendorTransactionBuyCoinsSuccess:createAction('model/vendorTransactionBuyCoinsSuccess'),
    vendorTransactionBuyCoinsError:createAction('model/vendorTransactionBuyCoinsError'),

    getTransactionsPayoutRequest:createAction('model/getTransactionsPayoutRequest'),
    getTransactionsPayoutSuccess:createAction('model/getTransactionsPayoutSuccess'),
    getTransactionsPayoutError:createAction('model/getTransactionsPayoutError'),

    addProductToWishlistRequest:createAction('model/addProductToWishlistRequest'),
    addProductToWishlistSuccess:createAction('model/addProductToWishlistSuccess'),
    addProductToWishlistError:createAction('model/addProductToWishlistError'),

    removeProductFromWishlistRequest:createAction('model/removeProductFromWishlistRequest'),
    removeProductFromWishlistSuccess:createAction('model/removeProductFromWishlistSuccess'),
    removeProductFromWishlistError:createAction('model/removeProductFromWishlistError'),

    getProductCategoriesByProductMarketplaceRequest:createAction('model/getProductCategoriesByProductMarketplaceRequest'),
    getProductCategoriesByProductMarketplaceSuccess:createAction('model/getProductCategoriesByProductMarketplaceSuccess'),
    getProductCategoriesByProductMarketplaceError:createAction('model/getProductCategoriesByProductMarketplaceError'),

    getProductCategoriesByProductMarketplaceBottomRequest:createAction('model/getProductCategoriesByProductMarketplaceBottomRequest'),
    getProductCategoriesByProductMarketplaceBottomSuccess:createAction('model/getProductCategoriesByProductMarketplaceBottomSuccess'),
    getProductCategoriesByProductMarketplaceBottomError:createAction('model/getProductCategoriesByProductMarketplaceBottomError'),

    getProductCategoriesByProductMarketplaceBottomTwoRequest:createAction('model/getProductCategoriesByProductMarketplaceBottomTwoRequest'),
    getProductCategoriesByProductMarketplaceBottomTwoSuccess:createAction('model/getProductCategoriesByProductMarketplaceBottomTwoSuccess'),
    getProductCategoriesByProductMarketplaceBottomTwoError:createAction('model/getProductCategoriesByProductMarketplaceBottomTwoError'),

    linkUnlinkProductCategoryMarketplaceProductRequest:createAction('model/linkUnlinkProductCategoryMarketplaceProductRequest'),
    linkUnlinkProductCategoryMarketplaceProductSuccess:createAction('model/linkUnlinkProductCategoryMarketplaceProductSuccess'),
    linkUnlinkProductCategoryMarketplaceProductError:createAction('model/linkUnlinkProductCategoryMarketplaceProductError'),

    getProductPricingVariationsRequest:createAction('model/getProductPricingVariationsRequest'),
    getProductPricingVariationsSuccess:createAction('model/getProductPricingVariationsSuccess'),
    getProductPricingVariationsError:createAction('model/getProductPricingVariationsError'),

    addProductPricingVariationRequest:createAction('model/addProductPricingVariationRequest'),
    addProductPricingVariationSuccess:createAction('model/addProductPricingVariationSuccess'),
    addProductPricingVariationError:createAction('model/addProductPricingVariationError'),

    removeProductPricingVariationRequest:createAction('model/removeProductPricingVariationRequest'),
    removeProductPricingVariationSuccess:createAction('model/removeProductPricingVariationSuccess'),
    removeProductPricingVariationError:createAction('model/removeProductPricingVariationError'),

    getProductByPricingRequest:createAction('model/getProductByPricingRequest'),
    getProductByPricingSuccess:createAction('model/getProductByPricingSuccess'),
    getProductByPricingError:createAction('model/getProductByPricingError'),


    resetShowNftPublicationMovie:createAction('model/resetShowNftPublicationMovie'),

    getAttributesByProductRequest:createAction('model/getAttributesByProductRequest'),
    getAttributesByProductSuccess:createAction('model/getAttributesByProductSuccess'),
    getAttributesByProductError:createAction('model/getAttributesByProductError'),

    updateProductCategoryRequest:createAction('model/updateProductCategoryRequest'),
    updateProductCategorySuccess:createAction('model/updateProductCategorySuccess'),
    updateProductCategoryError:createAction('model/updateProductCategoryError'),

    getProductCategoriesRequest:createAction('model/getProductCategoriesRequest'),
    getProductCategoriesSuccess:createAction('model/getProductCategoriesSuccess'),
    getProductCategoriesError:createAction('model/getProductCategoriesError'),

    removeAttributeByProductRequest:createAction('model/removeAttributeByProductRequest'),
    removeAttributeByProductSuccess:createAction('model/removeAttributeByProductSuccess'),
    removeAttributeByProductError:createAction('model/removeAttributeByProductError'),

    updateItemPropertiesRequest:createAction('model/updateItemPropertiesRequest'),
    updateItemPropertiesSuccess:createAction('model/updateItemPropertiesSuccess'),
    updateItemPropertiesError:createAction('model/updateItemPropertiesError'),

    getWidgetConvertationsListRequest:createAction('model/getWidgetConvertationsListRequest'),
    getWidgetConvertationsListSuccess:createAction('model/getWidgetConvertationsListSuccess'),
    getWidgetConvertationsListError:createAction('model/getWidgetConvertationsListError'),

    updateItemConvertationWidgetRequest:createAction('model/updateItemConvertationWidgetRequest'),
    updateItemConvertationWidgetSuccess:createAction('model/updateItemConvertationWidgetSuccess'),
    updateItemConvertationWidgetError:createAction('model/updateItemConvertationWidgetError'),

    addRemovePropertiesWidgetByProductRequest:createAction('model/addRemovePropertiesWidgetByProductRequest'),
    addRemovePropertiesWidgetByProductSuccess:createAction('model/addRemovePropertiesWidgetByProductSuccess'),
    addRemovePropertiesWidgetByProductError:createAction('model/addRemovePropertiesWidgetByProductError'),

    updateVisibleGroupNameRequest:createAction('model/updateVisibleGroupNameRequest'),
    updateVisibleGroupNameSuccess:createAction('model/updateVisibleGroupNameSuccess'),
    updateVisibleGroupNameError:createAction('model/updateVisibleGroupNameError'),

    getVisibleGroupNameRequest:createAction('model/getVisibleGroupNameRequest'),
    getVisibleGroupNameSuccess:createAction('model/getVisibleGroupNameSuccess'),
    getVisibleGroupNameError:createAction('model/getVisibleGroupNameError'),

    getWidgetRecordsRequest:createAction('model/getWidgetRecordsRequest'),
    getWidgetRecordsSuccess:createAction('model/getWidgetRecordsSuccess'),
    getWidgetRecordsError:createAction('model/getWidgetRecordsError'),

    getWidgetsNoAttributesProductRequest:createAction('model/getWidgetsNoAttributesProductRequest'),
    getWidgetsNoAttributesProductSuccess:createAction('model/getWidgetsNoAttributesProductSuccess'),
    getWidgetsNoAttributesProductError:createAction('model/getWidgetsNoAttributesProductError'),

    addAttributeByProductRequest:createAction('model/addAttributeByProductRequest'),
    addAttributeByProductSuccess:createAction('model/addAttributeByProductSuccess'),
    addAttributeByProductError:createAction('model/addAttributeByProductError'),

    addPricingAttributeByProductRequest:createAction('model/addPricingAttributeByProductRequest'),
    addPricingAttributeByProductSuccess:createAction('model/addPricingAttributeByProductSuccess'),
    addPricingAttributeByProductError:createAction('model/addPricingAttributeByProductError'),

    notLinkedPricingAttributesByProductRequest:createAction('model/notLinkedPricingAttributesByProductRequest'),
    notLinkedPricingAttributesByProductSuccess:createAction('model/notLinkedPricingAttributesByProductSuccess'),
    notLinkedPricingAttributesByProductError:createAction('model/notLinkedPricingAttributesByProductError'),

    updateUserOrVendorProfileRequest:createAction('model/updateUserOrVendorProfileRequest'),
    updateUserOrVendorProfileSuccess:createAction('model/updateUserOrVendorProfileSuccess'),
    updateUserOrVendorProfileError:createAction('model/updateUserOrVendorProfileError'),

    getUserOrVendorProfileRequest:createAction('model/getUserOrVendorProfileRequest'),
    getUserOrVendorProfileSuccess:createAction('model/getUserOrVendorProfileSuccess'),
    getUserOrVendorProfileError:createAction('model/getUserOrVendorProfileError'),



}
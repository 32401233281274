import axios from 'axios';
import { authOperations } from '../auth';
import { configActions } from '../config';
// import { useSelector } from 'react-redux';
// import { authSelectors } from '../auth';

import { instance, privateRequest } from '../privateRequest';

import privateUserActions from './privateUser-actions';



export default {

    getUserAddress: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getUserAddressRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_address",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getUserAddressSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.getUserAddressError())
        }
    },

    updateUserAddress: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateUserAddressRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_user_address",
                parameters:credential
            });
            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateUserAddressSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.updateUserAddressError())
        }
    },

    getUserProfile: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getUserProfileRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_profile",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getUserProfileSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.getUserProfileError())
        }
    },

    updateUserProfile: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateUserProfileRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_user_profile",
                parameters:credential
            }); 
            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateUserProfileSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.updateUserProfileError())
        }
    },

    updateUserPassword: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateUserPasswordRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_user_password",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.updateUserPasswordSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.updateUserPasswordError())
        }
    },

    getUserOrdersHistory: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getUserOrdersHistoryRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_orders_history",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getUserOrdersHistorySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getUserOrdersHistoryError())
        }
    },

    getUserOrderById: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getUserOrderByIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_order_by_id",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getUserOrderByIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getUserOrderByIdError())
        }
    },

    getUserDeliveryInfoById: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getUserDeliveryInfoByIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_user_delivery_info_by_id",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getUserDeliveryInfoByIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getUserDeliveryInfoByIdError())
        }
    },

    getCart: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getCartRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_cart",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getCartSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.getCartError())
        }
    },

    getAddProductToCart: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getAddProductToCartRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_add_product_to_cart",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getAddProductToCartSuccess(res.data))
    
        }catch (error){
            dispatch(privateUserActions.getAddProductToCartError())
        }
    },

    logoutUser: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.logoutUserRequest())
    
        try{
            const res = await instance.post('/', {
                name:"logout_user",
                parameters:credential
            }); 
            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.logoutUserSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.logoutUserError())
        }
    },

    updateVendorProfile: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateVendorProfileRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_vendor_profile",
                parameters:credential
            }); 
            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateVendorProfileSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateVendorProfileError())
        }
    },

    getVendorProfile: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getVendorProfileRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_vendor_profile",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getVendorProfileSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getVendorProfileError())
        }
    },

    updateVendorPassword: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateVendorPasswordRequest())
    
        try{
            const res = await instance.post('/', {
                name:"update_vendor_password",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.updateVendorPasswordSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateVendorPasswordError())
        }
    },

    getVendorProductsWithFilter: (credential, info) => async (dispatch, state) => {
        privateRequest(state, dispatch, info)
        dispatch(privateUserActions.getVendorProductsWithFilterRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_vendor_products_list",
                parameters:credential
            }); 
            dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getVendorProductsWithFilterSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getVendorProductsWithFilterError())
        }
    },

    getNewProductId: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getNewProductIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"get_new_product_id",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getNewProductIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getNewProductIdError())
        }
    },

    updateProductInfoGeneral: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateProductInfoGeneralRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_update_product_info_general",
                parameters:credential
            }); 
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateProductInfoGeneralSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateProductInfoGeneralError())
        }
    },

    getProductInfoGeneral: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductInfoGeneralRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_get_product_info_general",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getProductInfoGeneralSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductInfoGeneralError())
        }
    },

    removeProductImage: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.removeProductImageRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_remove_product_image",
                parameters:credential
            }); 
            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.removeProductImageSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.removeProductImageError())
        }
    },

    getProductImages: (credential, info) => async (dispatch, state) => {

        // console.log('onGetProductImages send', info);
        privateRequest(state, dispatch, info)
        dispatch(privateUserActions.getProductImagesRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_get_product_images",
                parameters:credential
            }); 
            dispatch(configActions.claerErrorCode())

            dispatch(privateUserActions.getProductImagesSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductImagesError())
        }
    },

    createUpdateProductImage: credential => async (dispatch, state) => {
        privateRequest(state, dispatch, {disableErrCode:false})
        dispatch(privateUserActions.createUpdateProductImageRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_create_update_product_image",
                parameters:credential
            });

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createUpdateProductImageSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createUpdateProductImageError())
        }
    },

    removeProductById: credential => async (dispatch, state) => {
        privateRequest(state, dispatch,  {disableErrCode:false})
        dispatch(privateUserActions.removeProductByIdRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_remove_product_by_id",
                parameters:credential
            }); 
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.removeProductByIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.removeProductByIdError())
        }
    },

    getMeasures: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getMeasuresRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_get_measures",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getMeasuresSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getMeasuresError())
        }
    },

    updateSpecialPriceProductOrOption: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateSpecialPriceProductOrOptionRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_update_special_price_product_or_option",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateSpecialPriceProductOrOptionSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateSpecialPriceProductOrOptionError())
        }
    },

    getProductSettingsDiscount: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductSettingsDiscountRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_get_product_settings_discount",
                parameters:credential
            }); dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getProductSettingsDiscountSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductSettingsDiscountError())
        }
    },

    setProductPercentCachback: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.setProductPercentCachbackRequest())
    
        try{
            const res = await instance.post('/', {
                name:"vendor_set_product_percent_cachback",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.setProductPercentCachbackSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.setProductPercentCachbackError())
        }
    },

    createFeedback: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.createFeedbackRequest())
    
        try{
            const res = await instance.post('/', {
                name:"create_feedback",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createFeedbackSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createFeedbackError())
        }
    },

    createFeedbackLike: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.createFeedbackLikeRequest())
    
        try{

            const res = await instance.post('/', {
                name:"create_feedback_like",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createFeedbackLikeSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createFeedbackLikeError())
        }
    },

    createFeedbackDislike: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.createFeedbackDislikeRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"create_feedback_dislike",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createFeedbackDislikeSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createFeedbackDislikeError())
        }
    },

    getCart: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getCartRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_cart",
                parameters:credential
            }); 

            // dispatch(configActions.configErrorCode(res.data))
            dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getCartSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getCartError())
        }
    },

    addProductToCart: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.addProductToCartRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_add_product_to_cart",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.addProductToCartSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.addProductToCartError())
        }
    },

    removeProductFromCart: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.removeProductFromCartRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_remove_product_from_cart",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.removeProductFromCartSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.removeProductFromCartError())
        }
    },

    updateProductQtyInCart: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateProductQtyInCartRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_update_product_qty_in_cart",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateProductQtyInCartSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateProductQtyInCartError())
        }
    },

    proceedToCheckout: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.proceedToCheckoutRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_proceed_to_checkout",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.proceedToCheckoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.proceedToCheckoutError())
        }
    },

    getReloadHistory: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getReloadHistoryRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_reload_history",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.getReloadHistorySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getReloadHistoryError())
        }
    },

    vendorGetReloadHistory: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.vendorGetReloadHistoryRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_reload_history",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.vendorGetReloadHistorySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.vendorGetReloadHistoryError())
        }
    },

    reloadProceedToCheckout: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.reloadProceedToCheckoutRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_reload_proceed_to_checkout",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.reloadProceedToCheckoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.reloadProceedToCheckoutError())
        }
    },

    paymentSystemsCoinsAmount: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.paymentSystemsCoinsAmountRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_payment_systems_coins_amount",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(privateUserActions.paymentSystemsCoinsAmountSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.paymentSystemsCoinsAmountError())
        }
    },

    transactionBuyCoins: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.transactionBuyCoinsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_transaction_buy_coins",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.transactionBuyCoinsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.transactionBuyCoinsError())
        }
    },

    vendorTransactionBuyCoins: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.vendorTransactionBuyCoinsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_transaction_buy_coins",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.vendorTransactionBuyCoinsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.vendorTransactionBuyCoinsError())
        }
    },

    getPaypalClientId: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getPaypalClientIdRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_paypal_client_id",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            
            dispatch(privateUserActions.getPaypalClientIdSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getPaypalClientIdError())
        }
    },

    transactionBuyCoinsPaypal: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.transactionBuyCoinsPaypalRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_transaction_buy_coins_paypal",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.transactionBuyCoinsPaypalSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.transactionBuyCoinsPaypalError())
        }
    },

    vendorTransactionBuyCoinsPaypal: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.vendorTransactionBuyCoinsPaypalRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_transaction_buy_coins_paypal",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.vendorTransactionBuyCoinsPaypalSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.vendorTransactionBuyCoinsPaypalError())
        }
    },

    getBraintreeToken: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getBraintreeTokenRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_braintree_token",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getBraintreeTokenSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getBraintreeTokenError())
        }
    },

    getSquareupSettings: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getSquareupSettingsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_squareup_settings",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getSquareupSettingsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getSquareupSettingsError())
        }
    },

    getStripToken: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getStripTokenRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_strip_token",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getStripTokenSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getStripTokenError())
        }
    },

    getStripClientSecret: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getStripClientSecretRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_strip_client_secret",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getStripClientSecretSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getStripClientSecretError())
        }
    },
    
    transactionBuyCoinsStrip: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.transactionBuyCoinsStripRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_transaction_buy_coins_strip",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.transactionBuyCoinsStripSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.transactionBuyCoinsStripError())
        }
    },

    vendorTransactionBuyCoinsStrip: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.vendorTransactionBuyCoinsStripRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_transaction_buy_coins_strip",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.vendorTransactionBuyCoinsStripSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.vendorTransactionBuyCoinsStripError())
        }
    },

    transactionBuyCoinsSquareup: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.transactionBuyCoinsSquareupRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_transaction_buy_coins_squareup",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.transactionBuyCoinsSquareupSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.transactionBuyCoinsSquareupError())
        }
    },

    vendorTransactionBuyCoinsSquareup: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.vendorTransactionBuyCoinsSquareupRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_transaction_buy_coins_squareup",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.vendorTransactionBuyCoinsSquareupSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.vendorTransactionBuyCoinsSquareupError())
        }
    },

    transactionBuyCoinsBraintree: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.transactionBuyCoinsBraintreeRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_transaction_buy_coins_braintree",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.transactionBuyCoinsBraintreeSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.transactionBuyCoinsBraintreeError())
        }
    },

    vendorTransactionBuyCoinsBraintree: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.vendorTransactionBuyCoinsBraintreeRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_transaction_buy_coins_braintree",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.vendorTransactionBuyCoinsBraintreeSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.vendorTransactionBuyCoinsBraintreeError())
        }
    },

    saveProceedToCheckout: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.saveProceedToCheckoutRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_save_proceed_to_checkout",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.saveProceedToCheckoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.saveProceedToCheckoutError())
        }
    },

    checkoutCryptoOrder: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.checkoutCryptoOrderRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_checkout_crypto_order",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.checkoutCryptoOrderSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.checkoutCryptoOrderError())
        }
    },

    userTransactionPaypal: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.userTransactionPaypalRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_user_transaction_paypal",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.userTransactionPaypalSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.userTransactionPaypalError())
        }
    },

    userTransactionSquareup: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.userTransactionSquareupRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_user_transaction_squareup",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.userTransactionSquareupSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.userTransactionSquareupError())
        }
    },
    
    userTransactionBraintree: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.userTransactionBraintreeRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_user_transaction_braintree",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.userTransactionBraintreeSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.userTransactionBraintreeError())
        }
    },

    userTransactionStrip: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.userTransactionStripRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_user_transaction_strip",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.userTransactionStripSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.userTransactionStripError())
        }
    },

    userTransactionCoins: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.userTransactionCoinsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_user_transaction_coins",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.userTransactionCoinsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.userTransactionCoinsError())
        }
    },

    updateProductDeliveryParameters: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateProductDeliveryParametersRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_product_delivery_parameters",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateProductDeliveryParametersSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateProductDeliveryParametersError())
        }
    },

    getProductDeliveryParameters: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductDeliveryParametersRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_product_delivery_parameters",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getProductDeliveryParametersSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductDeliveryParametersError())
        }
    },

    getOrdersHistory: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getOrdersHistoryRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_orders_history",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getOrdersHistorySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getOrdersHistoryError())
        }
    },

    getShareSalesProfitList: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getShareSalesProfitListRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_share_sales_profit_list",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getShareSalesProfitListSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getShareSalesProfitListError())
        }
    },

    createShareSalesProfit: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.createShareSalesProfitRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_create_share_sales_profit",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createShareSalesProfitSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createShareSalesProfitError())
        }
    },

    getOrdersDelivery: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getOrdersDeliveryRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_orders_delivery",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getOrdersDeliverySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getOrdersDeliveryError())
        }
    },

    getDeliveryTypes: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getDeliveryTypesRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_delivery_types",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getDeliveryTypesSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getDeliveryTypesError())
        }
    },

    updateOrdersDeliveryInfo: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateOrdersDeliveryInfoRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_orders_delivery_info",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateOrdersDeliveryInfoSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateOrdersDeliveryInfoError())
        }
    },

    updateOrdersDeliveryStatusSend: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateOrdersDeliveryStatusSendRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_orders_delivery_status_send",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateOrdersDeliveryStatusSendSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateOrdersDeliveryStatusSendError())
        }
    },

    getDeliveryInfo: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getDeliveryInfoRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_delivery_info",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getDeliveryInfoSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getDeliveryInfoError())
        }
    },

    getBalancesInfo: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getBalancesInfoRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_balances_info",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getBalancesInfoSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getBalancesInfoError())
        }
    },

    getPayoutMethodNames: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getPayoutMethodNamesRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_payout_method_names",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getPayoutMethodNamesSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getPayoutMethodNamesError())
        }
    },

    getPayoutMethodTypes: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getPayoutMethodTypesRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_payout_method_types",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getPayoutMethodTypesSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getPayoutMethodTypesError())
        }
    },

    getPayoutMethods: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getPayoutMethodsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_payout_methods",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getPayoutMethodsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getPayoutMethodsError())
        }
    },

    createUpdatePayoutMethod: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.createUpdatePayoutMethodRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_create_update_payout_method",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createUpdatePayoutMethodSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createUpdatePayoutMethodError())
        }
    },

    createPayout: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.createPayoutRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_create_payout",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.createPayoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.createPayoutError())
        }
    },

    getInfo: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getInfoRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_info",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getInfoSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getInfoError())
        }
    },

    getTransactionsPayout: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getTransactionsPayoutRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_transactions_payout",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getTransactionsPayoutSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getTransactionsPayoutError())
        }
    },

    addProductToWishlist: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.addProductToWishlistRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_add_product_to_wishlist",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.addProductToWishlistSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.addProductToWishlistError())
        }
    },

    removeProductFromWishlist: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.removeProductFromWishlistRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_remove_product_from_wishlist",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.removeProductFromWishlistSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.removeProductFromWishlistError())
        }
    },

    getProductCategoriesByProductMarketplace: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductCategoriesByProductMarketplaceRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_product_categories_marketplace_by_product",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getProductCategoriesByProductMarketplaceSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductCategoriesByProductMarketplaceError())
        }
    },

    getProductCategoriesByProductMarketplaceBottom: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductCategoriesByProductMarketplaceBottomRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_product_categories_marketplace_by_product",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getProductCategoriesByProductMarketplaceBottomSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductCategoriesByProductMarketplaceBottomError())
        }
    },

    getProductCategoriesByProductMarketplaceBottomTwo: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductCategoriesByProductMarketplaceBottomTwoRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_product_categories_marketplace_by_product",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getProductCategoriesByProductMarketplaceBottomTwoSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductCategoriesByProductMarketplaceBottomTwoError())
        }
    },

    linkUnlinkProductCategoryMarketplaceProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.linkUnlinkProductCategoryMarketplaceProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_link_unlink_product_category_marketplace_product",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.linkUnlinkProductCategoryMarketplaceProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.linkUnlinkProductCategoryMarketplaceProductError())
        }
    },

    getProductPricingVariations: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductPricingVariationsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_product_pricing_variations",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getProductPricingVariationsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductPricingVariationsError())
        }
    },

    addProductPricingVariation: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.addProductPricingVariationRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_add_product_pricing_variation",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.addProductPricingVariationSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.addProductPricingVariationError())
        }
    },

    removeProductPricingVariation: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.removeProductPricingVariationRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_remove_product_pricing_variation",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.removeProductPricingVariationSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.removeProductPricingVariationError())
        }
    },

    getAttributesByProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getAttributesByProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_attributes_by_product",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getAttributesByProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getAttributesByProductError())
        }
    },

    updateProductCategory: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateProductCategoryRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_product_category",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateProductCategorySuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateProductCategoryError())
        }
    },

    getProductCategories: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getProductCategoriesRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_product_categories",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getProductCategoriesSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getProductCategoriesError())
        }
    },
    removeAttributeByProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.removeAttributeByProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_remove_attribute_by_product",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.removeAttributeByProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.removeAttributeByProductError())
        }
    },

    updateItemProperties: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateItemPropertiesRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_item_properties",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateItemPropertiesSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateItemPropertiesError())
        }
    },

    getWidgetConvertationList: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getWidgetConvertationsListRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_widget_convertations_list",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getWidgetConvertationsListSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getWidgetConvertationsListError())
        }
    },

    updateItemConvertationWidget: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateItemConvertationWidgetRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_item_convertation_widget",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateItemConvertationWidgetSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateItemConvertationWidgetError())
        }
    },

    addRemovePropertiesWidgetByProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.addRemovePropertiesWidgetByProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_add_remove_properties_widget_by_product",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.addRemovePropertiesWidgetByProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.addRemovePropertiesWidgetByProductError())
        }
    },

    updateVisibleGroupName: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateVisibleGroupNameRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_update_visible_group_name",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateVisibleGroupNameSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateVisibleGroupNameError())
        }
    },

    getVisibleGroupName: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getVisibleGroupNameRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_visible_group_name",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getVisibleGroupNameSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getVisibleGroupNameError())
        }
    },

    getWidgetRecords: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getWidgetRecordsRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_widget_records",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getWidgetRecordsSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getWidgetRecordsError())
        }
    },

    getWidgetsNoAttributesProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getWidgetsNoAttributesProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_get_widgets_no_attributes_product",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getWidgetsNoAttributesProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getWidgetsNoAttributesProductError())
        }
    },

    addAttributeByProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.addAttributeByProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_add_attribute_by_product",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.addAttributeByProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.addAttributeByProductError())
        }
    },

    addPricingAttributeByProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.addPricingAttributeByProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_add_pricing_attribute_by_product",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.addPricingAttributeByProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.addPricingAttributeByProductError())
        }
    },

    notLinkedPricingAttributesByProduct: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.notLinkedPricingAttributesByProductRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"vendor_not_linked_pricing_attributes_by_product",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.notLinkedPricingAttributesByProductSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.notLinkedPricingAttributesByProductError())
        }
    },

    updateUserOrVendorProfile: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.updateUserOrVendorProfileRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"update_user_or_vendor_profile",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.updateUserOrVendorProfileSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.updateUserOrVendorProfileError())
        }
    },

    getUserOrVendorProfile: credential => async (dispatch, state) => {
        privateRequest(state, dispatch)
        dispatch(privateUserActions.getUserOrVendorProfileRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"get_user_or_vendor_profile",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(privateUserActions.getUserOrVendorProfileSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(privateUserActions.getUserOrVendorProfileError())
        }
    },

 

}

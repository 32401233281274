import { useCallback, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import Popup from "../Popup/Popup"
import SimpleReactValidator from 'simple-react-validator';
import {DOMAIN, WEB_PICTURES_FILE} from '../../config/Url'

import Button from "../Button/Button"
import { publicUserOperations } from "../../redux/publicUser"
import { configActions, configSelectors } from "../../redux/config";
import InputMask from 'react-input-mask';
import PopupLegal from "../PopupLegal/PopupLegal";

import Logo from './../../asstets/img/header/Logo.png'
import { replaceII } from "../../modules/helper";

const INITIAL_LOGIN = {
    EMAIL: "",
    PASSWORD: ""
}

const INITIAL_FORGOT = {
    EMAIL: "",
}

const INITIAL_REGISTER = {
    FIRST_NAME: "",
    LAST_NAME: "",
    NICK_NAME: "",
    GENDER: 0,
    BIRTHDAY: "",
    EMAIL: '',
    PHONE: "",
    PHONE_COUNTRY_CODE: "+1",
    COUNTRY_CODE: "",
    PASSWD: '',
    PASSWD2: '',
    DOMAIN,
    VENDOR_NAME:"",
	IS_VENDOR:0,
    IMAGE:""
}

const INITIAL_REGISTER_VENDOR = {
        VENDOR_NAME:"",	//	- имя и фамилия
		VENDOR_EMAIL:"",	//- email
		VENDOR_IMAGE:"",			//- иконка
		VENDOR_COUNTRY_CODE:"CA",	//- страна
		VENDOR_PASSWD:"",		//- пароль
        PASSWD2: '',
		VENDOR_TYPE:0,			//- тип (0 - частное литцо, 1 - бизнес)
		DOMAIN
}

const INITIAL_SHOW_PASSWORD = {
    'pass1':true,
    'pass2':true,
    'pass3':true,
}

const PopupLoginRegister = ({
    closePopup,
    isAuthenticated,
    onUserRegistration,
    onUserAutorization,
    history,
    errCode,
    resetLoginErr,
    onForgotPasswordEmail,
    setErrCode,
    onUserRegistrationVendor,
    onUserVendorAuthentification,
    onClaerErrorCode,
    initWebSait,
    onRegistrationUserOrVender
}) => {

    const [key, setKey] = useState(1)

    const [login, setLogin] = useState(INITIAL_LOGIN)
    const [register, setRegister] = useState(INITIAL_REGISTER)
    const [registerVendor, setRegisterVendor] = useState(INITIAL_REGISTER_VENDOR)
    const [showPass, setShowPass] = useState(INITIAL_SHOW_PASSWORD);

    const [page, setPage] = useState(0)

    const [rel, setRel] = useState(-1)
    const simpleValidator = useRef(new SimpleReactValidator())
    const simpleValidator2 = useRef(new SimpleReactValidator())
    const simpleValidator21 = useRef(new SimpleReactValidator())
    const simpleValidator3 = useRef(new SimpleReactValidator())

    const [errorsRegister, setErrorsRegister] = useState({})
    const [errorsRegisterVendor, setErrorsRegisterVendor] = useState({})
    const [eFlag, setFlag] = useState(0)
    const [eFlag2, setFlag2] = useState(0)

    const [show, setShow] = useState(false);
    

    useEffect(() => {
        // console.log({isAuthenticated});
        if (isAuthenticated) {
            // setLogin(INITIAL_LOGIN)
            // setRegister(INITIAL_REGISTER)
            // setRel(-1)
            // // history.goBack()
            // history.push(`/`)

            closePopup({status:0})

            // window.location.reload()
        }
    }, [isAuthenticated])

    useEffect(()=>{

        return(()=>{
            setErrorsRegister({})
            setErrorsRegisterVendor({})
        })

    }, [])


    useEffect(()=>{
        // if(key === 0){

        // }else if(key === 1){

        // }

        setLogin(INITIAL_LOGIN)
        setRegister(INITIAL_REGISTER)
        setRegisterVendor(INITIAL_REGISTER_VENDOR)

    }, [key])

    // useEffect(()=>{
    //     console.log('register', register);
    // }, [register])

    useEffect(()=>{
        if(eFlag === 1)
            validRegister()
    }, [register, eFlag])

    useEffect(()=>{
        if(eFlag2 === 1)
            validRegisterVendor()
    }, [registerVendor, eFlag2])


    useEffect(()=>{
        if(errCode === 1 && isAuthenticated){
            closePopup({status:0})
            setErrorsRegister({})
            setErrorsRegisterVendor({})
        }
    }, [errCode, isAuthenticated])

    const handleLogin = ({target: {name, value}}) => {
        // resetLoginErr()
        setLogin(state => ({...state, [name]: value.trim()}))
    }

    const handleRegister = ({target: {name, value}}) => {
        // resetLoginErr()
        setRegister(state => ({...state, [name]: value.trim()}))
    }
    const handleRegisterNoTrim = ({target: {name, value}}) => {
        // resetLoginErr()
        setRegister(state => ({...state, [name]: value}))
    }

    const handleRegister2 = ({target: {name, value}}) => {
        // resetLoginErr()
        setRegisterVendor(state => ({...state, [name]: value.trim()}))
    }

    const handleRegisterCheckbox = ({target: {name, checked}}) => {
        // resetLoginErr()
        setRegister(state => ({...state, [name]: checked ? 1 : 0}))
    }

    const [, forceUpdate] = useState()
    const sendLogin = (e) => {
        e.preventDefault();
        setRel(1)
        // resetLoginErr()
        
        const formValid = simpleValidator.current.allValid()

        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
            return
        }

        if(key === 0) onUserVendorAuthentification({
                                        DOMAIN,
                                        VENDOR_EMAIL:login.EMAIL, 
                                        VENDOR_PASSWD:login.PASSWORD
                                    })
        if(key === 1) onUserAutorization(login)

    }

    // const [, forceUpdate2] = useState() The firstname field is required. Passwords need to match!
    const sendRegister = () => {
        setRel(2)
        setFlag(1)

        if(validRegister()){

            const {PASSWD2, ...copyRegister} = register

            // onUserRegistration({...copyRegister});

            onRegistrationUserOrVender({...copyRegister});
        }

    }

    // const [, forceUpdate21] = useState()
    const sendRegisterVendor = () => {
        // setRel(2)
    
        setFlag2(1)
        if(validRegisterVendor()){

            const {PASSWD2, ...copyRegister} = registerVendor

            onUserRegistrationVendor({...copyRegister});
        }

    }

    
    const [, forceUpdate3] = useState()
    const sendReset = () => {
        setRel(3)
        const formValid = simpleValidator3.current.allValid()
        if (!formValid) {
            simpleValidator3.current.showMessages()
            forceUpdate3(1)

            return
        }
        
        onForgotPasswordEmail({DOMAIN, ...forgot})
        
    }
    const [forgot, setForgot] = useState(INITIAL_FORGOT)
    const handleForgot = ({target: {name, value}}) => {

        setForgot(state => ({...state, [name]: value}))
    }
    
    const showPassEye = key =>{
        setShowPass(state => ({...state, [key]:!showPass[key]}))
    }

    const validRegister = useCallback(() =>{

        let errors = {}
        let isValid = true;

        if(register.VENDOR_NAME === '' && register.IS_VENDOR === 1 ){
            isValid = false;
            errors["VENDOR_NAME"] = "The vendor name field is required.";
        }

        if(register.FIRST_NAME === ''){
            isValid = false;
            errors["FIRST_NAME"] = "The firstname field is required.";
        }

        if(register.LAST_NAME === ''){
            isValid = false;
            errors["LAST_NAME"] = "The lasttname field is required.";
        }

        if(register.PHONE === ''){
            isValid = false;
            errors["PHONE"] = "The phone field is required.";
        }

        if(register.EMAIL === ''){
            isValid = false;
            errors["EMAIL"] = "The email field is required.";
        }

        if (register.EMAIL !== "") {
          
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(register.EMAIL)) {
              isValid = false;
              errors["EMAIL"] = "The email field is required.";
            }
        }

        if(register.PASSWD === ''){
            isValid = false;
            errors["PASSWD"] = "The password field is required.";
        }

        if(register.PASSWD2 === ''){
            isValid = false;
            errors["PASSWD2"] = "The confirm password field is required.";
        }

        if (register.PASSWD !== "" && register.PASSWD2 !== "") {
          
            if (register.PASSWD != register.PASSWD2) {
              isValid = false;
              errors["PASSWD2"] = "Passwords need to match!";
            }
          } 


        setErrorsRegister({...errors})

        return isValid;
    }, [register])

    const validRegisterVendor = () =>{

        let errors = {}
        let isValid = true;

        if(registerVendor.VENDOR_NAME === ''){
            isValid = false;
            errors["VENDOR_NAME"] = "The vendor name field is required.";
        }

 

        if(registerVendor.VENDOR_EMAIL === ''){
            isValid = false;
            errors["VENDOR_EMAIL"] = "The email field is required.";
        }

        if (registerVendor.VENDOR_EMAIL !== "") {
          
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(registerVendor.VENDOR_EMAIL)) {
              isValid = false;
              errors["VENDOR_EMAIL"] = "The email field is required.";
            }
        }

        if(registerVendor.VENDOR_PASSWD === ''){
            isValid = false;
            errors["VENDOR_PASSWD"] = "The password field is required.";
        }

        if(registerVendor.PASSWD2 === ''){
            isValid = false;
            errors["PASSWD2"] = "The confirm password field is required.";
        }

        if (registerVendor.VENDOR_PASSWD !== "" && registerVendor.PASSWD2 !== "") {
          
            if (registerVendor.VENDOR_PASSWD != registerVendor.PASSWD2) {
              isValid = false;
              errors["PASSWD2"] = "Passwords need to match!";
            }
          } 


        setErrorsRegisterVendor({...errors})

        return isValid;
    }

    return (
        <>
        <Popup className="login-popup" overflow={true} closePopup={()=>closePopup({status:0})} width={page === 1 ? '600px' : '1220px'} style={{transform: 'scale(0.8)'}}>
            <div className="modal-body">

                <div className="loginClose" onClick={()=>closePopup({status:0})}>&times;</div>

            <div className="text-center">
                <img src={Logo} width="100%" />
            {/* {Object.keys(initWebSait).length !== 0 && initWebSait?.MAIN_INFO?.LOGO_HEADER !== '' &&
                <Link className="navbar-brand" >
                    <img src={`${WEB_PICTURES_FILE}${initWebSait?.MAIN_INFO?.LOGO_HEADER}`} width="165px" height="57px" style={{objectFit:'contain'}} />
                    <div className="imgName">take the <span>h</span>iiroad to happiness</div>
                </Link>
            } */}
            </div>
                
        {page === 0 && 
        <div className="container">

            {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={["nav-link", key===0 ? 'active' : null].join(' ')} onClick={()=>setKey(0)} style={{fontSize:15, fontWeight: 500}} >vendor</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={["nav-link", key===1 ? 'active' : null].join(' ')} onClick={()=>setKey(1)} style={{fontSize:15, fontWeight: 500}} >buyer</button>
                </li>
            </ul> */}

            {/* {key===0 && <div className="d-flex display-block">
                <div className="w-50 py-3 pr-3 full-with">
                    <form  onSubmit={sendLogin} className="login-frm">
                    <div className="">
                        <h4 className="text-btm-border" dangerouslySetInnerHTML={{__html:replaceII('vendor Login')}}></h4>
                    </div>
                    <div className="loginLine" />
                    <div className="pb-3">
                        <p dangerouslySetInnerHTML={{__html:replaceII('Welcome back! Sign in to your account.')}} ></p>
                    </div>
        
                    <div>
                        <label className="loginLabel" dangerouslySetInnerHTML={{__html:replaceII('Username or email address*')}}></label>
                    </div>
                    <div className="pb-3">
                        <input type="email" 
                        className="input-rounded-fields form-control" 
                        name="EMAIL" 
                        value={login.EMAIL} 
                        onChange={handleLogin} 
                        
                        // style={{borderColor:simpleValidator.current.fieldValid('Email') ? '' : 'red'}}
                        onBlur={() => simpleValidator.current.showMessageFor('Email')}
                        />
                       
                        <div className="text-danger">
                            {simpleValidator.current.message('Email', login.EMAIL, 'required|email')}
                        </div>
                    </div>
                    
                    <div className="control pb-3">
                        <label className="loginLabel">password*</label>
                        <input type={showPass.pass1 ? 'password' : 'text'} 
                            className="input-rounded-fields form-control" 
                            name="PASSWORD"
                            value={login.PASSWORD} 
                            onChange={handleLogin}
                      

                            // style={{borderColor:simpleValidator.current.fieldValid('Password') ? '' : 'red'}}
                            onBlur={() => simpleValidator.current.showMessageFor('Password')}
                         />
                         <i className="fa fa-eye" onClick={()=>showPassEye('pass1')} />
                        <div className="text-danger">
                            {simpleValidator.current.message('Password', login.PASSWORD, 'required|alpha_num')}
                        </div>
                    </div>
                    <div className="pb-3">
                        <input type="checkbox" className="px-2"/>
                        <span className="pl-2">remember me</span>
                    </div>
                  

                    { [0, -1, 1, 14].indexOf(errCode) === -1 && rel === 1 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>error code: {errCode}</div>
                        <br/></>}

                    {errCode === 14 && rel === 1 && <> <div style={{color: 'red', textAlign: 'center'}} >user not found / wrong login or password</div><br/></>}

                    <div className="pb-3">
                    <Button className="button loginButton " type="submit" >login</Button>
                    </div>
                    <div>
                        <a style={{color: '#007bff', cursor:'pointer'}} onClick={()=>{onClaerErrorCode(); setPage(1)}}>Lost your password?</a>
                    </div>
                    </form>
                </div>

                <div className="loginOR"> <span>or</span> </div>

                <div className="w-50  py-3 pl-3 full-with login-frm" style={{paddingRight: '0px !important'}}>
       
                    <div className="">
                        <h4 className="text-btm-border" dangerouslySetInnerHTML={{__html:replaceII('join us')}} ></h4>
                    </div>
                    <div className="loginLine" />
                    <div className="pb-3">
                        <p dangerouslySetInnerHTML={{__html:replaceII('welcome to our community!  Share some of the following information to fully immerse in the hiiroad experience')}}></p>
                    </div>

                            <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">vendor name</label>
                                <input
                                    type="text"
                                    onChange={handleRegister2}
                                    className="input-rounded-fields form-control"
                                    name="VENDOR_NAME"
                                    value={registerVendor.VENDOR_NAME}
                                    autoComplete="off"
                                    // style={{borderColor:simpleValidator2.current.fieldValid('firstname') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator21.current.showMessageFor('vendorname')}
                                    />
                                <div className="text-danger">
                                { Boolean(errorsRegisterVendor.VENDOR_NAME) && errorsRegisterVendor.VENDOR_NAME}

                                </div>
                            </div>

                            <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">vendor type</label>
                                <div style={{display:'flex'}}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="VENDOR_TYPE" id="exampleRadios1" checked={registerVendor.VENDOR_TYPE === 0} value={0} onChange={ ({target:{value}})=> setRegisterVendor(state => ({...state, VENDOR_TYPE: Number(value)}))} />
                                        <label className="form-check-label" htmlFor="exampleRadios1">private seller </label>
                                    </div>
                                    <div className="form-check" style={{marginLeft:20}}>
                                        <input className="form-check-input" type="radio" name="VENDOR_TYPE" id="exampleRadios2" checked={registerVendor.VENDOR_TYPE === 1} value={1} onChange={ ({target:{value}})=> setRegisterVendor(state => ({...state, VENDOR_TYPE: Number(value)}))}/>
                                        <label className="form-check-label" htmlFor="exampleRadios2"> business</label>
                                    </div>
                                </div>
                  
                            </div>
                            

                            <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">country</label><br/>
                                <div className="d-flex">
                                    <select className=" md-form pl-2 form-control  selecting-custom-styling" name="VENDOR_COUNTRY_CODE" value={registerVendor.VENDOR_COUNTRY_CODE} onChange={handleRegister2} >
                                        <option value="CA">Canada </option>
                                        <option value="US">USA</option>
                                    </select>
                                   
                                </div>
                              
                            </div>
                  

                    <div className="pb-3">
                        <label className="loginLabel" dangerouslySetInnerHTML={{__html:replaceII('email address*')}} ></label>
                        <input
                            type="email"
                            onChange={handleRegister2}
                            className="input-rounded-fields form-control"
                            name="VENDOR_EMAIL"
                            value={registerVendor.VENDOR_EMAIL}
                            autoComplete="nope"
                            // style={{borderColor:simpleValidator2.current.fieldValid('email') ? '' : 'red'}}
                            // onBlur={() => simpleValidator21.current.showMessageFor('email')}
                            />
                        <div className="text-danger">
                        { Boolean(errorsRegisterVendor.VENDOR_EMAIL) && errorsRegisterVendor.VENDOR_EMAIL}

                        </div>
                    </div>
                    <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">password</label>
                         
                                <input
                                    type={showPass.pass2 ? 'password' : 'text'} 
                                    onChange={handleRegister2}
                                    className="input-rounded-fields form-control"
                                    name="VENDOR_PASSWD"
                                    value={registerVendor.VENDOR_PASSWD}
                                    autoComplete="off"
                                    // style={{borderColor:simpleValidator2.current.fieldValid('password') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator21.current.showMessageFor('vendorpassword')}
                                    />
                                    <i className="fa fa-eye" onClick={()=>showPassEye('pass2')} />
                                <div className="text-danger">
                                    { Boolean(errorsRegisterVendor.VENDOR_PASSWD) && errorsRegisterVendor.VENDOR_PASSWD}

                                </div>
                            </div>
                            <div className="control pb-3 has-icons-left">
                                <label className="loginLabel" dangerouslySetInnerHTML={{__html:replaceII('confirm password')}} ></label>
                                <input
                                    type={showPass.pass3 ? 'password' : 'text'} 
                                    onChange={handleRegister2}
                                    className="input-rounded-fields form-control"
                                    name="PASSWD2"
                                    value={registerVendor.PASSWD2}
                                    autoComplete="off"
                                    // style={{borderColor:simpleValidator2.current.fieldValid('confirm_password') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator21.current.showMessageFor('confirm_password')}
                                    />

                                    <i className="fa fa-eye" onClick={()=>showPassEye('pass3')} />
                                <div className="text-danger">
                                    { Boolean(errorsRegisterVendor.PASSWD2) && errorsRegisterVendor.PASSWD2}

                                </div>
                            </div>
                            <div className="pt-1">
                                <p >your personal data w<span style={{color: '#4572c2'}}>i</span>ll be used to support your exper<span style={{color: '#4572c2'}}>i</span>ence thoughtout this webs<span style={{color: '#4572c2'}}>i</span>te, to manage accesss to your account, and for other purposes descr<span style={{color: '#4572c2'}}>i</span>bed <span style={{color: '#4572c2'}}>i</span>n our <a href="#" onClick={()=>setShow(true)}>pr<span style={{color: '#4572c2'}}>i</span>vacy pol<span style={{color: '#4572c2'}}>i</span>cy</a>.
                                </p>
                            </div>

                    { [0, -1, 1, 11, 12].indexOf(errCode) === -1 && rel === 2 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>error code: {errCode}</div>
                        <br/></>}

                    {errCode === 11 && rel === 2 && <> <div style={{color: 'red', textAlign: 'center'}} >user with such a email already registered</div><br/></>}
                    {errCode === 12 && rel === 2 && <> <div style={{color: 'red', textAlign: 'center'}} >user with such a phone number already registered</div><br/></>}
                    <div>
                    <Button className="button loginButton " onClick={() => sendRegisterVendor()}>join us</Button>
                    </div>

                </div>
            </div>} */}
            {key===1 && <div className="d-flex display-block">
                <div className="w-50 py-3 pr-3 full-with" style={{paddingLeft: '0px !important'}}>
                    <form onSubmit={sendLogin} className="login-frm">
                    <div className="">
                        <h4 className="text-btm-border">log<span style={{color: '#4572c2'}}>i</span>n</h4>
                    </div>
                    <div className="loginLine" />
                    <div className="pb-3">
                        <p>welcome back! s<span style={{color: '#4572c2'}}>i</span>gn <span style={{color: '#4572c2'}}>i</span>n to your account.</p>
                    </div>
                    {/* <input type="email" name="EMAIL" style={{height:0, opacity:1}}  />
                    <input type="password" name="PASSWORD" style={{height:0, opacity:1}}  /> */}
                    <div>
                        <label className="loginLabel">username or ema<span style={{color: '#4572c2'}}>i</span>l address*</label>
                    </div>
                    <div className="pb-3">
                        <input type="email" 
                        className="input-rounded-fields form-control" 
                        name="EMAIL" 
                        value={login.EMAIL} 
                        onChange={handleLogin} 
                        // style={{borderColor:simpleValidator.current.fieldValid('Email') ? '' : 'red'}}
                        onBlur={() => simpleValidator.current.showMessageFor('Email')}
                        />
                       
                        <div className="text-danger">
                            {simpleValidator.current.message('Email', login.EMAIL, 'required|email')}
                        </div>
                    </div>
                    
                    <div className="control pb-3">
                        <label className="loginLabel">password*</label>
                        <input type={showPass.pass1 ? 'password' : 'text'} 
                            className="input-rounded-fields form-control" 
                            name="PASSWORD"
                            value={login.PASSWORD} 
                            onChange={handleLogin}

                            // style={{borderColor:simpleValidator.current.fieldValid('Password') ? '' : 'red'}}
                            onBlur={() => simpleValidator.current.showMessageFor('Password')}
                         />
                         <i className="fa fa-eye" onClick={()=>showPassEye('pass1')} />
                        <div className="text-danger">
                            {simpleValidator.current.message('Password', login.PASSWORD, 'required|alpha_num')}
                        </div>
                    </div>
                    <div className="pb-3">
                        <input type="checkbox" className="px-2"/>
                        <span className="pl-2">remember me</span>
                    </div>
                    {/* {errCode > 1 && rel === 1 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>} */}

                    { [0, -1, 1, 14].indexOf(errCode) === -1 && rel === 1 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>Error code: {errCode}</div>
                        <br/></>}

                    {errCode === 14 && rel === 1 && <> <div style={{color: 'red', textAlign: 'center'}} >user not found / wrong login or password</div><br/></>}

                    <div className="pb-3">
                    <Button className="button loginButton " type="submit" >login</Button>
                        {/* <input className="login-buttons" type="submit" value="LOG IN" onClick={() => sendLogin()}/> */}
                    </div>
                    <div>
                        {/* <Link to="/forgot"> Lost your passsword?</Link> */}
                        <a style={{color: '#007bff', cursor:'pointer'}} onClick={()=>{onClaerErrorCode(); setPage(1)}}> lost your password?</a>
                    </div>
                    </form>
                </div>
                <div className="loginOR"> <span>or</span> </div>
                <div className="w-50  py-3 pl-3 full-with login-frm" style={{paddingRight: '0px !important'}}>
     
                    <div className="">
                        <h4 className="text-btm-border">jo<span style={{color: '#4572c2'}}>i</span>n us</h4>
                    </div>
                    <div className="loginLine" />
                    <div className="pb-3">
                        <p>welcome to our commun<span style={{color: '#4572c2'}}>i</span>ty!  Share some of the follow<span style={{color: '#4572c2'}}>i</span>ng <span style={{color: '#4572c2'}}>i</span>nformat<span style={{color: '#4572c2'}}>i</span>on to fully <span style={{color: '#4572c2'}}>i</span>mmerse <span style={{color: '#4572c2'}}>i</span>n the h<span style={{color: '#4572c2'}}>ii</span>road exper<span style={{color: '#4572c2'}}>i</span>ence</p>
                    </div>

                    <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">f<span style={{color: '#4572c2'}}>i</span>rstname</label>
                                <input
                                    type="text"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="FIRST_NAME"
                                    value={register.FIRST_NAME}
                                    autoComplete="nope"
                                    // style={{borderColor:simpleValidator2.current.fieldValid('firstname') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator2.current.showMessageFor('firstname')}
                                />
                                <div className="text-danger">
                                    { Boolean(errorsRegister.FIRST_NAME) && errorsRegister.FIRST_NAME}
                                    {/* {simpleValidator2.current.message('firstname', register.FIRST_NAME, 'required|alpha')} */}
                                </div>
                            </div>
                            <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">lastname</label>
                                <input
                                    type="text"
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="LAST_NAME"
                                    value={register.LAST_NAME}
                                    autoComplete="nope"
                                    // style={{borderColor:simpleValidator2.current.fieldValid('lastname') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator2.current.showMessageFor('lastname')}
                                    />
                                <div className="text-danger">
                                { Boolean(errorsRegister.LAST_NAME) && errorsRegister.LAST_NAME}
                                    {/* {simpleValidator2.current.message('lastname', register.LAST_NAME, 'required|alpha')} */}
                                </div>
                            </div>

                    <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">phone number</label><br/>
                                <div className="d-flex">
                                    <select className=" md-form pl-2 mr-2 form-control selecting-custom-styling" 
                                        name="PHONE_COUNTRY_CODE"
                                        onChange={handleRegister}
                                        value={register.PHONE_COUNTRY_CODE} 
                                        style={{width:'16%'}} 
                                    >
                                        <option value="+1" >+1</option>
                                        <option value="+91">+91</option>
                                        <option value="+92">+92</option>
                                    </select>
                                    <div style={{width: '100%'}}>
                                        <InputMask
                                            mask="(999)999-9999" 
                                            maskChar={null}
                                            style={{width: '463px !important'}}
                                            type="tel"
                                            onChange={handleRegister}
                                            className="adjust-width input-rounded-fields form-control  float-right"
                                            name="PHONE"
                                            value={register.PHONE}
                                            autoComplete="nope"
                                        />
                                      

                                    </div>
                                </div>
                                <div className="text-danger" style={{marginLeft:75}}>
                                    { Boolean(errorsRegister.PHONE) && errorsRegister.PHONE}

                                    {/* {simpleValidator2.current.message('phone_number', register.PHONE, 'required|min:7|max:10')} */}
                                </div>
                            </div>
                  

                    <div className="pb-3">
                        <label className="loginLabel">ema<span style={{color: '#4572c2'}}>i</span>l address*</label>
                        {/*<Input type="email" className="input-rounded-fields form-control" name="EMAIL" validations={[required, email]} value={register.EMAIL} onChange={handleRegister} />*/}
                        <input
                            type="email"
                            onChange={handleRegister}
                            className="input-rounded-fields form-control"
                            name="EMAIL"
                            value={register.EMAIL}
                            autoComplete="nope"
                            // style={{borderColor:simpleValidator2.current.fieldValid('email') ? '' : 'red'}}
                            // onBlur={() => simpleValidator2.current.showMessageFor('email')}
                            />
                        <div className="text-danger">
                            { Boolean(errorsRegister.EMAIL) && errorsRegister.EMAIL}

                            {/* {simpleValidator2.current.message('email', register.EMAIL, 'required|email')} */}
                        </div>
                    </div>
                    <div className="pb-3">
                        <input type="checkbox" checked={register.IS_VENDOR === 1} name="IS_VENDOR" onChange={handleRegisterCheckbox} className="px-2"/>
                        <span className="pl-2">Become a vendor</span>
                    </div>
                    {register.IS_VENDOR === 1 && <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">vendor name</label>
                                <input
                                    type="text"
                                    onChange={handleRegisterNoTrim}
                                    className="input-rounded-fields form-control"
                                    name="VENDOR_NAME"
                                    value={register.VENDOR_NAME}
                                    autoComplete="off"
                                    // style={{borderColor:simpleValidator2.current.fieldValid('firstname') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator21.current.showMessageFor('vendorname')}
                                    />
                                <div className="text-danger">
                                { Boolean(errorsRegister.VENDOR_NAME) && errorsRegister.VENDOR_NAME}

                                    {/* {simpleValidator21.current.message('vendorname', registerVendor.VENDOR_NAME, 'required')} */}
                                </div>
                            </div>}
                    <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">password</label>
                                <input
                                    type={showPass.pass2 ? 'password' : 'text'} 
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="PASSWD"
                                    value={register.PASSWD}
                                    // style={{borderColor:simpleValidator2.current.fieldValid('password') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator2.current.showMessageFor('password')}
                                    />
                                    <i className="fa fa-eye" onClick={()=>showPassEye('pass2')} />
                                <div className="text-danger">
                                    { Boolean(errorsRegister.PASSWD) && errorsRegister.PASSWD}

                                    {/* {simpleValidator2.current.message('password', register.PASSWD, 'required|alpha_num')} */}
                                </div>
                            </div>
                            <div className="control pb-3 has-icons-left">
                                <label className="loginLabel">conf<span style={{color: '#4572c2'}}>i</span>rm password</label>
                                <input
                                    type={showPass.pass3 ? 'password' : 'text'} 
                                    onChange={handleRegister}
                                    className="input-rounded-fields form-control"
                                    name="PASSWD2"
                                    value={register.PASSWD2}
                                    
                                    // style={{borderColor:simpleValidator2.current.fieldValid('confirm_password') ? '' : 'red'}}
                                    // onBlur={() => simpleValidator2.current.showMessageFor('confirm_password')}
                                    />

                                    <i className="fa fa-eye" onClick={()=>showPassEye('pass3')} />
                                <div className="text-danger">
                                    { Boolean(errorsRegister.PASSWD2) && errorsRegister.PASSWD2}
                                    
                                    {/* {simpleValidator2.current.message('confirm_password', register.PASSWD2, `required|alpha_num|in:${register.PASSWD}`, {messages: {in: 'Passwords need to match!'}})} */}
                                </div>
                            </div>
                            <div className="pt-1">
                                <p>your personal data w<span style={{color: '#4572c2'}}>i</span>ll be used to support your exper<span style={{color: '#4572c2'}}>i</span>ence thoughtout th<span style={{color: '#4572c2'}}>i</span>s webs<span style={{color: '#4572c2'}}>i</span>te, to manage accesss to your account, and for other purposes descr<span style={{color: '#4572c2'}}>i</span>bed <span style={{color: '#4572c2'}}>i</span>n our <a href="#" onClick={()=>setShow(true)}>pr<span style={{color: '#4572c2'}}>i</span>vacy pol<span style={{color: '#4572c2'}}>i</span>cy</a>.
                                </p>
                            </div>

                    { [0, -1, 1, 11, 12].indexOf(errCode) === -1 && rel === 2 && <>
                        <div style={{color: 'red', textAlign: 'center'}}>error code: {errCode}</div>
                        <br/></>}

                    {errCode === 11 && rel === 2 && <> <div style={{color: 'red', textAlign: 'center'}} >user with such a email already registered</div><br/></>}
                    {errCode === 12 && rel === 2 && <> <div style={{color: 'red', textAlign: 'center'}} >user with such a phone number already registered</div><br/></>}
                    <div>
                    <Button className="button loginButton " onClick={() => sendRegister()}>join us</Button>
                        {/* <input type="button" value="REGISTER" className="login-buttons" onClick={() => sendRegister()}/> */}
                    </div>
                    
                </div>
                
            </div>}

        </div>
        }

        {page === 1 && 
            <div className="container">
                <div className="text-center py-5">
                    <h1>forgot password</h1>
                </div>

                {[0, -1, 14].indexOf(errCode) !== -1 &&
                    <>

                    <div className="text-center pb-2">
                        <p>forgot your password? please enter your username or ema<span style={{color: '#4572c2'}}>i</span>l address. you w<span style={{color: '#4572c2'}}>i</span>ll rece<span style={{color: '#4572c2'}}>i</span>ve a temporary password v<span style={{color: '#4572c2'}}>i</span>a ema<span style={{color: '#4572c2'}}>i</span>l.</p>
                    </div>

                    <div className="field py-2">
                    <label className="loginLabel">ema<span style={{color: '#4572c2'}}>i</span>l address*</label>
                        <input
                            type="email"
                            className="input-rounded-fields form-control"
                            name="EMAIL"
                            onChange={handleForgot}
                            value={forgot.EMAIL}
                            onBlur={() => simpleValidator3.current.showMessageFor('email')}/>
                        <div className="text-danger">
                            {simpleValidator3.current.message('email', forgot.EMAIL, 'required|email')}
                        </div>
                    </div>
                    <div className="text-center">
                    <Button className="button loginButton " onClick={() => sendReset()}>reset password</Button>
                        {/* <button type="submit" className="forget-btn my-3 " onClick={() => sendReset()}>Reset Password</button> */}
                    </div>
                    {/* {rel === 3 && <div style={{color:'red'}}>Error code {errCode}</div>} */}

                    {errCode === 14 && rel === 3 && <> <div style={{color: 'red', textAlign: 'center'}} >user not found</div><br/></>}
                    
                    </>
                }

                {errCode === 1 &&
                    <p style={{textAlign:'center'}}>
                        password has been sent to your mailbox.<br/> 
                        you will be able to set new password in Personal cabinet
                    </p>
                }

               
            </div>
        }

        </div>
            <div className="modal-footer rmve">
            <Button className="button-line-b"  onClick={()=>{closePopup({status:0})}}>close</Button>
                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{closePopup({status:0})}}>Close</button> */}
            </div>
        </Popup>

        {show && <PopupLegal id={1} closePopup={()=>setShow(false)} />}

        </>

    )
}
const mapStateToProps = (state) => ({
    lang: state.config.LANG,
    errCode: state.config.errCode,
    initWebSait:state.publicUser.initWebSait,
    isAuthenticated: configSelectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
    onUserRegistration: publicUserOperations.userRegistration,
    onUserAutorization: publicUserOperations.userAutorization,
    onForgotPasswordEmail: publicUserOperations.forgotPasswordEmail,
    onUserRegistrationVendor: publicUserOperations.userRegistrationVendor,
    onUserVendorAuthentification: publicUserOperations.userVendorAuthentification,
    onRegistrationUserOrVender: publicUserOperations.registrationUserOrVender,
    // resetLoginErr: authActions.logoutSuccess,
    onClaerErrorCode: configActions.claerErrorCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupLoginRegister);

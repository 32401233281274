import { useEffect, useRef, useState } from "react"
import Popup from "../../components/Popup/Popup"
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";

import InputMask from 'react-input-mask';
import {DOMAIN} from '../../config/Url'
import { privateUserOperations } from "../../redux/privateUser";


const INITIAL_STATE = {
    ADDRESS_TYPE:0,  //   - (0 - Billling address, 1 - Shipping address)
    FIRST_NAME:"",
    LAST_NAME:"",
    COUNTRY_REGION:"Canada",
    APARTAMENT_SUITE_UNIT:"",
    STREET_ADDRESS:"",
    TOWN_CITY:"",
    STATE:"",
    ZIP_POSTAL_CODE:"",
    PHONE:"",
    EMAIL:""
}

const PopupEditAddress = ({
    title,
    errCode,
    closePopup,
    type,
    onUpdateUserAddress,
    billAddress={},
    shipAddress={}
}) => {
    const [address, setAddress] = useState(INITIAL_STATE)
    const [useBill, setUseBill] = useState(false);
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator())

    const [BillAddress] = useState(billAddress)
    const [ShipAddress] = useState(shipAddress)

    useEffect(()=>{

        INITIAL_STATE.ADDRESS_TYPE = type
        if(type === 0){
            setAddress(Object.keys(BillAddress).length === 0 ? INITIAL_STATE : BillAddress)
        }else if(type === 1){
            setAddress(Object.keys(ShipAddress).length === 0 ? INITIAL_STATE : ShipAddress)

        }
    }, [type, BillAddress, ShipAddress])

    useEffect(()=>{
        if(errCode === 1){
            closePopup()
        }
    }, [errCode])

    useEffect(()=>{
        console.log({BillAddress, ShipAddress});
    }, [BillAddress, ShipAddress])

    const saveAddress = () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)

            return
        }

        if(clearPhone(address.PHONE).length > 10) return

        onUpdateUserAddress({DOMAIN, ...address, ADDRESS_TYPE:type})
    }
    
    const handleAddress = ({target: {name, value}}) => {

        setAddress(state => ({...state, [name]: value}))
    }
    

    const handleUseBill = ({target:{checked}}) =>{
        INITIAL_STATE.ADDRESS_TYPE = 1
        if(checked){
            setAddress(Object.keys(BillAddress).length === 0 ? INITIAL_STATE : BillAddress)
        }else{
            setAddress(Object.keys(ShipAddress).length === 0 ? INITIAL_STATE : ShipAddress)
        }
        setUseBill(checked)
    }

    const clearPhone = phone => {
        return phone.replace( /[^0-9]/gi, '')
    }


    return (
        <Popup overflow={true} title={title} closePopup={closePopup} width="1000px">
            <div className="modal-body">
                <div className="container">
             
                <div className="row">
                    <div className="px-5 col-md-12">
                        <div>
                            <div className="">

                                {type === 1 && Object.keys(BillAddress).length !== 0 &&
                                    <div className="pr-3 mb-3 w-50">
                                        <span>use b<span style={{color:'#4572C2'}}>i</span>ll<span style={{color:'#4572C2'}}>i</span>ng address</span>
                                        <input
                                            type="checkbox"
                                            className="ml-2"
                                            checked={useBill}
                                            onChange={handleUseBill}
                                                />
                                        
                                    </div>
                                }
                                
                                <div className="d-flex display-block">
                                    
                                    <div className="pr-3 w-50">
                                        <span>f<span style={{color:'#4572C2'}}>i</span>rst name *</span><br></br>
                                        <input
                                            type="text"
                                            onChange={handleAddress}
                                            className="w-100 form-control px-4 checkout-input-small"
                                            name="FIRST_NAME"
                                            value={address.FIRST_NAME}
                                            onBlur={()=>simpleValidator.current.showMessageFor('Firstname')} />
                                        <div className="text-danger">
                                            {simpleValidator.current.message('Firstname', address.FIRST_NAME, 'required')}
                                        </div>
                                    </div>
                                    <div className=" w-50">
                                        <span>last name *</span><br></br>
                                        <input
                                            type="text"
                                            onChange={handleAddress}
                                            className="w-100 form-control px-4 checkout-input-small"
                                            name="LAST_NAME"
                                            value={address.LAST_NAME}
                                            onBlur={()=>simpleValidator.current.showMessageFor('LAST_NAME')} />
                                        <div className="text-danger">
                                            {simpleValidator.current.message('Lastname', address.LAST_NAME, 'required')}
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>country / reg<span style={{color:'#4572C2'}}>i</span>on *</span><br></br>
                                    <select className="form-control mdb-select md-form w-100 px-4 select-custom-styling"
                                    
                                        value={address.COUNTRY_REGION}
                                        onChange={({target:{value}}) => setAddress(state => ({...state, COUNTRY_REGION:value}))}
                                    >
                                        {/* <option value="" className="text-success" disabled selected></option> */}
                                        <option value="Canada" >Canada</option>
                                        <option value="USA">USA</option>
                                        {/* <option value="Germany">Germany</option>
                                        <option value="France">France</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Japan">Japan</option> */}
                                    </select>
                                </div>
                                <div className="py-2">
                                    <span>street address *</span><br></br>
                                    <input
                                        type="text"
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large mb-3 px-4"
                                        name="STREET_ADDRESS"
                                        value={address.STREET_ADDRESS}
                                        onBlur={()=>simpleValidator.current.showMessageFor('Address')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Address', address.STREET_ADDRESS, 'required')}
                                    </div>
                                        {/* <input type="text"
                                               className="form-control px-4 checkout-input-large px-4"
                                        /> */}
                                </div>
                                <div className="py-2">
                                    <span>town / c<span style={{color:'#4572C2'}}>i</span>ty *</span><br></br>
                                    <input
                                        type="text"
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large"
                                        name="TOWN_CITY"
                                        value={address.TOWN_CITY}
                                        onBlur={()=>simpleValidator.current.showMessageFor('City')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('City', address.TOWN_CITY, 'required')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>{address.COUNTRY_REGION === 'Canada' ? 'province' : 'state'} *</span><br></br>

                                    <input
                                        type="text"
                                        onChange={handleAddress}
                                        name="STATE"
                                        value={address.STATE}
                                        className="form-control px-4 checkout-input-large"
                                        onBlur={()=>simpleValidator.current.showMessageFor('State')}
                                     />

                                    <div className="text-danger">
                                        {simpleValidator.current.message('State', address.STATE, 'required')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>{address.COUNTRY_REGION === 'Canada' ? 'postal code' : 'zip'} *</span><br></br>
                                    {/* <input
                                        type="number"
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large hide-arrow"
                                        name="ZIP_POSTAL_CODE"
                                        value={address.ZIP_POSTAL_CODE}
                                        onBlur={()=>simpleValidator.current.showMessageFor('ZipCode')}
                                        maxLength={5}
                                        /> */}

                                    <InputMask
                                        mask={address.COUNTRY_REGION === 'Canada' ? '*** ***' : '99999'}
                                        maskChar={null}
                                   
                                 
                                        onChange={handleAddress}
                                        className="form-control px-4 checkout-input-large hide-arrow"
                                        name="ZIP_POSTAL_CODE"
                                        value={address.ZIP_POSTAL_CODE}
                                        autoComplete="nope"
                                        onBlur={()=>simpleValidator.current.showMessageFor('ZipCode')}
                                    />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('ZipCode', address.ZIP_POSTAL_CODE, 'required')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <span>phone *</span><br></br>
                                    <div className="d-flex">
                                        <select className=" md-form pl-2 mr-2 form-control selecting-custom-styling" 
                                            name="PHONE_COUNTRY_CODE"
                                            onChange={handleAddress}
                                            value={address.PHONE_COUNTRY_CODE} 
                                            style={{width:'16%'}} 
                                        >
                                            <option value="+1" >+1</option>
                                            <option value="+91">+91</option>
                                            <option value="+92">+92</option>
                                        </select>
                                        <div style={{width: '100%'}}>
                                            <InputMask
                                                mask="(999)999-9999" 
                                                maskChar={null}
                                                style={{width: '463px !important'}}
                                                type="tel"
                                                onChange={handleAddress}
                                                className="adjust-width input-rounded-fields form-control  float-right"
                                                name="PHONE"
                                                value={address.PHONE}
                                                autoComplete="nope"
                                                onBlur={()=>simpleValidator.current.showMessageFor('required|phone_number|min:13')}
                                            />
                                        </div>
                                    </div>

                                    <div className="text-danger">
                                        {simpleValidator.current.message('phone_number', address.PHONE, 'required')}
                                    </div> 
                                    {clearPhone(address.PHONE).length > 10 && <div className="text-danger">
                                        the phone number may not be greater than 10 digits 
                                    </div> }
                               
                                </div>
                            
                                <div className="py-2">
                                    <span>ema<span style={{color:'#4572C2'}}>i</span>l address *</span><br></br>
                                    <input
                                        type="email"
                                        className="form-control px-4 checkout-input-large"
                                        name="EMAIL"
                                        onChange={handleAddress}
                                        value={address.EMAIL}
                                        onBlur={() => simpleValidator.current.showMessageFor('email')}/>
                                    <div className="text-danger">
                                        {simpleValidator.current.message('email', address.EMAIL, 'required|email')}
                                    </div>
                                </div>
                                <div className="py-2">
                                    <button type="submit" className="button" onClick={() => saveAddress()}>Save </button>
                                    <button type="button" className="button buttonRed" onClick={() => closePopup()}>close </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>
            {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{closePopup()}}>Close</button>
            </div> */}
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang: state.config.lang,
    errCode: state.config.errCode,
})

const mapDispatchToProps = {
    onUpdateUserAddress: privateUserOperations.updateUserAddress,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupEditAddress);

import { useEffect, useState } from "react";
import { createPortal } from 'react-dom';
const modal = document.querySelector('#modal')

const styleOverFlow = {
    alignItems: 'flex-start',
    overflow: 'auto',
    padding: '40px 0'
}

const Popup = ({
    title='',
    children,
    closePopup,
    width = '600px',
    style = {},
    className = '',
    classNamePopup='',
    backdrop = true,

    backgroundImage='',
    backgroundColorDialog='#fff',
    overflow = false
}) => {

    const [getStyleOverFlow, setStyleOverFlow] = useState({})

    useEffect(()=>{
        window.addEventListener('keydown', handler)
         document.body.style.overflow = 'hidden';
         setTimeout(()=>{document.body.style.overflow = 'hidden';}, 100)
         setTimeout(()=>{document.body.style.overflow = 'hidden';}, 500)
         setTimeout(()=>{document.body.style.overflow = 'hidden';}, 1000)
        return () => {
            window.removeEventListener('keydown', handler) 
            document.body.style.overflow = 'auto';
        }
    }, [])

    useEffect(()=>{
        if(overflow){
            setStyleOverFlow(styleOverFlow)
        }
    }, [overflow])

    const handler = evt => {
        if (evt.key === 'Escape') {
            // closePopup({status:0})
        }
    }
    const handleBackdrop = evt =>{
        // if(backdrop && evt.currentTarget === evt.target) closePopup({status:0})
    }

    return createPortal(
        <div id={`${title}_popup`} className={["popup", classNamePopup].join(' ')}onClick={handleBackdrop} style={{backgroundImage:backgroundImage === '' ? null : `url(${backgroundImage})`, ...getStyleOverFlow}}>
            <div className={["popup-dialog", className].join(' ')} style={{...style, width, backgroundColor:backgroundColorDialog}}>
                {title !== '' && <div className="popup-head">{title}</div>}
                <div className="popup-body">{children}</div>
            </div>
        </div>,
        modal
    ) 
}

export default Popup
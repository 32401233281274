import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import {authSelectors} from './../redux/auth'

import Header from './Header';
import Footer from './Footer';
import PopupAlert from "./PopupAlert/PopupAlert";
import { configActions, configSelectors } from "../redux/config";
import { useEffect, useState } from "react";
import { publicUserOperations } from "../redux/publicUser";
import { DOMAIN } from "../config/Url";
import Loading from "./Loading";
import PopupLoginRegister from "./PopupLoginRegister";
import { privateUserOperations } from "../redux/privateUser";



function MainLayout({ children }) {
    
    const [show, setShow] = useState(false)

    const loading = useSelector(configSelectors.getLoading)
    const lang = useSelector(configSelectors.getLang)
    const errCode = useSelector(configSelectors.getErrCode)
    const showLoginRegister = useSelector(configSelectors.getShowLoginRegister)
    const isAuthenticated = useSelector(configSelectors.getIsAuthenticated)

    const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(publicUserOperations.initWebSait({
          DEFAULT_COLUMN:lang,
          DOMAIN
      }))

      // if(isAuthenticated){
      //   dispatch(privateUserOperations.getCart({DOMAIN, DEFAULT_COLUMN:lang}))
      // }
    }, [])

    useEffect(()=>{
      if(errCode === 1){
        dispatch(publicUserOperations.initWebSait({
            DEFAULT_COLUMN:lang,
            DOMAIN
        }))

        // if(isAuthenticated){
        //   dispatch(privateUserOperations.getCart({DOMAIN, DEFAULT_COLUMN:lang}))
        // }
      }
    }, [errCode, isAuthenticated])

    const closeAuth = ({status}) =>{
      dispatch(configActions.showLoginRegister(false))
    }

    return(
      <>
      <Header />
      {children}
      <Footer />

        {loading && <Loading />}

        <PopupAlert />

        {showLoginRegister && <PopupLoginRegister closePopup={state=>closeAuth(state)} /> }
      </>
    
    );
  }; 


const PublicRoute = ({component:Component, redirectTo, ...routeProps}) =>{
    const isAuthenticated = useSelector(configSelectors.getIsAuthenticated)
    return <Route 
        {...routeProps}

        render={props => 
            isAuthenticated && routeProps.restricted ? <Redirect to={redirectTo} /> : (<MainLayout><Component {...props} /></MainLayout>)
        }
    />
}

PublicRoute.defaultProps = {
    redirectTo:'/'
}


export default PublicRoute;
import {createAction} from '@reduxjs/toolkit';

export default{
    initWebSaitRequest:createAction('model/initWebSaitRequest'),
    initWebSaitSuccess:createAction('model/initWebSaitSuccess'),
    initWebSaitError:createAction('model/initWebSaitError'),

    userRegistrationRequest:createAction('model/userRegistrationRequest'),
    userRegistrationSuccess:createAction('model/userRegistrationSuccess'),
    userRegistrationError:createAction('model/userRegistrationError'),

    userAutorizationRequest:createAction('model/userAutorizationRequest'),
    userAutorizationSuccess:createAction('model/userAutorizationSuccess'),
    userAutorizationError:createAction('model/userAutorizationError'),

    forgotPasswordEmailRequest:createAction('model/forgotPasswordEmailRequest'),
    forgotPasswordEmailSuccess:createAction('model/forgotPasswordEmailSuccess'),
    forgotPasswordEmailError:createAction('model/forgotPasswordEmailError'),

    getCatalogsListRequest:createAction('model/getCatalogsListRequest'),
    getCatalogsListSuccess:createAction('model/getCatalogsListSuccess'),
    getCatalogsListError:createAction('model/getCatalogsListError'),

    getProductsByGroupRequest:createAction('model/getProductsByGroupRequest'),
    getProductsByGroupSuccess:createAction('model/getProductsByGroupSuccess'),
    getProductsByGroupError:createAction('model/getProductsByGroupError'),

    getProductsRequest:createAction('model/getProductsRequest'),
    getProductsSuccess:createAction('model/getProductsSuccess'),
    getProductsError:createAction('model/getProductsError'),

    userRegistrationVendorRequest:createAction('model/userRegistrationVendorRequest'),
    userRegistrationVendorSuccess:createAction('model/userRegistrationVendorSuccess'),
    userRegistrationVendorError:createAction('model/userRegistrationVendorError'),

    userVendorAuthentificationRequest:createAction('model/userVendorAuthentificationRequest'),
    userVendorAuthentificationSuccess:createAction('model/userVendorAuthentificationSuccess'),
    userVendorAuthentificationError:createAction('model/userVendorAuthentificationError'),

    marketplaceGetVendorsListRequest:createAction('model/marketplaceGetVendorsListRequest'),
    marketplaceGetVendorsListSuccess:createAction('model/marketplaceGetVendorsListSuccess'),
    marketplaceGetVendorsListError:createAction('model/marketplaceGetVendorsListError'),

    getMarketplaceProductsSpecialOffersRequest:createAction('model/getMarketplaceProductsSpecialOffersRequest'),
    getMarketplaceProductsSpecialOffersSuccess:createAction('model/getMarketplaceProductsSpecialOffersSuccess'),
    getMarketplaceProductsSpecialOffersError:createAction('model/getMarketplaceProductsSpecialOffersError'),


    getMarketplaceProductsNftRequest:createAction('model/getMarketplaceProductsNftRequest'),
    getMarketplaceProductsNftSuccess:createAction('model/getMarketplaceProductsNftSuccess'),
    getMarketplaceProductsNftError:createAction('model/getMarketplaceProductsNftError'),

    marketplaceGetProductByIdRequest:createAction('model/marketplaceGetProductByIdRequest'),
    marketplaceGetProductByIdSuccess:createAction('model/marketplaceGetProductByIdSuccess'),
    marketplaceGetProductByIdError:createAction('model/marketplaceGetProductByIdError'),

    getFeedbackListRequest:createAction('model/getFeedbackListRequest'),
    getFeedbackListSuccess:createAction('model/getFeedbackListSuccess'),
    getFeedbackListError:createAction('model/getFeedbackListError'),

    getProductsByCatalogRequest:createAction('model/getProductsByCatalogRequest'),
    getProductsByCatalogSuccess:createAction('model/getProductsByCatalogSuccess'),
    getProductsByCatalogError:createAction('model/getProductsByCatalogError'),

    getProductsSearchByNameCatalogRequest:createAction('model/getProductsSearchByNameCatalogRequest'),
    getProductsSearchByNameCatalogSuccess:createAction('model/getProductsSearchByNameCatalogSuccess'),
    getProductsSearchByNameCatalogError:createAction('model/getProductsSearchByNameCatalogError'),

    getMarketplaceProductsNotNftRequest:createAction('model/getMarketplaceProductsNotNftRequest'),
    getMarketplaceProductsNotNftSuccess:createAction('model/getMarketplaceProductsNotNftSuccess'),
    getMarketplaceProductsNotNftError:createAction('model/getMarketplaceProductsNotNftError'),

    getProductByPricingParametersRequest:createAction('model/getProductByPricingParametersRequest'),
    getProductByPricingParametersSuccess:createAction('model/getProductByPricingParametersSuccess'),
    getProductByPricingParametersError:createAction('model/getProductByPricingParametersError'),

    getLegalInfoByIdRequest:createAction('model/getLegalInfoByIdRequest'),
    getLegalInfoByIdSuccess:createAction('model/getLegalInfoByIdSuccess'),
    getLegalInfoByIdError:createAction('model/getLegalInfoByIdError'),

    getLegalInfoByTypeRequest:createAction('model/getLegalInfoByTypeRequest'),
    getLegalInfoByTypeSuccess:createAction('model/getLegalInfoByTypeSuccess'),
    getLegalInfoByTypeError:createAction('model/getLegalInfoByTypeError'),

    createSubscribeRequest:createAction('model/createSubscribeRequest'),
    createSubscribeSuccess:createAction('model/createSubscribeSuccess'),
    createSubscribeError:createAction('model/createSubscribeError'),

    vendorInfoByIdRequest:createAction('model/vendorInfoByIdRequest'),
    vendorInfoByIdSuccess:createAction('model/vendorInfoByIdSuccess'),
    vendorInfoByIdError:createAction('model/vendorInfoByIdError'),

    getProductsWithFilterRequest:createAction('model/getProductsWithFilterRequest'),
    getProductsWithFilterSuccess:createAction('model/getProductsWithFilterSuccess'),
    getProductsWithFilterError:createAction('model/getProductsWithFilterError'),

    addProductIssueRequest:createAction('model/addProductIssueRequest'),
    addProductIssueSuccess:createAction('model/addProductIssueSuccess'),
    addProductIssueError:createAction('model/addProductIssueError'),


    registrationUserOrVenderRequest:createAction('model/registrationUserOrVenderRequest'),
    registrationUserOrVenderSuccess:createAction('model/registrationUserOrVenderSuccess'),
    registrationUserOrVenderError:createAction('model/registrationUserOrVenderError'),

    checkTokenRequest:createAction('model/checkTokenRequest'),
    checkTokenSuccess:createAction('model/checkTokenSuccess'),
    checkTokenError:createAction('model/checkTokenError'),

}
import { configActions } from '../config';
import { instance, publicRequest } from '../publicRequest';
import publicUserActions from './publicUser-actions';


export default {

    initWebSait : credential => async (dispatch, state) => {

        publicRequest(state, dispatch, {disableToken:true})
        dispatch(publicUserActions.initWebSaitRequest());
    
        try{
            const res = await instance.post('/', {
                // name:"init_web_sait",
                name:"init_marketplace",
                parameters:credential
            }); 
            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.initWebSaitSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.initWebSaitError(error));
        }
    },

    userRegistration: credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.userRegistrationRequest())
    
        try{
            const res = await instance.post('/', {
                name:"USER_REGISTRATION_ACCOUNT",
                parameters:credential
            }); 
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.userRegistrationSuccess(res.data))
    
        }catch (error){
            dispatch(publicUserActions.userRegistrationError())
        }
    },
    
    userAutorization : credential => async (dispatch, state) => {
    
    
        publicRequest(state, dispatch)
        dispatch(publicUserActions.userAutorizationRequest())
    
        try{
            const res = await instance.post('/', {
                name:"USER_AUTORISATION_BY_EMAIL",
                parameters:credential
            });
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.userAutorizationSuccess(res.data))
        }catch (error){
            dispatch(publicUserActions.userAutorizationError())
        }
    },

    forgotPasswordEmail: credential => async (dispatch, state) => {
        publicRequest(state, dispatch)
        dispatch(publicUserActions.forgotPasswordEmailRequest())
        try{
            const res = await instance.post('/', {
                name:"USER_FORGOT_PASSWORD_BY_EMAIL",
                parameters:credential
            }); 
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.forgotPasswordEmailSuccess(res.data))

        }catch (error){
            dispatch(publicUserActions.forgotPasswordEmailError())
        }
    },

    getCatalogsList : credential => async (dispatch, state) => {

        publicRequest(state, dispatch)
        dispatch(publicUserActions.getCatalogsListRequest());
    
        try{
            const res = await instance.post('/', {
                name:"get_catalogs_list",
                parameters:credential
            }); 
            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.getCatalogsListSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getCatalogsListError(error));
        }
    },
    getProductsByGroup : credential => async (dispatch, state) => {

    
        publicRequest(state, dispatch)
        dispatch(publicUserActions.getProductsByGroupRequest());
    
        try{
            const res = await instance.post('/', {
                name:"get_products_by_group",
                parameters:credential
            }); 
            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.getProductsByGroupSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getProductsByGroupError(error));
        }
    },
    getProducts : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)

        dispatch(publicUserActions.getProductsRequest());
    
        try{
            const res = await instance.post('/', {
                name:"get_products",
                parameters:credential
            }); 
            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.getProductsSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getProductsError(error));
        }
    },
    userRegistrationVendor : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.userRegistrationVendorRequest());
    
        try{
            const res = await instance.post('/', {
                name:"user_registration_vendor",
                parameters:credential
            }); 
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.userRegistrationVendorSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.userRegistrationVendorError(error));
        }
    },

    userVendorAuthentification : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.userVendorAuthentificationRequest());
    
        try{
            const res = await instance.post('/', {
                name:"user_vendor_authentification",
                parameters:credential
            }); 

            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.userVendorAuthentificationSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.userVendorAuthentificationError(error));
        }
    },

    marketplaceGetVendorsList : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.marketplaceGetVendorsListRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_vendors_list",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.marketplaceGetVendorsListSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.marketplaceGetVendorsListError(error));
        }
    },

    getMarketplaceProductsSpecialOffers : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getMarketplaceProductsSpecialOffersRequest());
    
        try{
            const res = await instance.post('/', {
                name:"get_marketplace_products_special_offers",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.getMarketplaceProductsSpecialOffersSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getMarketplaceProductsSpecialOffersError(error));
        }
    },

    getMarketplaceProductsNft : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getMarketplaceProductsNftRequest());
    
        try{
            const res = await instance.post('/', {
                name:"get_marketplace_products_nft",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.getMarketplaceProductsNftSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getMarketplaceProductsNftError(error));
        }
    },

    marketplaceGetProductById : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.marketplaceGetProductByIdRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_product_by_id",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.marketplaceGetProductByIdSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.marketplaceGetProductByIdError(error));
        }
    },
    getFeedbackList : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getFeedbackListRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_feedback_list",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.getFeedbackListSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getFeedbackListError(error));
        }
    },

    getProductsByCatalog: credential => async (dispatch, state) => {
        publicRequest(state, dispatch)
        dispatch(publicUserActions.getProductsByCatalogRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_products_by_catalog",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.getProductsByCatalogSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(publicUserActions.getProductsByCatalogError())
        }
    },

    getProductsSearchByNameCatalog: credential => async (dispatch, state) => {
        publicRequest(state, dispatch)
        dispatch(publicUserActions.getProductsSearchByNameCatalogRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"marketplace_get_products_search_by_name_catalog",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.getProductsSearchByNameCatalogSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(publicUserActions.getProductsSearchByNameCatalogError())
        }
    },

    getMarketplaceProductsNotNft : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getMarketplaceProductsNotNftRequest());
    
        try{
            const res = await instance.post('/', {
                name:"get_marketplace_products_not_nft",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            dispatch(publicUserActions.getMarketplaceProductsNotNftSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getMarketplaceProductsNotNftError(error));
        }
    },

    getProductByPricingParameters : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getProductByPricingParametersRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_product_by_pricing",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))

            dispatch(publicUserActions.getProductByPricingParametersSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getProductByPricingParametersError(error));
        }
    },

    getLegalInfoById : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getLegalInfoByIdRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_legal_info_by_id",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.getLegalInfoByIdSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getLegalInfoByIdError(error));
        }
    },

    getLegalInfoByType : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getLegalInfoByTypeRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_legal_info_by_type",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.getLegalInfoByTypeSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getLegalInfoByTypeError(error));
        }
    },

    createSubscribe : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.createSubscribeRequest());
    
        try{
            const res = await instance.post('/', {
                name:"create_subscribe",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.createSubscribeSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.createSubscribeError(error));
        }
    },

    vendorInfoById : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.vendorInfoByIdRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_vendor_info_by_id",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.vendorInfoByIdSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.vendorInfoByIdError(error));
        }
    },

    vendorInfoById : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.vendorInfoByIdRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_vendor_info_by_id",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.vendorInfoByIdSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.vendorInfoByIdError(error));
        }
    },

    getProductsWithFilter : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.getProductsWithFilterRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_get_products_with_filter",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.getProductsWithFilterSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.getProductsWithFilterError(error));
        }
    },

    addProductIssue : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.addProductIssueRequest());
    
        try{
            const res = await instance.post('/', {
                name:"marketplace_add_product_issue",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.addProductIssueSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.addProductIssueError(error));
        }
    },

    registrationUserOrVender : credential => async (dispatch, state) => {


        publicRequest(state, dispatch)
        dispatch(publicUserActions.registrationUserOrVenderRequest());
    
        try{
            const res = await instance.post('/', {
                name:"REGISTRATION_USER_OR_VENDOR",
                parameters:credential
            }); 

            // dispatch(configActions.claerErrorCode())
            dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.registrationUserOrVenderSuccess(res.data));
    
        }catch (error){
            dispatch(publicUserActions.registrationUserOrVenderError(error));
        }
    },
    
    checkToken: credential => async (dispatch, state) => {
        publicRequest(state, dispatch, {disableToken:true})
        dispatch(publicUserActions.checkTokenRequest())
    
        try{
            
            const res = await instance.post('/', {
                name:"check_token",
                parameters:credential
            }); 

            dispatch(configActions.claerErrorCode())
            // dispatch(configActions.configErrorCode(res.data))
            dispatch(publicUserActions.checkTokenSuccess(res.data))
    
        }catch (error){
            // console.log(error);
            dispatch(publicUserActions.checkTokenError())
        }
    },

}


export const DOMAIN = process.env.NODE_ENV === 'production' ? `${window.location.origin}` : "https://hiiroad.com"
export const REDIRECT_URI = window.location.origin;

export const WEB_PICTURES_FILE= `${DOMAIN}:8444/files/file/WEB_PICTURES/`
export const WEB_PICTURES_UPLOAD= `${DOMAIN}:8444/files/upload/WEB_PICTURES`
export const WEB_PICTURES_FILE_RESIZER= `${DOMAIN}:8444/files/fileresizer/WEB_PICTURES/`

export const PUBLIC_USER = `${DOMAIN}:8444/api/public/user/wpa` //- без авторизации
export const PRIVATE_USER = `${DOMAIN}:8444/api/private/user/wpa` //- с авторизацией  

export const ADMIN_URL = process.env.NODE_ENV === 'production' ? "https://admin.hiiroad.com/" : "http://localhost:3000/"
export const BRAINTREE_CLIENT_TOKEN = '';


// vendorname@vendorname.com
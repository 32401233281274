import { createSelector } from '@reduxjs/toolkit';

// const getMenu = state =>  Boolean(state.config.menu);
const getLang = state =>  state.config.lang;
const getLoading = state =>  state.config.loading;


// const getProjectId = state => state.auth.project_id
// const getThree3dProjectId = state => state.three3d.json2dModel.project_id || 0;

// const getAuthUserName = state => state.auth.user.name;
// const getError = state => state.auth.error;
// const authLoading = state => state.auth.loading;

// const projectId = createSelector([getIsAuthenticated, getProjectId, getThree3dProjectId], (isAuthenticated, project_id, three3d_project_id)=>{
//     return isAuthenticated ? project_id : three3d_project_id
// })

export default {
    // getMenu,
    // getLang
    getLang : state =>  state.config.lang,
    getErrCode : state =>  state.config.errCode,
    getLoading : state =>  state.config.loading,
    getShowLoginRegister: state =>  state.config.showLoginRegister,
    getIsAuthenticated: state => state.config.isAuthenticated,
    getFlag: state => state.config.flag,
    getFullName: state => state.config.resultUser?.FULL_NAME || ''

}
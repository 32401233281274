import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import configActions from './config-actions'

import jsonLang from '../../json/lang.json';
import jsonCurrency from '../../json/currency.json';
import { publicUserActions } from '../publicUser';
import privateUserActions from '../privateUser/privateUser-actions';


export default combineReducers({
    errCode:createReducer(-1, {
        [configActions.claerErrorCode]:() => -1,
        // [configActions.configHttpRequest]:() => -1,
        // [configActions.configHttpSuccess]:(_, {payload}) => payload.err_code,
        // [configActions.configHttpError]:() => 1,
        [configActions.configErrorCode]:(_, {payload}) => payload?.err_code || -1,

    }),

    lang:createReducer(jsonLang[0].type, {
        [configActions.updateLang]:(_, {payload}) => payload,
    }),

    currency:createReducer(jsonCurrency[0].currency, {
        [configActions.updateCurrency]:(_, {payload}) => payload,
        [publicUserActions.userRegistrationSuccess]:(_, {payload}) => payload?.result?.CURRENCY || jsonCurrency[0].currency,
        [publicUserActions.userAutorizationSuccess]:(_, {payload}) => payload?.result?.CURRENCY || jsonCurrency[0].currency,
        [publicUserActions.userRegistrationVendorSuccess]:(_, {payload}) => payload?.result?.CURRENCY || jsonCurrency[0].currency,
        [publicUserActions.userVendorAuthentificationSuccess]:(_, {payload}) => payload?.result?.CURRENCY || jsonCurrency[0].currency,
        [publicUserActions.registrationUserOrVenderSuccess]:(_, {payload}) => payload?.result?.CURRENCY || jsonCurrency[0].currency,
        // [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload?.result?.USER_PROPERTIES?.CURRENCY || jsonCurrency[0].currency,
        
    }),

    orderId:createReducer(0, {
        [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload?.result?.USER_CART?.ID_ORDER || 0,
    }),

    getCart:createReducer({}, {
        [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload?.result?.USER_CART || {},
    }),

    getWishList:createReducer([], {
        [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload?.result?.WISH_LIST || [],
    }),

    showFilter:createReducer(false, {
        [configActions.showFilter]:(_, {payload}) => payload,
    }),

    showLoginRegister:createReducer(false, {
        [configActions.showLoginRegister]:(_, {payload}) => payload,
    }),

    flag:createReducer(1, {
        [publicUserActions.logoutUserSuccess]:() => 1,

        [publicUserActions.userRegistrationSuccess]:() => 0,
        [publicUserActions.userAutorizationSuccess]:() => 0,
        [publicUserActions.userRegistrationVendorSuccess]:() => 0,
        [publicUserActions.userVendorAuthentificationSuccess]:() => 0,
        [publicUserActions.registrationUserOrVenderSuccess]:() => 0,
        [publicUserActions.forgotPasswordEmailSuccess]:() => 0,

        [configActions.flag]:(_, {payload}) => payload,
    }),

    pinCodeStatus:createReducer(-1, {
        [configActions.pinCodeStatus]:(_, {payload}) => payload,
    }),

    loading:createReducer(false, {
        [configActions.configHttpRequest]:() => true,
        [configActions.configHttpSuccess]:() => false,
        [configActions.configHttpError]:() => false,
        [configActions.loader]:(_, {payload}) => payload,
    }),

    

    errText:createReducer('', {
        [configActions.configHttpRequest]:() => '',
        [configActions.configHttpSuccess]:(_, {payload}) => payload.err_t,
        [configActions.configHttpError]:(_, {payload}) => payload?.err_t,
    }),

   

    token:createReducer({}, {
        [configActions.logout]:()=>{return{}},
        [configActions.status401]:()=>{return{}},
        [privateUserActions.logoutUserSuccess]:()=> {return {}},
        [publicUserActions.userRegistrationSuccess]:(_, {payload}) => payload?.token,
        [publicUserActions.userAutorizationSuccess]:(_, {payload}) => payload?.token,
        [publicUserActions.forgotPasswordEmailSuccess]:(_, {payload}) => payload?.token,
        [publicUserActions.userRegistrationVendorSuccess]:(_, {payload}) => payload?.token,
        [publicUserActions.userVendorAuthentificationSuccess]:(_, {payload}) => payload?.token,
        [publicUserActions.registrationUserOrVenderSuccess]:(_, {payload}) => payload?.token,
    }),

    resultUser:createReducer({}, {
        [configActions.logout]:()=>{return{}},
        [configActions.status401]:()=>{return{}},
        [privateUserActions.logoutUserSuccess]:()=> {return {}},
        [publicUserActions.userRegistrationSuccess]:(_, {payload}) => payload?.result,
        [publicUserActions.userAutorizationSuccess]:(_, {payload}) => payload?.result,
        [publicUserActions.forgotPasswordEmailSuccess]:(_, {payload}) => payload?.result,
        [publicUserActions.userRegistrationVendorSuccess]:(_, {payload}) => payload?.result,
        [publicUserActions.userVendorAuthentificationSuccess]:(_, {payload}) => payload?.result,
        [publicUserActions.registrationUserOrVenderSuccess]:(_, {payload}) => payload?.result,
        [privateUserActions.updateUserOrVendorProfileSuccess]:(_, {payload}) => payload?.result,
    }),

    user:createReducer({}, {
        [configActions.logout]:()=>{return{}},
        [configActions.status401]:()=>{return{}},
        [privateUserActions.logoutUserSuccess]:()=> {return {}},
        [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload?.result?.USER_PROPERTIES || {},
    }),

    isAuthenticated:createReducer(false, {
        [configActions.logout]:()=>false,
        [configActions.status401]:()=>false,
        [privateUserActions.logoutUserSuccess]:()=>false,
        [publicUserActions.userRegistrationSuccess]:(_, {payload}) => Boolean(payload?.token?.access_token),
        [publicUserActions.userAutorizationSuccess]:(_, {payload}) => Boolean(payload?.token?.access_token),
        [publicUserActions.forgotPasswordEmailSuccess]:(_, {payload}) => Boolean(payload?.token?.access_token),
        [publicUserActions.userRegistrationVendorSuccess]:(_, {payload}) => Boolean(payload?.token?.access_token),
        [publicUserActions.userVendorAuthentificationSuccess]:(_, {payload}) => Boolean(payload?.token?.access_token),
        [publicUserActions.registrationUserOrVenderSuccess]:(_, {payload}) => Boolean(payload?.token?.access_token)
    }),
})
import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import privateUserActions from './privateUser-actions';
// import { publicUserActions } from '../publicUser';
// import { publicUserActions } from '../publicUser';
// import publicUserActions from './../publicUser/publicUser-actions';







export default combineReducers({

    // err_code,
    // err_t,

    // getOrdersHistory: createReducer([], {
    //     [privateUserActions.getOrdersHistorySuccess]:(_, {payload}) => payload.result['#result-set-1'],
    // }),
    // getLegalInfoSaitSettingsList: createReducer({}, {
    //     [privateUserActions.getLegalInfoSaitSettingsListSuccess]:(_, {payload}) => payload.result,
    // }),

    getUserAddress:createReducer([], {
        [privateUserActions.getUserAddressSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getUserProfile:createReducer({}, {
        [privateUserActions.getUserProfileSuccess]:(_, {payload}) => payload.result,
    }),

    getUserOrdersHistory:createReducer([], {
        [privateUserActions.getUserOrdersHistorySuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getUserOrderById:createReducer({}, {
        [privateUserActions.getUserOrderByIdSuccess]:(_, {payload}) => payload.result,
    }),

    getUserDeliveryInfoById:createReducer({}, {
        [privateUserActions.getUserDeliveryInfoByIdSuccess]:(_, {payload}) => payload.result,
    }),

    // getCart:createReducer({}, {
    //     // [authActions.logoutSuccess]:() => {},
    //     // [userPrivateActions.getCartClear]:(_, {payload}) => payload,
    //     // [publicUserActions.initWebSaitSuccess]:(_, {payload}) =>{
    //     //         return payload.result?.USER_CART
    //     // },
    //     // [privateUserActions.getCartSuccess]:(_, {payload}) => payload.result,

    //     [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload?.result?.USER_CART || {},
    // }),
    // orderId:createReducer(0, {
    //     [privateUserActions.getCartSuccess]:(_, {payload}) => payload.result.ID_ORDER,
    //     // [privateUserActions.initWebSaitSuccess]:(_, {payload}) =>{
    //     //     return payload.result.USER_CART.ID_ORDER || 0
    //     // }
    // }),

    getAddProductToCart:createReducer({}, {
        [privateUserActions.getAddProductToCartSuccess]:(_, {payload}) => payload.result,
    }),

    getVendorProfile:createReducer({}, {
        [privateUserActions.getVendorProfileSuccess]:(_, {payload}) => payload.result,
    }),

    getVendorProductsWithFilter:createReducer({}, {
        [privateUserActions.getVendorProductsWithFilterSuccess]:(_, {payload}) => payload.result,
    }),

    getNewProductId:createReducer({}, {
        [privateUserActions.getVendorProductsWithFilterRequest]:() => {return{}},
        [privateUserActions.getVendorProductsWithFilterSuccess]:() => {return{}},
        [privateUserActions.getNewProductIdClear]:(_, {payload}) => payload,
        [privateUserActions.getNewProductIdSuccess]:(_, {payload}) => payload.result,
    }),

    getProductInfoGeneral:createReducer({}, {
        [privateUserActions.getVendorProductsWithFilterRequest]:() => {return{}},
        [privateUserActions.getVendorProductsWithFilterSuccess]:() => {return{}},
        // [privateUserActions.getProductInfoGeneralClear]:(_, {payload}) => payload,
        [privateUserActions.getProductInfoGeneralSuccess]:(_, {payload}) => payload.result,
    }),

    getProductImages:createReducer({}, {
        [privateUserActions.getVendorProductsWithFilterSuccess]:() => {return{}},
        [privateUserActions.getProductImagesRequest]:() => {return{}},
        [privateUserActions.getProductImagesSuccess]:(_, {payload}) => payload.result,
    }),

    getMeasures:createReducer([], {
        [privateUserActions.getMeasuresSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getProductSettingsDiscount:createReducer({}, {
        [privateUserActions.getProductSettingsDiscountSuccess]:(_, {payload}) => payload.result,
    }),

    getCart:createReducer({}, {
        [privateUserActions.getCartSuccess]:(_, {payload}) => payload.result,
    }),

    proceedToCheckout:createReducer({}, {
        [privateUserActions.proceedToCheckoutSuccess]:(_, {payload}) => payload.result,
    }),

    getReloadHistory:createReducer({}, {
        [privateUserActions.getReloadHistorySuccess]:(_, {payload}) => payload.result,
    }),
    vendorGetReloadHistory:createReducer({}, {
        [privateUserActions.vendorGetReloadHistorySuccess]:(_, {payload}) => payload.result,
    }),

    paymentSystemsCoinsAmount:createReducer({}, {
        [privateUserActions.paymentSystemsCoinsAmountSuccess]:(_, {payload}) => payload.result,
    }),

    getPaypalClientId:createReducer('', {
        [privateUserActions.getPaypalClientIdSuccess]:(_, {payload}) => payload.result,
    }),

    transactionBuyCoinsPaypal:createReducer(0, {
        [privateUserActions.transactionBuyCoinsPaypalSuccess]:(_, {payload}) => payload.err_code,
    }),

    vendorTransactionBuyCoinsPaypal:createReducer(0, {
        [privateUserActions.vendorTransactionBuyCoinsPaypalSuccess]:(_, {payload}) => payload.err_code,
    }),

    getBraintreeToken:createReducer('', {
        [privateUserActions.getBraintreeTokenSuccess]:(_, {payload}) => payload.result,
    }),

    getSquareupSettings:createReducer('', {
        [privateUserActions.getSquareupSettingsSuccess]:(_, {payload}) => payload.result,
    }),

    getStripToken:createReducer('', {
        [privateUserActions.getStripTokenSuccess]:(_, {payload}) => payload.result,
    }),

    getStripClientSecret:createReducer('', {
        [privateUserActions.getStripClientSecretSuccess]:(_, {payload}) => payload.result,
    }),

    transactionBuyCoinsBraintree:createReducer(0, {
        [privateUserActions.getBraintreeTokenSuccess]:() => 0,
        [privateUserActions.transactionBuyCoinsBraintreeSuccess]:(_, {payload}) => payload.err_code,
    }),

    vendorTransactionBuyCoinsBraintree:createReducer(0, {
        [privateUserActions.getBraintreeTokenSuccess]:() => 0,
        [privateUserActions.vendorTransactionBuyCoinsBraintreeSuccess]:(_, {payload}) => payload.err_code,
    }),

    transactionBuyCoinsSquareup:createReducer(0, {
        [privateUserActions.getSquareupSettingsSuccess]:() => 0,
        [privateUserActions.transactionBuyCoinsSquareupSuccess]:(_, {payload}) => payload.err_code,
    }),

    vendorTransactionBuyCoinsSquareup:createReducer(0, {
        [privateUserActions.getSquareupSettingsSuccess]:() => 0,
        [privateUserActions.vendorTransactionBuyCoinsSquareupSuccess]:(_, {payload}) => payload.err_code,
    }),

    checkoutCryptoOrder:createReducer(0, {
        [privateUserActions.getCartSuccess]:() => 0,
        [privateUserActions.proceedToCheckoutSuccess]:() => 0,
        [privateUserActions.checkoutCryptoOrderSuccess]:(_, {payload}) => payload.err_code,
    }),

    userTransactionPaypal:createReducer(0, {
        [privateUserActions.getCartSuccess]:() => 0,
        [privateUserActions.proceedToCheckoutSuccess]:() => 0,
        [privateUserActions.userTransactionPaypalSuccess]:(_, {payload}) => payload.err_code,
    }),

    userTransactionSquareup:createReducer(0, {
        [privateUserActions.getCartSuccess]:() => 0,
        [privateUserActions.proceedToCheckoutSuccess]:() => 0,
        [privateUserActions.userTransactionSquareupSuccess]:(_, {payload}) => payload.err_code,
    }),

    userTransactionBraintree:createReducer(0, {
        [privateUserActions.getCartSuccess]:() => 0,
        [privateUserActions.proceedToCheckoutSuccess]:() => 0,
        [privateUserActions.userTransactionBraintreeSuccess]:(_, {payload}) => payload.err_code,
    }),

    userTransactionStrip:createReducer(0, {
        [privateUserActions.getCartSuccess]:() => 0,
        [privateUserActions.proceedToCheckoutSuccess]:() => 0,
        [privateUserActions.userTransactionStripSuccess]:(_, {payload}) => payload.err_code,
    }),

    userTransactionCoins:createReducer(0, {
        [privateUserActions.getCartSuccess]:() => 0,
        [privateUserActions.proceedToCheckoutSuccess]:() => 0,
        [privateUserActions.userTransactionCoinsSuccess]:(_, {payload}) => payload.err_code,
    }),

    getProductDeliveryParameters:createReducer({}, {
        [privateUserActions.getProductDeliveryParametersSuccess]:(_, {payload}) => payload.result,
    }),

    getOrdersHistory:createReducer({}, {
        [privateUserActions.getOrdersHistorySuccess]:(_, {payload}) => payload.result,
    }),

    getShareSalesProfitList:createReducer([], {
        [privateUserActions.getShareSalesProfitListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getOrdersDelivery:createReducer([], {
        [privateUserActions.getOrdersDeliverySuccess]:(_, {payload}) => payload.result,
    }),

    getDeliveryTypes:createReducer([], {
        [privateUserActions.getDeliveryTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getDeliveryInfo:createReducer({}, {
        [privateUserActions.getDeliveryInfoSuccess]:(_, {payload}) => payload.result,
    }),

    getBalancesInfo:createReducer({}, {
        [privateUserActions.getBalancesInfoSuccess]:(_, {payload}) => payload.result,
    }),

    getPayoutMethodNames: createReducer([], {
        [privateUserActions.getPayoutMethodNamesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getPayoutMethodTypes: createReducer([], {
        [privateUserActions.getPayoutMethodTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getPayoutMethods: createReducer([], {
        [privateUserActions.getPayoutMethodsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getInfo: createReducer({}, {
        [privateUserActions.getInfoSuccess]:(_, {payload}) => payload.result,
    }),

    getTransactionsPayout: createReducer([], {
        [privateUserActions.getTransactionsPayoutSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getProductCategoriesByProductMarketplace: createReducer([], {
        [privateUserActions.getProductCategoriesByProductMarketplaceSuccess]:(_, {payload}) => payload.result,
    }),

    getProductCategoriesByProductMarketplaceBottom: createReducer([], {
        [privateUserActions.getProductCategoriesByProductMarketplaceBottomSuccess]:(_, {payload}) => payload.result,
    }),

    getProductCategoriesByProductMarketplaceBottomTwo: createReducer([], {
        [privateUserActions.getProductCategoriesByProductMarketplaceBottomTwoSuccess]:(_, {payload}) => payload.result,
    }),

    getProductPricingVariations: createReducer([], {
        [privateUserActions.getProductPricingVariationsSuccess]:(_, {payload}) => payload.result,
    }),

    showNftPublicationMovie: createReducer({}, {
        [privateUserActions.resetShowNftPublicationMovie]:(_, {payload}) => payload,
        [privateUserActions.updateProductInfoGeneralSuccess]:(_, {payload}) => payload.result?.SHOW_NFT_PUBLICATION_MOVIE,
    }),

    getAttributesByProduct: createReducer({}, {
        [privateUserActions.getAttributesByProductSuccess]:(_, {payload}) => payload.result,
    }),

    getProductCategories: createReducer([], {
        [privateUserActions.getProductCategoriesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getWidgetConvertationList: createReducer([], {
        [privateUserActions.getWidgetConvertationsListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    updateVisibleGroupName: createReducer({}, {
        [privateUserActions.updateVisibleGroupNameSuccess]:(_, {payload}) => payload.result,
    }),

    getVisibleGroupName: createReducer([], {
        [privateUserActions.getVisibleGroupNameSuccess]:(_, {payload}) => payload.result,
    }),

    getVisibleGroupNameErr: createReducer(-1, { 
        [privateUserActions.getVisibleGroupNameSuccess]:(_, {payload}) => payload.err_code,
        [privateUserActions.getAttributesByProductSuccess]:() => -1,
        [privateUserActions.getVisibleGroupNameRequest]:() => -1,
    }),

    getWidgetRecords: createReducer([], {
        [privateUserActions.getWidgetRecordsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    // getWidgetRecords : createReducer({}, {
    //     [privateUserActions.getWidgetRecordsSuccess]:(_, {payload}) => payload.result,
    // }),

    getWidgetsNoAttributesProduct: createReducer([], {
        [privateUserActions.getWidgetsNoAttributesProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getUserOrVendorProfile : createReducer({}, {
        [privateUserActions.getUserOrVendorProfileSuccess]:(_, {payload}) => payload.result,
    }),

    logoutUser : createReducer({}, {
        [privateUserActions.logoutUserSuccess]:(_, {payload}) => payload.result,
    }),

   
})
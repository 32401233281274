import { useEffect, useState } from "react";
import { connect } from "react-redux";

const PriceToken = ({
    currency,
    price,
    type="PRICE"
}) => {

    // const [currency, setCurrency] = useState('')

    const [balance, setBalance] = useState(0)
    const [balanceToken, setBalanceToken] = useState(0)

    // useState(()=>{
    //     console.log('priceToken', getCurrency);
    //     // if(currency2 === '') 
    //     //     setCurrency(getCurrency)
    //     // else
    //         setCurrency(getCurrency)

    // }, [getCurrency])

    useEffect(()=>{
        console.log(price);
        setBalance(Number(price.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === currency).map((v) => v[type]).join('')))
        setBalanceToken(Number(price.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map((v) => v[type]).join('')))
    }, [price, type, currency])

    return (
        <div style={{display:'inline-flex', alignItems:'center'}}>
            <i className="hiiroad mr-2"></i> 
            <b className="mr-1">{Number(balanceToken.toFixed(2))}</b>{' '} ({currency} {Number(balance.toFixed(2))}) 
        </div>
    ) 
}
const mapStateToPorps = (state) => ({
    currency:state.config.currency,
})

export default connect(mapStateToPorps)(PriceToken);

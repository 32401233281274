import { useEffect, useRef, useState } from "react";
import Children from 'react-children-utilities'

const Select = ({
    value, 
    onChange, 
    children, 
    style={}
}) => {

    const ref = useRef()
    const [show, setShow] = useState(false)
    const [select, setSelect] = useState([])
    const [view, setView] = useState('');

    useEffect(()=>{
        document.addEventListener('click', handle)
        return () => {
            document.removeEventListener('click', handle)
        }
    }, [])

    // useEffect(()=>{
    //     console.log('SELECT', {value});
    // }, [value])


    useEffect(()=>{
        // console.log('SELECT', {value});
        // console.log(children);

        if(!Boolean(children)) return;

        let arr = []
        children.forEach((v) => {
            // console.log(v);
            if(v?.props){
                const {value, children} = v.props
                arr.push({value, children})
            }
            if(v[0]?.props){
                const {value, children} = v[0].props
                arr.push({value, children})
            }
        });
        setSelect(arr)

        if(arr.length !== 0){
            const index = arr.findIndex(({value:getValue}) => getValue === value )
            if(index !== -1){
                const { children } = arr[index];
                setView( Children.onlyText(children) )
            }
        }else{
            setView('')
        }


    }, [children, value])


    const selectView = (value) => {
        const index = select.findIndex(({value:getValue}) => getValue === value )
        if(index !== -1){
            const { children } = select[index];
            setView( Children.onlyText(children) )
            onChange(value)
            setShow(false)
        }
    }

    const handle = ({target}) => {

        if(ref.current === target.closest('.Select')){
            return
        }

        setShow(false)
    }

    return (
        <div ref={ref} className="Select" style={style}>
            <div className="view" onClick={()=>setShow(!show)}>
                <div>{view}</div>
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            {show && 
                <ul>
                    {select.length !== 0 && select.map(({value:getValue, children}, k)=>{
                        return(<li key={k} className={value === getValue ? 'active' : null} onClick={()=>selectView(getValue)}>{children}</li>)
                    })}

                    {select.length === 0 && <li onClick={()=>setShow(false)}></li>}
                </ul>
            }
        </div>
    ) 
}

export default Select
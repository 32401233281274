import axios from 'axios';
import {PRIVATE_USER} from './../config/Url'
import { configActions } from "./config";
import update from 'immutability-helper';

let globalResponse = {};
let flag = 0;

export const instance = axios.create({
    baseURL: PRIVATE_USER,
    headers: { "Access-Control-Allow-Origin": "*", }
});

export const privateRequest = (state, dispatch, {disableErrCode=true}={}) => {
    console.log("BEGIN privateRequest", disableErrCode);
    flag = 0;
    let ii = 0;
    if(Boolean(state().config.token)){

        dispatch(configActions.configHttpRequest())  

        // instance.headers.common.Authorization = `Bearer ${state().auth.token}`;
// Invalid access token: fb67798c-8397-44f1-b0a6-9bb1fa9cd2a2

       
        instance.interceptors.request.use(config => {
               
            if(state().config.token !== null)
                config.headers.Authorization = `Bearer ${state().config.token.access_token}`;
                // config.headers.Authorization = `Bearer fb67798c-8397-44f1-b0a6-9bb1fa9cd2a2`;
            else
                config.headers.Authorization = ``;
            return config;

        }, error => {
            dispatch(configActions.configHttpError(error))
            return Promise.reject(error);
        });
        
        instance.interceptors.response.use(response => {

            const {status} = response
            const originalRequest = response.config;
            if(status === 401){
                dispatch(configActions.status401())
                // window.location.href = '/'
                // return instance(originalRequest);
            }
            dispatch(configActions.configHttpSuccess(response.data))
            return response;
        }, error => {
            const status = error.response.status
            const originalRequest = error.config;
            // console.log('public error', error.response.status);
            if(status === 401){
                dispatch(configActions.status401())
                // window.location.href = '/'
                // return instance(originalRequest);
            }

            dispatch(configActions.configHttpError(error))
            return Promise.reject(error);
        });
    }
}

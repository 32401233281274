import Sidebar from "../Sidebar";
import {useEffect, useRef} from "react";
import {useState} from "react";
import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'

import upload from '../../modules/upload'
import Button from "../../components/Button/Button";
import { configSelectors } from "../../redux/config";
import { privateUserOperations } from "../../redux/privateUser";

import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import Popup from "../../components/Popup";
import InputMask from 'react-input-mask';

const INITIAL_STATE_USER = {
    EMAIL: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    PHOTO:"",
    PROFILE_BANNER:"",
    VENDOR_NAME:'',
    DOMAIN,
    CURRENCY:"CAD",					//- валюта по умолчанию
    COUNTRY_CODE:"CA",			//	- страна
    IS_VENDOR:-1,					//	- (0 - покупатель, 1 - продавец)
    VENDOR_LINK:"",				//	- линк продавца (если нет то "")
    VENDOR_ABOUT:"",              //- о продавце (если нет то "")
    PHONE_COUNTRY_CODE:"",
    PHONE:""
}

const INITIAL_STATE_USER_VENDOR = {
    VENDOR_NAME:"",	//	- имя и фамилия
    VENDOR_EMAIL:"",//	- email
    VENDOR_IMAGE:"",		//	- иконка
    VENDOR_COUNTRY_CODE:"CA",//	- страна
    VENDOR_TYPE:0, 			//- тип (0 - частное литцо, 1 - бизнес)
    CURRENCY:'CAD',
    DOMAIN	
}

const INITIAL_STATE_PASS = {
    CURRENT_PASSWORD:"",
    NEW_PASSWORD:"",
    CONFIRM_NEW_PASSWORD:""
}

// const Initial_Account = {
//     Firstname: "",
//     Lastname:"",
//     Email:"",
//     Displayname:"",
// }
const MyAccount = ({
    errCode,
    initWebSait,
    onGetUserProfile,
    getUserProfile,
    onUpdateUserProfile,
    onUpdateUserPassword,
    getIsAuthenticated,
    isVendor,
    onUpdateVendorProfile,
    onGetVendorProfile,
    getVendorProfile,
    onUpdateVendorPassword,
    onUpdateUserOrVendorProfile,
    onGetUserOrVendorProfile,
    getUserOrVendorProfile
}) => {
    const [rel, setRel] = useState(0)
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator())
    const simpleValidator1 = useRef(new SimpleReactValidator())
    const simpleValidator2 = useRef(new SimpleReactValidator())

    const [showSuccess, setShowSuccess] = useState(false);

    const [showVendor, setShowVendor] = useState(false)
    const [popupAlert2, setPopupAlert2] = useState(false)
    const [popupAvatar, setPopupAvatar] = useState(false)
    const [img, setImg] = useState('')

    const [popupAvatar2, setPopupAvatar2] = useState(false)
    const [img2, setImg2] = useState('')

    const [flag, setFlag] = useState(0)


    // const saveAccount = () => {
    //     const formValid = simpleValidator.current.allValid()
    //     if (!formValid) {
    //         simpleValidator.current.showMessages()
    //         forceUpdate(1)
    //     }
    // }
    const [accountVendor, setAccountVendor] = useState(INITIAL_STATE_USER_VENDOR)
    const [account, setAccount] = useState(INITIAL_STATE_USER)
    const [pass, setPass] = useState(INITIAL_STATE_PASS)

    const refImgCrop = useRef();
    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);

    const handleAccount = ({target: {name, value}}) => {
        setAccount(state => ({...state, [name]: value}))
    }
    const handleAccount2 = ({target: {name, value}}) => {
        setAccountVendor(state => ({...state, [name]: value}))
    }
    const handlePass = ({target: {name, value}}) => {
        setPass(state => ({...state, [name]: value}))
    }
    

    useEffect(()=>{
        if(document.querySelector('.reactEasyCrop_CropArea'))
            document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'

    }, [])

    useEffect(()=>{
        
        const time = setInterval(()=>{
            const subject = document.querySelector('.ant-modal-body');
            const isAMB = document.querySelector('.isAMB')
            if(flag === 1 && Boolean(subject) && !Boolean(isAMB)){
                subject.insertAdjacentHTML('afterbegin', '<p class="isAMB">To ensure that your full w<span style={color:"#4572C2"}>i</span>dth <span style={color:"#4572C2"}>i</span>mages look good we are recommended s<span style={color:"#4572C2"}>i</span>ze <span style={color:"#4572C2"}>i</span>s not more then 1600x1600px or any s<span style={color:"#4572C2"}>i</span>ze w<span style={color:"#4572C2"}>i</span>th 1:1 rat<span style={color:"#4572C2"}>i</span>o.  <span style={color:"#4572C2"}>i</span>mages w<span style={color:"#4572C2"}>i</span>th a d<span style={color:"#4572C2"}>i</span>fferent s<span style={color:"#4572C2"}>i</span>ze can be res<span style={color:"#4572C2"}>i</span>zed after upload and cropped manually.</p>');
                setFlag(0)
            }
            }, 500)

        return ()=>{
            clearInterval(time)
        }
    }, [flag])

    

    

    // useEffect(()=>{
    //     console.log({isVendor});
    //     if(getIsAuthenticated && isVendor === 0)
    //         onGetUserProfile({DOMAIN})
        
    //     else if(getIsAuthenticated && isVendor === 1)
    //         onGetVendorProfile({DOMAIN})

    // }, [getIsAuthenticated, isVendor])

    useEffect(()=>{
        if(getIsAuthenticated){
            onGetUserOrVendorProfile({DOMAIN}) 
        }
    }, [getIsAuthenticated])

    useEffect(()=>{
        if(getIsAuthenticated && errCode === 1){
            onGetUserOrVendorProfile({DOMAIN}) 
        }

        // if(errCode === 1)
        //     if(getIsAuthenticated && isVendor === 0)
        //         onGetUserProfile({DOMAIN})
            
        //     else if(getIsAuthenticated && isVendor === 1)
        //         onGetVendorProfile({DOMAIN})
    }, [getIsAuthenticated, errCode])



    useEffect(()=>{
        if(Object.keys(getUserOrVendorProfile).length !== 0){
            const {PHOTO, PROFILE_BANNER} = getUserOrVendorProfile
            setAccount(getUserOrVendorProfile)

            if(PHOTO !== '')
                setFileList([
                    {
                        uid: '-1',
                        url: `${WEB_PICTURES_FILE_RESIZER}${PHOTO}/240`,
                    },
                ])

            if(PROFILE_BANNER !== '')
                setFileList2([
                    {
                        uid: '-1',
                        url: `${WEB_PICTURES_FILE_RESIZER}${PROFILE_BANNER}/240`,
                    },
                ])
        }
    }, [getUserOrVendorProfile])

    useEffect(()=>{
        if(errCode === 1){
            setPass(INITIAL_STATE_PASS)
        }
    }, [errCode])

    const saveChange = () => {
        const formValid = simpleValidator.current.allValid()

        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)

            return
        }
        if(clearPhone(account?.PHONE || '').length > 10) return
        setRel(0)
        let isvendor = showVendor ? 1 : 0
        if(isVendor === 1) isvendor = isVendor
        // onUpdateUserProfile({DOMAIN, ...account})
        onUpdateUserOrVendorProfile({DOMAIN, ...account, IS_VENDOR:isvendor})
    }

    const saveChange2 = () => {
        const formValid = simpleValidator1.current.allValid()

        if (!formValid) {
            simpleValidator1.current.showMessages()
            forceUpdate(1)

            return
        }
        setRel(0)
        onUpdateVendorProfile({DOMAIN, ...accountVendor})
    }

    const savePassword = () =>{
        const formValid = simpleValidator2.current.allValid()

        if (!formValid) {
            simpleValidator2.current.showMessages()
            forceUpdate(1)

            return
        }
        setRel(1)
        if(isVendor === 0)
            onUpdateUserPassword({
                DOMAIN,
                CURRENT_PASSWORD:pass.CURRENT_PASSWORD,
                NEW_PASSWORD:pass.NEW_PASSWORD
            })
        if(isVendor === 1)
            onUpdateVendorPassword({
                DOMAIN,
                CURRENT_PASSWORD:pass.CURRENT_PASSWORD,
                NEW_PASSWORD:pass.NEW_PASSWORD
            })
    }

    // const handleChangeFile = (e) =>{
    //     const files = e.target.files

    //     // if(document.querySelector('.reactEasyCrop_CropArea'))
    //     //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'

    //     // setTimeout(()=>{
    //     //     if(document.querySelector('.reactEasyCrop_CropArea'))
    //     //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
    //     // }, 10)
    //     // setTimeout(()=>{
    //     //     if(document.querySelector('.reactEasyCrop_CropArea'))
    //     //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
    //     // }, 50)
    //     // setTimeout(()=>{
    //     //     if(document.querySelector('.reactEasyCrop_CropArea'))
    //     //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
    //     // }, 100)
    //     // setTimeout(()=>{
    //     //     if(document.querySelector('.reactEasyCrop_CropArea'))
    //     //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
    //     // }, 1000)

    //     upload(files, ({uuid})=>{
    //         setAccount(state => ({...state, PHOTO: uuid}))
    //     })
    // }
    const onUploadImage = async (options, flag) => {
        const { onSuccess, onError, file, onProgress } = options;

        let list = new DataTransfer();
        list.items.add(file);

        upload(list.files, ({uuid})=>{
            upload(list.files, ({uuid})=>{
                setFileList([
                    {
                        uid: '-1',
                        url: `${WEB_PICTURES_FILE_RESIZER}${uuid}/240`,
                    },
                ])
                setAccount(state => ({...state, PHOTO: uuid}))
            })
        })
        
    }
    const onChange = ({ fileList: newFileList }) => {
        console.log(newFileList);
    };

    const onRemove = (file) => {
        console.log(file);
        const {uid, url} = file

        setAccount(state => ({...state, PHOTO: ''}))
        setFileList([])
    }

    const onPreview = async (file) => {

        console.log({file});

        let src = file.url;

        if(Boolean(src)){
            setPopupAvatar(true)
            setImg(src)
        }

        // if (!src) {
        //   src = await new Promise((resolve) => {
        //     const reader = new FileReader();
        //     reader.readAsDataURL(file.originFileObj);
        //     reader.onload = () => resolve(reader.result);
        //   });
        // }
        // const image = new Image();
        // image.src = src;
        // const imgWindow = window.open(src);
    
        // if (imgWindow) {
        //   imgWindow.document.write(image.outerHTML);
        // } else {
        //   window.location.href = src;
        // }
    };

    const clickImgCrop = () => {
        // console.log(refImgCrop);
        // window.querySelector('.reactEasyCrop_CropArea')?.style.borderRadius = '500px'
        // if(document.querySelector('.reactEasyCrop_CropArea'))
        //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'

        // setTimeout(()=>{
        //     if(document.querySelector('.reactEasyCrop_CropArea'))
        //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
        // }, 10)
        // setTimeout(()=>{
        //     if(document.querySelector('.reactEasyCrop_CropArea'))
        //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
        // }, 50)
        // setTimeout(()=>{
        //     if(document.querySelector('.reactEasyCrop_CropArea'))
        //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
        // }, 100)
        // setTimeout(()=>{
        //     if(document.querySelector('.reactEasyCrop_CropArea'))
        //     document.querySelector('.reactEasyCrop_CropArea').style.borderRadius = '500px'
        // }, 500)
    }

    const onBeforeCrop = file => {
        console.log(file);

        setFlag(1)

        if(file.type === ''){
            setPopupAlert2(true)
            return
        }else if(  (file.type).match(/image/gi).length === 0 ){
            setPopupAlert2(true)
            return
        }

        return file
    }

    const clearPhone = phone => {
        return phone.replace( /[^0-9]/gi, '')
    }

    const onUploadImage2 = async (options, flag) => {
        const { onSuccess, onError, file, onProgress } = options;

        let list = new DataTransfer();
        list.items.add(file);

        upload(list.files, ({uuid})=>{
            upload(list.files, ({uuid})=>{
                setFileList2([
                    {
                        uid: '-1',
                        url: `${WEB_PICTURES_FILE_RESIZER}${uuid}/240`,
                    },
                ])
                setAccount(state => ({...state, PROFILE_BANNER: uuid}))
            })
        })
        
    }
    const onChange2 = ({ fileList: newFileList }) => {
        
    };

    const onRemove2 = (file) => {
        console.log(file);
        const {uid, url} = file

        setAccount(state => ({...state, PROFILE_BANNER: ''}))
        setFileList2([])
    }

    const onPreview2 = async (file) => {

        console.log({file});

        let src = file.url;

        if(Boolean(src)){
            setPopupAvatar2(true)
            setImg2(src)
        }

    };

    const onBeforeCrop2 = file => {
        console.log(file);


        if(file.type === ''){
            setPopupAlert2(true)
            return
        }else if(  (file.type).match(/image/gi).length === 0 ){
            setPopupAlert2(true)
            return
        }

        return file
    }


    return (<>
        <div className="container-fluid">

            <div className="p-2 px-3">
            {isVendor === 0 && <h1>Account details</h1>}
            {isVendor === 1 && <h1>Vendor account details</h1>}
            </div>

            <div className="row mobile-view">
                <Sidebar/>
                <div className="col-md-9 form-parent">
                    {/* {isVendor === 0 && */}
                    {/* <div className="accnt-details-img mb-4" style={{position:'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {account.PHOTO === '' && <span >Uploaded <span style={{color:'#4572C2'}}>i</span>mage</span>}
                        {account.PHOTO !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${account.PHOTO}/500`} style={{width:'100%', height:'100%', borderRadius:'100%'}} />}
                        <input type="file" className="upload" onChange={handleChangeFile} />
                    </div> */}
                    {/* {account.IS_VENDOR === 1 && */}
                    <div className="d-flex" style={{alignItems:'center', gap:20, flexWrap:'wrap'}}>
                        <div>
                            prof<span style={{color:'#4572C2'}}>i</span>le <span style={{color:'#4572C2'}}>i</span>mage
                            <div className="d-flex" style={{alignItems:'center', gap:20}}>
                                <div className="imgCrop1 profilePhoto" style={{width:170, padding:0, maxWidth:170}}>
                                    <ImgCrop 
                                        shape="round"  
                                        beforeCrop={onBeforeCrop} 
                                        modalClassName="imgCropProfile"
                                    >
                                        <Upload
                                            ref={refImgCrop}
                                            customRequest={state => onUploadImage(state)}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={state => onChange(state)}
                                            onPreview={onPreview}
                                            onRemove={onRemove}
                                            className="test-radius"
                                        >
                                            {fileList.length < 1 && 
                                                <label className="productFileUpload" onClick={()=>clickImgCrop()}>
                                                    <p style={{fontSize: 18}}>Uploaded <span style={{color:'#4572C2'}}>i</span>mage</p>
                                                </label>
                                            }
                                        </Upload>
                                    </ImgCrop>
                                </div>               
                            </div>
                        </div>
                        <div>
                            prof<span style={{color:'#4572C2'}}>i</span>le banner
                            <div className="d-flex" style={{alignItems:'center', gap:20}}>
                                <div className="imgCropBanner profilePhoto" style={{width:529, padding:0, maxWidth:529}}>
                                    <ImgCrop 
                                        aspect={20 / 6}  
                                        beforeCrop={onBeforeCrop2} 
                                    >
                                        <Upload
                                            ref={refImgCrop}
                                            customRequest={state => onUploadImage2(state)}
                                            listType="picture-card"
                                            fileList={fileList2}
                                            onChange={state => onChange2(state)}
                                            onPreview={onPreview2}
                                            onRemove={onRemove2}
                                            className="test-radius"
                                        >
                                            {fileList2.length < 1 && 
                                                <label className="productFileUpload" onClick={()=>clickImgCrop()}>
                                                    <p style={{fontSize: 18}}>Uploaded <span style={{color:'#4572C2'}}>i</span>mage</p>
                                                </label>
                                            }
                                        </Upload>
                                    </ImgCrop>
                                </div>               
                            </div>
                        </div>
                    </div>
                    
                    {/* } */}
     
                    <div className="pb-4">
                        {/* {isVendor === 0 && <> */}
                            {(showVendor || account.IS_VENDOR === 1) && 
                            <div className="d-flex display-block pb-3">
                                <div className="pr-3 w-50 mobile-name">
                                    <span>Vendor name *</span>
                                    <input
                                        type="text"
                                        onChange={handleAccount}
                                        placeholder="Vendor Name"
                                        className="w-100 form-control px-4 checkout-input-small"
                                        name="VENDOR_NAME"
                                        value={account.VENDOR_NAME}
                                        onClick={()=>simpleValidator.current.showMessageFor('Vendorname')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Vendorname', account.VENDOR_NAME, 'required')}
                                    </div>
                                    <br/>
                                    <span>about vendor *</span>
                                    <input
                                        type="text"
                                        onChange={handleAccount}
                                        placeholder="about vendor"
                                        className="w-100 form-control px-4 checkout-input-small"
                                        name="VENDOR_ABOUT"
                                        value={account.VENDOR_ABOUT}
                                        onClick={()=>simpleValidator.current.showMessageFor('About_vendor')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('About_vendor', account.VENDOR_ABOUT, 'required')}
                                    </div>
                                </div>
                                {account.IS_VENDOR === 0 && <div className=" w-50 mobile-name" style={{paddingTop: 19}}>
                                    <Button className="button" onClick={()=>setShowVendor(false)} >cancel</Button>
                                </div>}
                            </div>}

                        
                            <div className="d-flex display-block pb-3">
                                <div className="pr-3 w-50 mobile-name">
                                    <span>first name *</span>
                                    <input
                                        type="text"
                                        onChange={handleAccount}
                                        placeholder="First Name"
                                        className="w-100 form-control px-4 checkout-input-small"
                                        name="FIRST_NAME"
                                        value={account.FIRST_NAME}
                                        onClick={()=>simpleValidator.current.showMessageFor('Firstname')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Firstname', account.FIRST_NAME, 'required|alpha')}
                                    </div>
                                </div>
                                <div className=" w-50 mobile-name">
                                    <span>last name *</span>
                                    <input
                                        type="text"
                                        onChange={handleAccount}
                                        placeholder="Last Name"
                                        className="w-100 form-control px-4 checkout-input-small"
                                        name="LAST_NAME"
                                        value={account.LAST_NAME}
                                        onClick={()=>simpleValidator.current.showMessageFor('Lastname')} />
                                    <div className="text-danger">
                                        {simpleValidator.current.message('Lastname', account.LAST_NAME, 'required|alpha')}
                                    </div>
                                </div>
                            </div>

                            <div className="py-2">
                                <span>Ema<span style={{color:'#4572C2'}}>i</span>l address *</span><br></br>

                                <input
                                    type="email"
                                    className="form-control px-4 checkout-input-large"
                                    name="EMAIL"
                                    placeholder="Enter your email address"
                                    onChange={handleAccount}
                                    value={account.EMAIL}
                                    onBlur={() => simpleValidator.current.showMessageFor('Email')}/>
                                <div className="text-danger">
                                    {simpleValidator.current.message('Email', account.EMAIL, 'required|email')}
                                </div>
                            </div>

                            <div className="py-2">
                                    <span>phone *</span><br></br>
                                    <div className="d-flex">
                                        <select className=" md-form pl-2 mr-2 form-control selecting-custom-styling" 
                                            name="PHONE_COUNTRY_CODE"
                                            onChange={handleAccount}
                                            value={account.PHONE_COUNTRY_CODE} 
                                            style={{width:'16%'}} 
                                        >
                                            <option value="+1" >+1</option>
                                            <option value="+91">+91</option>
                                            <option value="+92">+92</option>
                                        </select>
                                        <div style={{width: '100%'}}>
                                            <InputMask
                                                mask="(999)999-9999999999999999" 
                                                maskChar={null}
                                                style={{width: '463px !important'}}
                                                type="tel"
                                                onChange={handleAccount}
                                                className="adjust-width input-rounded-fields form-control  float-right"
                                                name="PHONE"
                                                value={account.PHONE}
                                                autoComplete="nope"
                                                onBlur={()=>simpleValidator.current.showMessageFor('required|phone_number|min:10')}
                                            />
                                        </div>
                                    </div>

                                    <div className="text-danger">
                                        {simpleValidator.current.message('phone_number', account.PHONE, 'required')}
                                    </div> 
                                    {clearPhone(account?.PHONE || '').length > 10 && <div className="text-danger">
                                        the phone number may not be greater than 10 digits 
                                    </div> }
                               
                                </div>

                            <div className="py-2">
                                <label>Deafult currency</label><br/>
                                <div className="d-flex">
                                    <select className=" md-form pl-2 form-control  selecting-custom-styling" name="CURRENCY" value={account.CURRENCY} onChange={handleAccount} >
                                

                                        {
                                            initWebSait?.CURRENCY_LIST?.map(({rate_name, rate_short_name}, k)=>{
                                                return(<option key={k} value={rate_short_name}>{rate_name}</option>)
                                            })
                                        }
                                    </select>
                                
                                </div>
                            </div>
                            <div className="py-3">
                                <label>Country</label><br/>
                                <div className="d-flex">
                                    <select className=" md-form pl-2 form-control  selecting-custom-styling" name="COUNTRY_CODE" value={account.COUNTRY_CODE} onChange={handleAccount} >
                                        <option value="CA">Canada </option>
                                        <option value="US">USA</option>
                                    </select>
                                
                                </div>
                            
                            </div>
                            <div className="py-2" style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button className="button" onClick={()=>saveChange()}>save changes</Button>
                                {!showVendor && account.IS_VENDOR === 0 && <Button className="button" onClick={()=>setShowVendor(true)} style={{minWidth: 250}}>become a vendor</Button>}

                            </div>
                        {/* </>}
                        {isVendor === 1 && <> */}
                            {/* <div className="py-2">
                                <span>Vendor name</span>
                                <input
                                    type="text"
                                    onChange={handleAccount2}
                                    placeholder="Vendor name"
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="VENDOR_NAME"
                                    value={accountVendor.VENDOR_NAME}
                                    onClick={()=>simpleValidator1.current.showMessageFor('Vendor_name')} />
                                <div className="text-danger">
                                    {simpleValidator1.current.message('Vendor_name', accountVendor.VENDOR_NAME, 'required')}
                                </div>
                            </div>
                            <div className="d-flex display-block  py-2">
                                <div className="control pr-3 w-50  pb-3 has-icons-left">
                                    <label>Country</label><br/>
                                    <div className="d-flex">
                                        <select className=" md-form pl-2 form-control  selecting-custom-styling" name="VENDOR_COUNTRY_CODE" value={accountVendor.VENDOR_COUNTRY_CODE} onChange={handleAccount2} >
                                            <option value="CA">Canada </option>
                                            <option value="US">USA</option>
                                        </select>
                                    
                                    </div>
                               
                                </div>
                                <div className="control w-50  pb-3 has-icons-left">
                                    <label>Vendor type</label>
                                    <div className="mt-2" style={{display:'flex'}}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="VENDOR_TYPE" id="exampleRadios1" checked={accountVendor.VENDOR_TYPE === 0} value={0} onChange={ ({target:{value}})=> setAccountVendor(state => ({...state, VENDOR_TYPE: Number(value)}))} />
                                            <label className="form-check-label" htmlFor="exampleRadios1">Pr<span style={{color:'#4572C2'}}>i</span>vate seller </label>
                                        </div>
                                        <div className="form-check" style={{marginLeft:20}}>
                                            <input className="form-check-input" type="radio" name="VENDOR_TYPE" id="exampleRadios2" checked={accountVendor.VENDOR_TYPE === 1} value={1} onChange={ ({target:{value}})=> setAccountVendor(state => ({...state, VENDOR_TYPE: Number(value)}))} />
                                            <label className="form-check-label" htmlFor="exampleRadios2"> Bus<span style={{color:'#4572C2'}}>i</span>ness</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="control pr-3 w-50  pb-3 has-icons-left">
                                    <label>Deafult currency</label><br/>
                                    <div className="d-flex">
                                        <select className=" md-form pl-2 form-control  selecting-custom-styling" name="CURRENCY" value={accountVendor.CURRENCY} onChange={handleAccount2} >
                                 

                                            {
                                                initWebSait?.CURRENCY_LIST?.map(({rate_name, rate_short_name}, k)=>{
                                                    return(<option key={k} value={rate_short_name}>{rate_name}</option>)
                                                })
                                            }
                                        </select>
                                    
                                    </div>
                                </div>
                            

                            
                            </div>

                            <div className="py-2">
                                <span>Ema<span style={{color:'#4572C2'}}>i</span>l address *</span><br></br>

                                <input
                                    type="email"
                                    className="form-control px-4 checkout-input-large"
                                    name="VENDOR_EMAIL"
                                    placeholder="Enter your email address"
                                    onChange={handleAccount2}
                                    value={accountVendor.VENDOR_EMAIL}
                                    onBlur={() => simpleValidator1.current.showMessageFor('Email')}/>
                                <div className="text-danger">
                                    {simpleValidator1.current.message('Email', accountVendor.VENDOR_EMAIL, 'required|email')}
                                </div>
                            </div>
                            <div className="py-2">
                                <span>N<span style={{color:'#4572C2'}}>i</span>ckname</span>
                                <input
                                    type="text"
                                    onChange={handleAccount2}
                                    placeholder="Nickname"
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="VENDOR_LINK"
                                    value={accountVendor.VENDOR_LINK}
                                    onClick={()=>simpleValidator1.current.showMessageFor('Nickname')} />
                                <div className="text-danger">
                                    {simpleValidator1.current.message('Nickname', accountVendor.VENDOR_LINK, 'required')}
                                </div>
                            </div>
                            <div className="py-2">
                                <span>About me</span>
                                <textarea
                                    type="text"
                                    onChange={handleAccount2}
                                    placeholder="About me"
                                    className="w-100 form-control px-4 checkout-input-small"
                                    name="VENDOR_ABOUT"
                                    value={accountVendor.VENDOR_ABOUT}
                                    onClick={()=>simpleValidator1.current.showMessageFor('About_me')} />
                                <div className="text-danger">
                                    {simpleValidator1.current.message('About_me', accountVendor.VENDOR_ABOUT, 'required')}
                                </div>
                            </div>
                            <div className="py-2">
                                <Button className="button" onClick={()=>saveChange2()}>save changes</Button>

                            </div>
                        </>} */}
                        <div className="border-bottom">
                            <h4 className="py-2">Password change</h4>
                            {showSuccess && <div style={{color:"green"}}></div>}
                        </div>
                        <div className="pt-4">
                            <div className="py-2">
                                <span>Current password (leave blank for no change)</span><br></br>
                                <input type="password" name="CURRENT_PASSWORD"  style={{height:0, opacity:0}} />
                                <input className="form-control px-4 checkout-input-large" 
                                    type="password"
                                    name="CURRENT_PASSWORD"
                                    value={pass.CURRENT_PASSWORD}
                                    onChange={handlePass}
                                    autoComplete="off"
                                    onClick={()=>simpleValidator2.current.showMessageFor('current_password')}
                                />
                                <div className="text-danger">
                                    {simpleValidator2.current.message('current_password', pass.CURRENT_PASSWORD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="py-2">
                                <span>New password (leave blank for no change)</span><br></br>
                                <input className="form-control px-4 checkout-input-large"
                                    type="password"
                                    name="NEW_PASSWORD"
                                    value={pass.NEW_PASSWORD}
                                    onChange={handlePass}
                                    autoComplete="off"
                                    onClick={()=>simpleValidator2.current.showMessageFor('new_password')}
                                />
                                <div className="text-danger">
                                    {simpleValidator2.current.message('new_password', pass.NEW_PASSWORD, 'required|alpha_num')}
                                </div>
                            </div>
                            <div className="py-2">
                                <span>Conf<span style={{color:'#4572C2'}}>i</span>rm new password</span><br></br>
                                <input className="form-control px-4 checkout-input-large"
                                    type="password"
                                    name="CONFIRM_NEW_PASSWORD"
                                    value={pass.CONFIRM_NEW_PASSWORD}
                                    onChange={handlePass}
                                    autoComplete="off"
                                    onClick={()=>simpleValidator2.current.showMessageFor('confirm_password')}
                                />
                                <div className="text-danger">
                                {simpleValidator2.current.message('confirm_password', pass.CONFIRM_NEW_PASSWORD, `required|in:${pass.NEW_PASSWORD}`, {messages: {in: 'Passwords need to match!'}})}
                                </div>
                            </div>

                        </div>
                        <div className="py-2">
                            <Button className="button" onClick={()=>{savePassword()}}>save password</Button>
                        </div>
                        {rel === 1 && [-1, 1, 0, 18].indexOf(errCode) === -1 && <div style={{color:'red'}}>Error code: {errCode}</div>}
                        {rel === 1 && errCode === 18 && <div style={{color:'red'}} >Incorrect Current password</div>}
                </div>



            </div>
        </div>

        </div>
        {popupAlert2 && <Popup classNamePopup="alert" closePopup={()=>setPopupAlert2(false)}>
            <h3 className="text-center my-5">
                You can upload only jpg, jpeg, png <span style={{color:'#4572C2'}}>i</span>mages
            </h3>

            <div className="text-center">
                <button className="button-16" onClick={()=>setPopupAlert2(false)}>close</button>
            </div>
        </Popup>}

        {popupAvatar && <Popup>
                <div className="text-center">
                    <img src={img} style={{width:500, height: 500, margin: '0 auto 12px', borderRadius: '100%'}} />
                </div>
                <div className="text-center">
                    <button className="button-16" onClick={()=>setPopupAvatar(false)}>close</button>
                </div>
            </Popup>}

            {popupAvatar2 && <Popup width="initial">
                <div className="text-center">
                    <img src={img2} style={{width:'70vw', height: 370, margin: '0 auto 12px',}} />
                </div>
                <div className="text-center">
                    <button className="button-16" onClick={()=>setPopupAvatar2(false)}>close</button>
                </div>
            </Popup>}
    </>)
}
const mapStateToProps = (state) => ({
    lang: state.config.lang,
    errCode: state.config.errCode,
    initWebSait: state.publicUser.initWebSait,
    getUserProfile: state.privateUser.getUserProfile,
    getVendorProfile: state.privateUser.getVendorProfile,
    getUserOrVendorProfile: state.privateUser.getUserOrVendorProfile,
    getIsAuthenticated:configSelectors.getIsAuthenticated(state),
    isVendor:state.config.resultUser.IS_VENDOR || 0
})

const mapDispatchToProps = {
    onGetUserProfile: privateUserOperations.getUserProfile,
    onUpdateUserProfile: privateUserOperations.updateUserProfile,
    onUpdateUserPassword: privateUserOperations.updateUserPassword,
    onUpdateVendorProfile: privateUserOperations.updateVendorProfile,
    onGetVendorProfile: privateUserOperations.getVendorProfile,
    onUpdateVendorPassword: privateUserOperations.updateVendorPassword,
    onUpdateUserOrVendorProfile: privateUserOperations.updateUserOrVendorProfile,
    onGetUserOrVendorProfile: privateUserOperations.getUserOrVendorProfile,
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);

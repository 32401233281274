import { useCallback, useEffect, useState } from "react"
import { Col, Container, Dropdown, DropdownButton, FormControl, InputGroup, Nav, Navbar, NavDropdown, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { Link, NavLink, useHistory, useLocation } from "react-router-dom"
import { DOMAIN, WEB_PICTURES_FILE, WEB_PICTURES_FILE_RESIZER } from "../../config/Url"
import { configActions, configSelectors } from "../../redux/config"
import { publicUserOperations } from "../../redux/publicUser"
import Select from "../Select/Select"
import Favicon from 'react-favicon'
import cad from './../../asstets/img/canada-flag.png'

import discord from './../../asstets/svg/discord-1.svg'
import reddit from './../../asstets/svg/reddit-1.svg'
import slack from './../../asstets/svg/slack.svg'
import twitter from './../../asstets/svg/tiwer.svg'


import jsonCurrency from './../../json/currency.json'
import jsonLang from './../../json/lang.json'
import Popup from "../Popup"
import { Base64Decode, replaceII } from "../../modules/helper"

import Logo from './../../asstets/img/header/Logo.png'
import mLogo from './../../asstets/img/header/Logo-m.png'

const infoContacts = ['FACEBOOK', 'INSTAGRAM', 'TIKTOK', 'TWITTER']

const INITIAL_STATE_CATEGORY = {id:0, name:'All Categories'}

const Header = ({
    currency,
    onUpdateCurrency,
    onShowLoginRegister,
    lang,
    onUpdateLang,
    initWebSait,
    isAuthenticated,
    user,
    isVendor,
    onCheckToken
}) => {

    const location = useLocation()
    const [showSerch, setShowSerch] = useState(true)
    const [popupView, setPopupView] = useState(false);
    const [selectCategory, setSelectCategory] = useState(INITIAL_STATE_CATEGORY)

    const [search, setSearch] = useState('')
 
    const history = useHistory()

    const [contacts, setContacts] = useState({
        EMAIL_SALES: "",
        EMAIL_SUPPORT: "",
        ID_WEB_SITE: 0,
        LOGO_FACEBOOK: "",
        LOGO_INSTAGRAM: "",
        LOGO_TIKTOK: "",
        LOGO_TWITTER: "",
        PHONE_SALES: "",
        PHONE_SUPPORT: "",
        STATUS_FACEBOOK: 0,
        STATUS_INSTAGRAM: 0,
        STATUS_TIKTOK: 0,
        STATUS_TWITTER: 0,
        URL_FACEBOOK: "",
        URL_INSTAGRAM: "",
        URL_TIKTOK: "",
        URL_TWITTER: ""
    })

    const [countCart, setCountCart] = useState(0)
    const [countWish, setCountWish] = useState(0)

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleSubmit = useCallback( () => {
        history.push({
            pathname: '/search',
            search: `?query=${search}&category_id=${selectCategory.id}`,
        });
    }, [search, selectCategory])

    useEffect(()=>{
        console.log({initWebSait});
        if(Object.keys(initWebSait).length !== 0){
            const { MAIN_INFO, CONTACTS_INFO, PRODUCT_GROUPS } = initWebSait

            const {TITLE_WEB_PAGE, CURRENCY_LIST_STATUS, DEFAULT_CURRENCY_SHORT_NAME} = MAIN_INFO

            document.title = TITLE_WEB_PAGE
            setContacts(CONTACTS_INFO)

            
            if(Boolean(initWebSait.USER_CART)){
                const {PRODUCTS_QTY} = initWebSait.USER_CART
                setCountCart(PRODUCTS_QTY)
            }

            if(Boolean(initWebSait.WISH_LIST)){
                setCountWish(initWebSait.WISH_LIST.length)
            }

            // if(Boolean(initWebSait.USER_PROPERTIES) ){
            //     const { CURRENCY } = initWebSait.USER_PROPERTIES

            //     onUpdateCurrency(CURRENCY)
            // }
        }
    }, [initWebSait])

    useEffect(()=>{
        console.log({location});

        const {pathname} = location
        setShowSerch(true)
        if(['/search'].indexOf(pathname) === -1){
            setSearch('')
            setSelectCategory(INITIAL_STATE_CATEGORY)
        }

        if([
            '/account-products',
            '/my-account',
            '/sales-and-delivery',
            '/dashboard',
            '/address',
            '/order'
        ].indexOf(pathname) > -1){
            setShowSerch(false)
        }

        

    }, [location])

    useEffect(()=>{
        if(isAuthenticated){
            onCheckToken({DOMAIN})
        }
    }, [isAuthenticated])


  

    return (
        <>
        { Object.keys(initWebSait).length !== 0 && initWebSait?.MAIN_INFO?.ICON_TITLE_WEB_PAGE !== '' && <Favicon url={`${WEB_PICTURES_FILE_RESIZER}${initWebSait?.MAIN_INFO?.ICON_TITLE_WEB_PAGE}/50`} />}
        <header>
            <div className="header1">
                <div>


                    {/* <a className="d-flex" href={`mailto:${initWebSait?.CONTACTS_INFO?.EMAIL_SALES}`} style={{color: '#000', textDecoration: 'none'}}>
                            <div className="py-2 px-1">
                                
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.3334 2.00004C17.3334 1.08337 16.5834 0.333374 15.6667 0.333374H2.33335C1.41669 0.333374 0.666687 1.08337 0.666687 2.00004V12C0.666687 12.9167 1.41669 13.6667 2.33335 13.6667H15.6667C16.5834 13.6667 17.3334 12.9167 17.3334 12V2.00004ZM15.6667 2.00004L9.00002 6.16671L2.33335 2.00004H15.6667ZM15.6667 12H2.33335V3.66671L9.00002 7.83337L15.6667 3.66671V12Z" fill="black"/>
                                    </svg>
                                
                            </div>
                            <div className=" py-2">{initWebSait?.CONTACTS_INFO?.EMAIL_SALES}</div>
                        </a> */}
                </div>
                <div>
                    <Select value={currency} onChange={state=>onUpdateCurrency(state)} style={{marginRight:25}}>
                        {
                            initWebSait?.CURRENCY_LIST?.map(({rate_name, rate_short_name}, k)=>{
                                return(<li key={k} value={rate_short_name}>{rate_name}</li>)
                            })
                        }
                    </Select>
{/* 
                    <img src={cad} width="20px" height=""/>
                    <select className="lang" style={{marginRight:25, marginLeft:5}}>
                        <option value="english">
                          eng
                        </option>
                    </select> */}

                    <img src={cad} width="20px" height=""/>
                    <DropdownButton title="eng" className="lang">
                        <Dropdown.Item href="#/action-1"><img src={cad} width="20px" height=""/> eng</Dropdown.Item>
                    </DropdownButton>



                    {/* <span style={{marginRight:25, display:'flex'}}>
                        <span className={[`flag-icon`, `flag-icon-${jsonLang.filter(({type})=>type === lang).map(({flag})=>flag).join('')}`].join(' ')}></span>
                        <Select value={lang} onChange={state=>onUpdateLang(state)} style={{marginLeft:8}} >
                            {
                                jsonLang.map(({type, name}, k)=>{
                                    return(<li key={k} value={type}>{name}</li>)
                                })
                            }
                        </Select>
                    </span> */}

                    <div className="colll" style={{marginRight:25}} ></div>
                    {/* <NavLink className="head-link" to="/blog" >Blog</NavLink> */}
                    {/* <NavLink className="head-link" to="/contact-us" >Contact US</NavLink> */}
                    <div className="mr-3" style={{display:"flex"}}>
                       
                       
                                                {/* <a href="https://discord.gg/5UThQSw5uxhii@hiiroad.com">
                                                    <img src={discord} width="25px" height="25px" />
                                                </a>
                                                <a href="https://www.reddit.com/r/hiiroad/comments/s00w2k/rhiiroad_lounge/">
                                                    <img src={reddit} width="25px" height="25px" />
                                                </a>
                                                <a href="https://slack.com/intl/en-in/">
                                                    <img src={slack} width="25px" height="25px" />
                                                </a>
                                                <a href="https://twitter.com/i/flow/login">
                                                    <img src={twitter} width="25px" height="25px" />
                                                </a> */}


                        {/* {
                            infoContacts.map((v, k)=>{
                                if(contacts[`STATUS_${v}`] === 1 && contacts[`LOGO_${v}`] !== '')
                                return(<a key={k} href={`${contacts[`URL_${v}`]}`} target='_blank' className="px-2 py-2">
                                            <img src={`${WEB_PICTURES_FILE}${contacts[`LOGO_${v}`]}`} width="20px" height="20px" />
                                        </a>)
                            })
                        } */}



                            <a href="https://discord.gg/5UThQSw5ux" target="_blank" className="px-2 py-2">
                                {/* <img src="https://whellli.com:8444/files/file/WEB_PICTURES/a2a787a3-3512-4833-aa14-96a9c270ba00" width="20px" height="20px" /> */}
                                <img src={discord} height="26px" />
                            </a>
                            <a href="https://www.reddit.com/r/hiiroad/comments/s00w2k/rhiiroad_lounge/" target="_blank" className="px-2 py-2">
                                {/* <img src="https://whellli.com:8444/files/file/WEB_PICTURES/b8714499-a6b0-465d-be09-a1b4e59118b5" width="20px" height="20px" /> */}
                                <img src={reddit} height="26px" />
                            </a>
                            <a href="https://slack.com/intl/en-in/" target="_blank" className="px-2 py-2">
                                <img src={slack} height="26px" />
                                {/* <img src="https://whellli.com:8444/files/file/WEB_PICTURES/d6159308-68ff-46f9-900f-e71f8dc5003c" width="20px" height="20px" /> */}
                            </a>
                            <a href="https://twitter.com/" target="_blank" className="px-2 py-2">
                                {/* <img src="https://whellli.com:8444/files/file/WEB_PICTURES/88c58f2e-b989-4a4a-9ee0-fbbe047ab599" width="20px" height="20px" /> */}
                                <img src={twitter} height="26px" />
                            </a>



                    </div>

                <div className="m-hide" style={{display:'flex'}}>   
                    <NavLink className="head-link" to="/contact-us" >contact us</NavLink> 
                    <NavLink className="head-link" to="/my-account" >my account</NavLink> 
                    <NavLink className="head-link" to={isVendor === 0 ? "/orders" : "/dashboard"} > 
                        {!Boolean(user.PHOTO) && <i className="login" />} 
                        {Boolean(user.PHOTO) && <>
                            {user.PHOTO === '' && <i className="login" />}
                            {user.PHOTO !== '' && <img src={`${WEB_PICTURES_FILE_RESIZER}${user.PHOTO}/50`} className="userPhoto" />}
                        </>} 
                        {isAuthenticated ? <>
                            {/* My Account */}
                            {!Boolean(user.FIRST_NAME) && 'my Account'} 
                            {Boolean(user.FIRST_NAME) && <>
                                {user.FIRST_NAME === '' && 'my Account'}
                                {user.FIRST_NAME !== '' && <div> h<span style={{color:'#4572C2'}}>ii</span> {user.FIRST_NAME} </div>}
                            </>} 
                         </> : <div> s<span style={{color:'#4572C2'}}>i</span>gn <span style={{color:'#4572C2'}}>i</span>n / reg<span style={{color:'#4572C2'}}>i</span>ster </div>}
                    </NavLink>
                </div>
                </div>
            </div>
            <div className="header2">


                                {/* desktop navbar  */}

            <Navbar expand="lg" className="desktop-header" style={{padding:' 0.9rem 0rem'}}>
                <Container className="widthHeader" >
                    <Navbar.Brand>
                        {/* <i className="logo" /> */}
                        {Object.keys(initWebSait).length !== 0 && initWebSait?.MAIN_INFO?.LOGO_HEADER !== '' &&
                            <Link className="navbar-brand" to="/">
                                {/* <img src={`${WEB_PICTURES_FILE}${initWebSait?.MAIN_INFO?.LOGO_HEADER}`} width="165px" height="57px" style={{objectFit:'contain'}} /> */}
                                <img src={Logo} width="300px"  height="65px"  style={{objectFit:'cover'}} />
                            </Link>
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-dark-example" />
                    <Navbar.Collapse id="navbar-dark-example">
                    <Nav className="me-auto">
                        <Nav.Link href="/" style={{marginRight:20}}>home</Nav.Link>
                        {/* <Nav.Link href="/top-weekly-vendors" style={{marginRight:20}}>Vendor</Nav.Link> */}
                    
                        {/* <NavDropdown
                        id="nav-dropdown-dark-example"
                        title="explore"
                        style={{marginRight:20}}
                        >
                            {initWebSait?.CATALOGS_MARKETPLACE?.map(({CATEGORY_ID, CATEGORY_NAME}, k)=>{
                                    return(<NavDropdown.Item key={k} href={`/products-by-catalog/${CATEGORY_ID}`}>{Base64Decode(CATEGORY_NAME)}</NavDropdown.Item>)
                                })}
                        </NavDropdown> */}

                        {/* <NavDropdown
                        id="nav-dropdown-dark-example"
                        title="community"
                        style={{marginRight:20}}
                        >
                            <NavDropdown.Item href="/about" style={{fontWeight: '600'}}>about</NavDropdown.Item>
                            <NavDropdown.Item href="/hiiCoin" style={{fontWeight: '600'}}>h<span style={{color:'#4572C2'}}>ii</span>Co<span style={{color:'#4572C2'}}>i</span>n</NavDropdown.Item>
                            <NavDropdown.Item href="/hii-protocol" style={{fontWeight: '600'}}>h<span style={{color:'#4572C2'}}>ii</span> protocol</NavDropdown.Item>
                            <NavDropdown.Item href="#subscribe" style={{fontWeight: '600'}}>subscr<span style={{color:'#4572C2'}}>i</span>be</NavDropdown.Item>
                        </NavDropdown> */}

                        <div className="dropdown">
                            <a className="dropdown-toggle nav-link" onClick={()=>setShow2(!show2)} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span>commun<span style={{color:'#4572C2'}}>i</span>ty</span>
                            </a>

                            <div className={["dropdown-menu", show2 ? 'show' : '' ].join(' ')} aria-labelledby="dropdownMenuLink">
                                <a className="dropdown-item" href="/about" style={{fontWeight: '600'}}>about</a>
                                <a className="dropdown-item" href="/hiiCoin" style={{fontWeight: '600'}}>h<span style={{color:'#4572C2'}}>ii</span>Co<span style={{color:'#4572C2'}}>i</span>n</a>
                                <a className="dropdown-item" href="/hii-protocol" style={{fontWeight: '600'}}>h<span style={{color:'#4572C2'}}>ii</span> protocol</a>
                                <a className="dropdown-item" href="#subscribe" style={{fontWeight: '600'}}>subscr<span style={{color:'#4572C2'}}>i</span>be</a>
                            </div>
                        </div>


                        {/* <Nav.Link href="/wish-list">following </Nav.Link> */}

                    </Nav>
                    <div>
                        <div className="rigthOption">
                            <div>
                                <NavLink to="/wish-list" className="cartView" >
                                    {isAuthenticated && <div className="count">{countWish}</div>}
                                    <i className="heart" />
                                    <span dangerouslySetInnerHTML={{__html:replaceII('wish list')}}></span>
                                </NavLink>
                            </div>
                            {/* <div> */}
                                {/* <NavLink to="/wishlist" >
                                    <i className="compare" />
                                    <span>Compare</span>
                                </NavLink> */}

                                {/* <NavLink to="#" onClick={()=>setPopupView(true)} >
                                    <i className="compare" />
                                    <span>compare</span>
                                </NavLink> */}

                                
                            {/* </div> */}
                            <div>
                                <NavLink to="/cart" className="cartView" >
                                {isAuthenticated && <div className="count">{countCart}</div>}
                                    <i className="cart" />
                                    <span>cart</span>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/my-wallet" >
                                    <i className="wallet" />
                                    <span>my wallet</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    </Navbar.Collapse>

                    

                </Container>
            </Navbar>




                                {/* MOBILE navbar  */}

            <Navbar expand="lg" className="mobile-header" style={{padding:' 0.4rem 0rem'}}>
                    <Container className="widthHeader" style={{padding:'0% 5.5%'}} >
                        <Navbar.Brand style={{width:"75%"}} >
                            {Object.keys(initWebSait).length !== 0 && initWebSait?.MAIN_INFO?.LOGO_HEADER !== '' &&
                                <Link className="navbar-brand" to="/">
                                    <img src={mLogo} width="270px"  height="65px"  style={{objectFit:'cover'}} />
                                </Link>
                            }
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-dark-example" />
                        <Navbar.Collapse id="navbar-dark-example">
                        <Nav className="me-auto">
                            <Nav.Link href="/" style={{marginRight:20}}>home</Nav.Link>
                            <Nav.Link href="/contact-us" >contact us</Nav.Link>
                            <Nav.Link href="/my-account" >my account</Nav.Link> 
                        {/* account options */}

                            

                            <NavLink className="head-link" style={{display:"flex",padding:"0.5rem 0rem"}} to={isVendor === 0 ? "/order" : "/dashboard"} > 
                               
                                {isAuthenticated ? <>
                                    {/* My Account */}
                                    {!Boolean(user.FIRST_NAME) && 'my Account'} 
                                    {Boolean(user.FIRST_NAME) && <>
                                        {user.FIRST_NAME === '' && 'my Account'}
                                        {user.FIRST_NAME !== '' && <div> h<span style={{color:'#4572C2'}}>ii</span> {user.FIRST_NAME} </div>}
                                    </>} 

                                </> : <div> s<span style={{color:'#4572C2'}}>i</span>gn <span style={{color:'#4572C2'}}>i</span>n / reg<span style={{color:'#4572C2'}}>i</span>ster </div>}
                            </NavLink>
                                    
                        {/*  */}




                            <div className="dropdown">
                                <a className="dropdown-toggle nav-link" onClick={()=>setShow2(!show2)} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>commun<span style={{color:'#4572C2'}}>i</span>ty</span>
                                </a>

                                <div className={["dropdown-menu", show2 ? 'show' : '' ].join(' ')} aria-labelledby="dropdownMenuLink">
                                    <a className="dropdown-item" href="/about" style={{fontWeight: '500', fontSize:'13px',color:'#3f3f3f'}}>about</a>
                                    <a className="dropdown-item" href="/hiiCoin" style={{fontWeight: '500', fontSize:'13px',color:'#3f3f3f'}}>h<span style={{color:'#4572C2'}}>ii</span>Co<span style={{color:'#4572C2'}}>i</span>n</a>
                                    <a className="dropdown-item" href="/hii-protocol" style={{fontWeight: '500', fontSize:'13px',color:'#3f3f3f'}}>h<span style={{color:'#4572C2'}}>ii</span> protocol</a>
                                    <a className="dropdown-item" href="#subscribe" style={{fontWeight: '500', fontSize:'13px',color:'#3f3f3f'}}>subscr<span style={{color:'#4572C2'}}>i</span>be</a>
                                </div>
                            </div>

                        </Nav>
                        <div>
                            <div className="rigthOption">
                                <div>
                                    <NavLink to="/wish-list" className="cartView" >
                                        {isAuthenticated && <div className="count">{countWish}</div>}
                                        <i className="heart" />
                                        <span dangerouslySetInnerHTML={{__html:replaceII('following')}}></span>
                                    </NavLink>
                                </div>
                                
                                <div>
                                    <NavLink to="/cart" className="cartView" >
                                    {isAuthenticated && <div className="count">{countCart}</div>}
                                        <i className="cart" />
                                        <span>cart</span>
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink to="/my-wallet" >
                                        <i className="wallet" />
                                        <span>my wallet</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        </Navbar.Collapse>

                        

                    </Container>
            </Navbar>

            {showSerch && 
            <Container className="widthHeader" >
                <Row>
                    <Col md={2} className="zero-padding" style={{display: 'flex', alignItems: 'center', maxWidth: 355}}>
                        {/* <NavDropdown
                            className="category category2"
                            id="nav-dropdown-dark-example"
                            title="browse categories"
                        >
                            {initWebSait?.CATALOGS_MARKETPLACE?.map(({CATEGORY_ID, CATEGORY_NAME}, k)=>{
                                return(<NavDropdown.Item key={k} href={`/products-by-catalog/${CATEGORY_ID}`} dangerouslySetInnerHTML={{__html:replaceII(Base64Decode(CATEGORY_NAME))}}></NavDropdown.Item>)
                           
                            })}

                           
                        </NavDropdown> */}

                        <div className="dropdown category category2" style={{width: 'auto'}}>
                            <a className="dropdown-toggle nav-link" onClick={()=>setShow(!show)} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span>browse categor<span style={{color:'#4572C2'}}>i</span>es</span>
                            </a>

                            <div className={["dropdown-menu", show ? 'show' : '' ].join(' ')} aria-labelledby="dropdownMenuLink">
                                {initWebSait?.CATALOGS_MARKETPLACE?.map(({CATEGORY_ID, CATEGORY_NAME}, k)=>{
                                    return(<a className="dropdown-item" key={k} href={`/products-by-catalog/${CATEGORY_ID}`} dangerouslySetInnerHTML={{__html:replaceII(Base64Decode(CATEGORY_NAME))}}></a>)
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col className="zero-padding">
                        
                    <InputGroup
                        className="category search-control"
                    >
                        {/* <form onSubmit={handleSubmit}> */}
                            <FormControl placeholder="Search" aria-label="Text input with 2 dropdown buttons" value={search} onChange={({target:{value}}) => setSearch(value)} onKeyPress={({key})=>{ if(key ===  'Enter') handleSubmit()}} />
                            <i type="submit" onClick={()=>handleSubmit()} className="fa fa-search" aria-hidden="true" style={{right:9}}></i>
                        {/* </form> */}
                        {/* <NavDropdown
                            
                            id="searchcategory"
                            title={selectCategory.name}
                        >
            
                            {initWebSait?.CATALOGS_MARKETPLACE?.map(({CATEGORY_ID, CATEGORY_NAME}, k)=>{
                                return(<NavDropdown.Item 
                                    key={k} 
                                    className={[CATEGORY_ID === selectCategory.id ? 'active' : '', `id-${CATEGORY_ID}`].join(' ')} 
                                    onClick={()=>{
                                        setSelectCategory({id:CATEGORY_ID, name:Base64Decode(CATEGORY_NAME)}); 
                                        history.push({
                                            pathname: '/search',
                                            search: `?query=${search}&category_id=${CATEGORY_ID}`,
                                        });
                                    }}
                                >{Base64Decode(CATEGORY_NAME)}</NavDropdown.Item>)
                               
                            })}
                        </NavDropdown> */}
                    </InputGroup>
                    
                    </Col>
                    <Col md={1} className="zero-padding" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 10px'}}>

                        <div className="rigthOption2">
                            {/* <div>
                                <NavLink to="#" onClick={()=>setPopupView(true)} >
                                    <i className="location" />
                                    <span>track order</span>
                                </NavLink>
                            </div> */}
                            <div>
                                <NavLink to="/deals-of-the-day" >
                                    <i className="daliy" />
                                    <span dangerouslySetInnerHTML={{__html:replaceII('daily deals')}}></span>
                                </NavLink>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>}

            </div>
        </header>

        {popupView && <Popup closePopup={()=>setPopupView(false)}>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:160}}>
                <h1 className="text-center"> Coming soon</h1> 
            </div>
            <div className="text-center">
                <button className="button-16" onClick={()=>setPopupView(false)}>Close</button>
            </div>
        </Popup>}
        </>
    ) 
}
const mapStateToProps = (state) => ({
    errCode:state.config.errCode,
    currency:state.config.currency,
    lang:state.config.lang,
    user:state.config.user,
    initWebSait:state.publicUser.initWebSait,
    isAuthenticated: configSelectors.getIsAuthenticated(state),
    isVendor:state.config.resultUser.IS_VENDOR || 0
})
const mapDispatchToProps = {
    onUpdateCurrency:configActions.updateCurrency,
    onUpdateLang:configActions.updateLang,
    onShowLoginRegister:configActions.showLoginRegister,
    onCheckToken:publicUserOperations.checkToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import modelActions from './model-actions';
import { modelOperations } from '.';

const result = createReducer({}, {
    // [modelActions.getItemsListSuccess]:(_, {payload}) => payload.result,
    // [modelActions.getItemsByIdSuccess]:(_, {payload}) => payload.result
});

const pageListResult = createReducer([], {
    [modelActions.getAppPagesListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    // [modelActions.getSaitListSuccess]:() => [],
    // [modelActions.getTranslatorSuccess]:() => [],
});

const saitListResult = createReducer([], {
    [modelActions.getSaitListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    // [modelActions.getTranslatorSuccess]:() => [],
});

const getTranslatorResult = createReducer([], {
    [modelActions.getTranslatorSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const searchGetRequestsResult = createReducer([], {
    [modelActions.searchGetRequestsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const searchGetLinksResult = createReducer([], {
    [modelActions.searchGetLinkRequest]:() => [],
    [modelActions.searchGetLinkSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetsSchemeResult = createReducer([], {
    [modelActions.getWidgetsSchemeSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getKeywordList = createReducer([], {
    [modelActions.getKeywordsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetList = createReducer([], {
    [modelActions.getWidgetListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});
const getWidgetList2 = createReducer([], {
    [modelActions.getWidgetListSuccess]:(_, {payload}) => payload.result['#result-set-2'],
});
const getItemsListWidgetQty = createReducer([], {
    [modelActions.getItemsListWidgetSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getItemGroupList = createReducer([], {
    [modelActions.getItemGroupListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getGallariesListWidgetQty = createReducer([], {
    [modelActions.getGallariesListWidgetQtySuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getGallariesListWidgetQtyItemGallery = createReducer(-1, {
    [modelActions.getGallariesListWidgetQtySuccess]:(_, {payload}) => payload.result.ITEM_GALLERY,
});

const getItemListSortGroup = createReducer([], {
    [modelActions.getItemListSortGroupSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetConvertationList = createReducer([], {
    [modelActions.getWidgetConvertationListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getItemGroupTypes = createReducer([], {
    [modelActions.GetItemGroupTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetRecordList = createReducer([], {
    [modelActions.getWidgetRecordListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetGroupType = createReducer([], {
    [modelActions.getWidgetGroupTypeSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetsNoSchemaWithType = createReducer([], {
    [modelActions.getWidgetsNoSchemaWithTypeSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    [modelActions.getWidgetsNoPropertiesProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getSchemeByItemList = createReducer([], {
    [modelActions.getSchemeByItemListRequest]:() => [],
    [modelActions.getSchemeByItemListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});


const getWidgetSchemeById = createReducer({}, {
    [modelActions.getWidgetsSchemeSuccess]:()=>{},
    [modelActions.getWidgetsSchemeSuccess]:() => {},
    [modelActions.getWidgetSchemeByIdSuccess]:(_, {payload}) => payload.result,
});



const getKeywordLink = createReducer({}, {
    [modelActions.getKeywordLinkSuccess]:(_, {payload}) => payload.result,
});

const getItemById = createReducer({}, {
    // [modelActions.getItemByIdRequest]:() => {},
    [modelActions.getItemByIdSuccess]:(_, {payload}) => payload.result,
});

const getKeyword = createReducer({}, {
    [modelActions.getKeywordsSuccess]:()=>{},
    [modelActions.getKeywordRequest]:()=>{},
    [modelActions.getKeywordSuccess]:(_, {payload}) => payload.result,
});

const getGallaryByID = createReducer({}, {
    [modelActions.getGallaryByIDRequest]:()=>{},
    [modelActions.getGallaryByIDSuccess]:(_, {payload}) => payload.result,
});

const getWidgetById = createReducer({}, {
    [modelActions.getWidgetByIdRequest]:()=>{},
    [modelActions.getWidgetByIdSuccess]:(_, {payload}) => payload.result,
});

const getWidgetRecordById = createReducer({}, {
    [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.getWidgetRecordByIdSuccess]:(_, {payload}) => payload.result,
});

const getWidgetSchemeWithWidgets = createReducer({}, {
    [modelActions.getWidgetsSchemeSuccess]:()=>{},
    [modelActions.getWidgetsSchemeSuccess]:() => {},
    [modelActions.getWidgetSchemeWithWidgetsSuccess]:(_, {payload}) => payload.result,
});

const getLinkedWidgets = createReducer({}, {
    [modelActions.getWidgetsSchemeSuccess]:()=>{},

    [modelActions.getLinkedWidgetsRequest]:()=>{},
    [modelActions.getLinkedWidgetsSuccess]:(_, {payload}) => payload.result,
});

const getSchemeByProduct = createReducer({}, {
    [modelActions.getWidgetsSchemeSuccess]:()=>{},

    [modelActions.getSchemeByProductRequest]:()=>{},
    [modelActions.getSchemeByProductSuccess]:(_, {payload}) => payload.result,
    [modelActions.getPropertiesByProductSuccess]:(_, {payload}) => payload.result,
});

// const updateTranslatorResult = createReducer([], {
//     [modelActions.updateTranslatorSuccess]:(_, {payload}) => payload.result['#result-set-1'],
// });

const getItemGroupById = createReducer({}, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.getItemGroupByIdSuccess]:(_, {payload}) => payload.result,
});

const getItemWidgetsPricing = createReducer([], {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.getItemWidgetsPricingSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const updateItemPricingWidget = createReducer([], {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.updateItemPricingWidgetSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getItemsPricing = createReducer({}, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.getItemsPricingSuccess]:(_, {payload}) => payload.result,
});

const updateItemPricing = createReducer(0, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.updateItemPricingSuccess]:(_, {payload}) =>payload.err_code,
});


const removeWidgetRecord = createReducer(0, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.removeWidgetRecordRequest]:() =>-1,
    [modelActions.removeWidgetRecordSuccess]:(_, {payload}) =>payload.err_code,
});

const updateItemPackingWidget = createReducer(0, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    
    [modelActions.updateItemTypePricingRequest]:() =>-1,
    [modelActions.getItemsPricingRequest]:() =>-1,
    [modelActions.getItemWidgetsPricingRequest]:() =>-1,
    [modelActions.updateItemPackingWidgetRequest]:() =>-1,
    [modelActions.updateItemPackingWidgetSuccess]:(_, {payload}) =>payload.err_code,
});

const convertPrice = createReducer([], {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.updateItemTypePricingRequest]:()=>[],
    [modelActions.getItemsPricingRequest]:()=>[],
    [modelActions.convertPriceSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const updateItemPackingPricing = createReducer(0, {
    [modelActions.updateItemTypePricingRequest]:()=>-1,
    [modelActions.getItemsPricingRequest]:()=>-1,
    [modelActions.convertPriceRequest]:()=>-1,
    [modelActions.updateItemPackingPricingRequest]:()=>-1,
    [modelActions.updateItemPackingPricingSuccess]:(_, {payload}) => payload.err_code
});

const updateItemTypePricing = createReducer({}, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.updateItemTypePricingSuccess]:(_, {payload}) => payload.result,
});
const updateItemStartPricing = createReducer(0, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.updateItemStartPricingRequest]:() =>-1,
    [modelActions.updateItemStartPricingSuccess]:(_, {payload}) =>payload.err_code,
});

const getWidgetTypes = createReducer([], {
    // [modelActions.getWidgetByIdRequest]:()=>{},
    [modelActions.getWidgetTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getPropertiesByProduct = createReducer({}, {
    // [modelActions.getWidgetRecordByIdRequest]:()=>{},
    [modelActions.getSchemeByProductSuccess]:(_, {payload}) => payload.result,
    [modelActions.getPropertiesByProductSuccess]:(_, {payload}) => payload.result,
});

const getProductListWithOptions = createReducer([], {
    [modelActions.getProductListWithOptionsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getProductListWithOptionsAndPrices = createReducer([], {
    [modelActions.getProductListWithOptionsAndPricesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const updatePriceProductOrOption = createReducer(-1, { 
    [modelActions.getLocationsRequest]:() => -1,
    [modelActions.getLocationsSuccess]:() => -1,
    [modelActions.updatePriceProductOrOptionRequest]:() => -1,
    [modelActions.updatePriceProductOrOptionSuccess]:(_, {payload}) => payload.err_code,

});

const getLocations = createReducer([], {
    [modelActions.getLocationsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getLocationById = createReducer({}, {
    [modelActions.updatePriceProductOrOptionSuccess]:() => {},
    [modelActions.getLocationsSuccess]:() => {},
    [modelActions.getLocationByIdSuccess]:(_, {payload}) => payload.result,
});

const getWidgetsListNotCatalog = createReducer([], {
    [modelActions.getWidgetsListNotCatalogSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getCatalogsList = createReducer([], {
    [modelActions.getCatalogsListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});


const linkUnlinkWidgetToCatalog = createReducer(-1, { 
    [modelActions.linkUnlinkWidgetToCatalogRequest]:() => -1,
    [modelActions.linkUnlinkWidgetToCatalogSuccess]:(_, {payload}) => payload.err_code,
});

const getProductsInStock = createReducer([], {
    [modelActions.getProductsInStockSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getLocationShortList = createReducer([], {
    [modelActions.getLocationShortListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getProductsInStockNotLocation = createReducer([], {
    [modelActions.getProductsInStockNotLocationSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});
const initProductInLocation = createReducer(-1, { 
    [modelActions.initProductInLocationRequest]:() => -1,
    [modelActions.initProductInLocationSuccess]:(_, {payload}) => payload.err_code,
});
const getProductBarcodesList = createReducer([], {
    [modelActions.getProductBarcodesListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});
const addProductBarcode = createReducer(-1, { 
    [modelActions.addProductBarcodeRequest]:() => -1,
    [modelActions.addProductBarcodeSuccess]:(_, {payload}) => payload.err_code,
});
const moveProductToLocation = createReducer(-1, { 
    [modelActions.moveProductToLocationRequest]:() => -1,
    [modelActions.moveProductToLocationSuccess]:(_, {payload}) => payload.err_code,
});
const getLocationsWithProductsQty = createReducer([], {
    [modelActions.getLocationsWithProductsQtySuccess]:(_, {payload}) => payload.result['#result-set-1'],
});
const getMovementProductHistory = createReducer([], {
    [modelActions.getMovementProductHistorySuccess]:(_, {payload}) => payload.result['#result-set-1'],
});
const removeImageFromGallery = createReducer(-1, { 
    [modelActions.removeImageFromGalleryRequest]:() => -1,
    [modelActions.removeImageFromGallerySuccess]:(_, {payload}) => payload.err_code,
});

const getProductFromLucene = createReducer({}, {
    [modelActions.getProductFromLuceneSuccess]:(_, {payload}) => payload.result,
});

const getAttributesByProduct = createReducer({}, {
    [modelActions.getAttributesByProductSuccess]:(_, {payload}) => payload.result,
});

const getWidgetsNoAttributesProduct = createReducer([], {
    [modelActions.getWidgetsNoAttributesProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    [modelActions.notLinkedPricingAttributesByProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

// const notLinkedPricingAttributesByProduct = createReducer([], {
//     [modelActions.notLinkedPricingAttributesByProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
// });

const getProductCategories = createReducer([], {
    [modelActions.getProductCategoriesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getAttributesByGroupAndProduct = createReducer([], {
    [modelActions.getAttributesByGroupAndProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getPricingAttributesByProduct = createReducer({}, {
    [modelActions.getPricingAttributesByProductSuccess]:(_, {payload}) => payload.result,
});

const addPricingAttributeByProduct = createReducer(-1, { 
    [modelActions.addPricingAttributeByProductRequest]:() => -1,
    [modelActions.addPricingAttributeByProductSuccess]:(_, {payload}) => payload.err_code,
});

const getMeasures = createReducer([], {
    [modelActions.getMeasuresSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getMeasureById = createReducer({}, {
    [modelActions.getMeasureByIdSuccess]:(_, {payload}) => payload.result,
});

const getItemListLinkGroup = createReducer([], {
    [modelActions.getItemListLinkGroupSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getItemListNotLinkGroup = createReducer([], {
    [modelActions.getItemListNotLinkGroupSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getSpecialProductsForProduct = createReducer([], {
    [modelActions.getSpecialProductsForProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getSpecialProductsNotLinkedProduct = createReducer([], {
    [modelActions.getSpecialProductsNotLinkedProductSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getLinkedCatalogs = createReducer([], {
    [modelActions.getLinkedCatalogsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getNotLinkedCatalogs = createReducer([], {
    [modelActions.getNotLinkedCatalogsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getFeedbackShortInfo = createReducer({}, {
    [modelActions.getFeedbackShortInfoSuccess]:(_, {payload}) => payload.result,
});

const getFeedbackList = createReducer({}, {
    [modelActions.getFeedbackListSuccess]:(_, {payload}) => payload.result,
});

const getAllProductsInStock = createReducer([], {
    [modelActions.getAllProductsInStockSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getWidgetRecords = createReducer({}, {
    [modelActions.getWidgetRecordsSuccess]:(_, {payload}) => payload.result,
});

const getVisibleGroupName = createReducer({}, {
    [modelActions.getAttributesByProductSuccess]:() => {},
    [modelActions.getVisibleGroupNameSuccess]:(_, {payload}) => payload.result,
});
const getVisibleGroupNameErr = createReducer(-1, { 
    [modelActions.getVisibleGroupNameSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.getAttributesByProductSuccess]:() => -1,
    [modelActions.getVisibleGroupNameRequest]:() => -1,
});

const getMainSaitSettings = createReducer({}, {
    [modelActions.getMainSaitSettingsSuccess]:(_, {payload}) => payload.result,
});

const getSaitSettingsList = createReducer([], {
    [modelActions.getSaitSettingsListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
});

const getContactsAndSocialSaitSettings = createReducer({}, {
    [modelActions.getContactsAndSocialSaitSettingsSuccess]:(_, {payload}) => payload.result,
});

const getPartnersSaitSettings = createReducer({}, {
    [modelActions.getPartnersSaitSettingsSuccess]:(_, {payload}) => payload.result,
});

const getPartnerByIdSaitSettings = createReducer({}, {
    [modelActions.getPartnerByIdSaitSettingsSuccess]:(_, {payload}) => payload.result,
});


const err_code = createReducer(0, {
    [modelActions.getAppPagesListRequest]:() => -1,
    [modelActions.getSaitListRequest]:() => -1,
    [modelActions.getTranslatorRequest]:() => -1,
    [modelActions.getWidgetsSchemeRequest]:() => -1,
    [modelActions.createUpdateWidgetSchemeRequest]:() => -1,
    [modelActions.getKeywordLinkSuccess]:() => -1,
    [modelActions.getKeywordSuccess]:() => -1,
    [modelActions.createUpdateKeyRequest]:() => -1,
    [modelActions.getItemsListWidgetRequest]:() => -1,
    [modelActions.updateItemStatusRequest]:() => -1,
    [modelActions.getItemByIdSuccess]:() => -1,
    [modelActions.createUpdateItemRequest]:() => -1,
    [modelActions.getGallaryByIDSuccess]:() => -1,
    [modelActions.createUpdateGallaryRequest]:() => -1,
    [modelActions.updateWidgetActiveRequest]:() => -1,
    [modelActions.createUpadetWidgetRequest]:() => -1,
    [modelActions.createItemGroupRequest]:() => -1,
    [modelActions.remoevItemGroupRequest]:() => -1,
    [modelActions.linkUnlinkItemGroupRequest]:() => -1,
    [modelActions.getWidgetListSuccess]:() => -1,
    [modelActions.removeWidgetRequest]:() => -1,
    [modelActions.createUpdateItemGroupRequest]:() => -1,
    [modelActions.createUpdateWidgetRecordRequest]:() => -1,
    [modelActions.getWidgetRecordListRequest]:() => -1,
    [modelActions.changeLevelItemInGroupRequest]:() => -1,
    [modelActions.linkWidgetToWidgetSchemeRequest]:() => -1,
    [modelActions.getWidgetsNoSchemaWithTypeRequest]:() => -1,
    [modelActions.getWidgetSchemeWithWidgetsRequest]:() => -1,
    [modelActions.updateWidgetLevelInWidgetSchemeRequest]:() => -1,
    [modelActions.linkProductToWidgetSchemeRequest]:() => -1,
    [modelActions.updateItemPropertiesRequest]:() => -1,
    [modelActions.getSchemeByProductSuccess]:() => -1,
    [modelActions.updateItemPricingWidgetRequest]:() => -1,
    [modelActions.getItemsPricingRequest]:() => -1,
    [modelActions.updateItemPricingRequest]:() => -1,
    [modelActions.removeWidgetRecordRequest]:() => -1,
    [modelActions.updateItemStartPricingRequest]:() => -1,
    [modelActions.addPropertiesWidgetByProductRequest]:() => -1,
    [modelActions.removePropertiesWidgetByProductRequest]:() => -1,
    [modelActions.updateGalleryFlagProductOrOptionRequest]:() => -1,
    [modelActions.copyPriceToOptionsRequest]:() => -1,
    [modelActions.updatePriceProductOrOptionRequest]:() => -1,
    [modelActions.createUpdateLocationRequest]:() => -1,
    [modelActions.getLocationsRequest]:() => -1,
    [modelActions.getLocationByIdRequest]:() => -1,
    [modelActions.linkUnlinkWidgetToCatalogRequest]:() => -1,
    [modelActions.initProductInLocationRequest]:() => -1,
    [modelActions.addProductBarcodeRequest]:() => -1,
    [modelActions.moveProductToLocationRequest]:() => -1,
    [modelActions.getProductBarcodesListRequest]:() => -1,
    [modelActions.removeImageFromGalleryRequest]:() => -1,
    [modelActions.updateSpecialPriceProductOrOptionRequest]:() => -1,
    [modelActions.getItemGroupListRequest]:() => -1,
    [modelActions.portalRemoveProductByIdRequest]:() => -1,
    [modelActions.addAttributeByProductRequest]:() => -1,
    [modelActions.removeAttributeByProductRequest]:() => -1,
    [modelActions.updateProductCategoryRequest]:() => -1,
    [modelActions.updateItemConvertationWidgetRequest]:() => -1,
    [modelActions.addRemovePropertiesWidgetByProductRequest]:() => -1,
    [modelActions.dragAndDropPropertiesWidgetByProductRequest]:() => -1,
    [modelActions.changePricingOptionsFlagRequest]:() => -1,
    [modelActions.removePricingAttributeByProductRequest]:() => -1,
    [modelActions.createUpdateManualMeasureRequest]:() => -1,
    [modelActions.removeManualMeasureRequest]:() => -1,
    [modelActions.dragAndDropSpecialProductsForProductRequest]:() => -1,
    [modelActions.dragAndDropItemListLinkGroupRequest]:() => -1,
    [modelActions.linkUnlinkSpecialProductsForProductRequest]:() => -1,
    [modelActions.drugAndDropCatalogsRequest]:() => -1,
    [modelActions.updateVisibleGroupNameRequest]:() => -1,
    [modelActions.getVisibleGroupNameRequest]:() => -1,
    [modelActions.updateMainSaitSettingsRequest]:() => -1,
    [modelActions.createSaitRequest]:() => -1,
    [modelActions.updateContactsAndSocialSaitSettingsRequest]:() => -1,
    [modelActions.createUpdatePartnerSaitSettingsRequest]:() => -1,
    [modelActions.removePartnerSaitSettingsRequest]:() => -1,
    [modelActions.getPartnerByIdSaitSettingsRequest]:() => -1,
    [modelActions.getPartnersSaitSettingsRequest]:() => -1,
    [modelActions.createUpdateLegalInfoTitleRequest]:() => -1,
    [modelActions.updateLegalInfoDescriptionRequest]:() => -1,
    [modelActions.updateLegalInfoDeclineTextRequest]:() => -1,
    [modelActions.updateLegalInfoStatusRequest]:() => -1,
    [modelActions.updateLegalInfoConfirmationRequest]:() => -1,
    [modelActions.removeLegalInfoRequest]:() => -1,
    [modelActions.updatePaymentSaitSettingsRequest]:() => -1,
    [modelActions.updatePaymentSaitSettingsNameRequest]:() => -1,
    [modelActions.updatePaymentSaitSettingsStatusRequest]:() => -1,
    [modelActions.updatePaymentSaitSettingsAddressRequest]:() => -1,
    [modelActions.updatePaymentSaitSettingsIconsRequest]:() => -1,
    [modelActions.updateTranslateByCodeRequest]:() => -1,
    [modelActions.updateAdditionalPropertyStatusRequest]:() => -1,
    [modelActions.payCryptoOrderFromHistoryRequest]:() => -1,
    [modelActions.updateLocationProductDeliveryRequest]:() => -1,
    [modelActions.updateLocationDeliveryServiceRequest]:() => -1,
    [modelActions.updateDeliveryTrackingRequest]:() => -1,
    [modelActions.updateDeliveryStatusRequest]:() => -1,
    [modelActions.getAllProductsInStockRequest]:() => -1,
    [modelActions.getAllProductsInStockSuccess]:() => -1,
    [modelActions.cancelDeliveryRequest]:() => -1,
    [modelActions.updateMarketplaceProductStatusRequest]:() => -1,
    [modelActions.updateWebSaitProductStatusRequest]:() => -1,
    [modelActions.updateWebSaitPaymentSystemBraintreeRequest]:() => -1,
    [modelActions.getPaymentSaitSettingsSuccess]:() => -1,
    [modelActions.updateWebSaitPaymentSystemPaypalRequest]:() => -1,
    [modelActions.getProductListTypeSaitSettingsSuccess]:() => -1,
    [modelActions.updateProductListTypeSaitSettingsRequest]:() => -1,
    [modelActions.getCryptoOrderForPaySuccess]:() => -1,
    [modelActions.getOrdersHistoryRequest]:() => -1,
    [modelActions.getOrderProductsDeliveryRequest]:() => -1,
    [modelActions.getOrderProductsDeliverySuccess]:() => -1,
    [modelActions.createShareSalesProfitRequest]:() => -1,
    [modelActions.updateShareSalesProfitStatusRequest]:() => -1,
    [modelActions.removeShareSalesProfitRequest]:() => -1,
    [modelActions.updateLocationStatusRequest]:() => -1,
    [modelActions.dragAndDropGallaryRequest]:() => -1,
    [modelActions.updateWebSaitPaymentSystemSquareupRequest]:() => -1,
    [modelActions.updateWebSaitPaymentSystemStripRequest]:() => -1,
    [modelActions.createUpdateMarketplaceCatalogRequest]:() => -1,
    [modelActions.dragAndDropMarketplaceCatalogRequest]:() => -1,
    [modelActions.getMarketplaceCatalogsSuccess]:() => -1,
    [modelActions.linkUnlinkItemMarketplaceCatalogRequest]:() => -1,
    [modelActions.dragAndDropItemsMarketplaceCatalogRequest]:() => -1,
    [modelActions.publishErc1155Request]:() => -1,
    [modelActions.updateUserBlockchainAddressRequest]:() => -1,
    [modelActions.updateTransactionHashMetamaskRequest]:() => -1,
    [modelActions.saveProceedToCheckoutRequest]:() => -1,
    [modelActions.transactionBuyCoinsBitcoinRequest]:() => -1,
    [modelActions.transactionBuyCoinsPaypalRequest]:() => -1,
    // [modelActions.getStripClientSecretRequest]:() => -1,
    [modelActions.transactionBuyCoinsStripRequest]:() => -1,
    [modelActions.updateVendorNameAndCountryRequest]:() => -1,
    [modelActions.updateVendorImageRequest]:() => -1,
    [modelActions.createUpdatePayoutMethodRequest]:() => -1,
    [modelActions.createPayoutRequest]:() => -1,
    [modelActions.createUpdateDspSibscriptionsRequest]:() => -1,
    [modelActions.removeDspSubscriptionsRequest]:() => -1,
    [modelActions.addDspTemplateImageRequest]:() => -1,
    [modelActions.removeDspTemplateImageRequest]:() => -1,
    [modelActions.createUpdateDspTemplatesRequest]:() => -1,
    [modelActions.removeDspTemplatesRequest]:() => -1,
    [modelActions.createClientDistributionRequest]:() => -1,
    [modelActions.removeDistributionRequest]:() => -1,
    [modelActions.resendClientMailRequest]:() => -1,
    [modelActions.createUpdateSystemDistributionRequest]:() => -1,
    [modelActions.setTemplateDistributionRequest]:() => -1,
    [modelActions.removeTemplateDistributionRequest]:() => -1,
    [modelActions.getDspSubscriptionsSuccess]:() => -1,
    [modelActions.getDspTemplatesSuccess]:() => -1,
    [modelActions.getClientDistributionListSuccess]:() => -1,
    [modelActions.getSystemDistributionListSuccess]:() => -1,
    [modelActions.stopStartDistributionRequest]:() => -1,
    [modelActions.setProductPercentDiscountRequest]:() => -1,
    [modelActions.setCashbackPointsMaxLimitRequest]:() => -1,
    [modelActions.setPointDiscountMaxLimitRequest]:() => -1,
    [modelActions.setCashbackPointsDefaultValueRequest]:() => -1,
    [modelActions.setPointDiscountDefaultValueRequest]:() => -1,
    [modelActions.updateExchangeAddressRequest]:() => -1,
    [modelActions.getExchangeAddressRequest]:() => -1,
    [modelActions.getExchangeAddressSuccess]:() => -1,
    [modelActions.getRatesManagmentRequest]:() => -1,
    [modelActions.updateRatesManagmentRequest]:() => -1,
    [modelActions.webSaitPaymentSystemByTypeRequest]:() => -1,
    [modelActions.getGlobalPaymentSystemsRequest]:() => -1,
    [modelActions.sendTestEmailRequest]:() => -1,


    [modelActions.sendTestEmailSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateRatesManagmentSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateExchangeAddressSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.setPointDiscountDefaultValueSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.setCashbackPointsDefaultValueSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.setPointDiscountMaxLimitSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.setCashbackPointsMaxLimitSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.setProductPercentDiscountSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.stopStartDistributionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeTemplateDistributionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.setTemplateDistributionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateSystemDistributionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.resendClientMailSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeDistributionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createClientDistributionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeDspTemplatesSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateDspTemplatesSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeDspTemplateImageSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.addDspTemplateImageSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeDspSubscriptionsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateDspSibscriptionsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createPayoutSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdatePayoutMethodSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateVendorImageSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateVendorNameAndCountrySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.transactionBuyCoinsStripSuccess]:(_, {payload}) => payload.err_code,
    // [modelActions.getStripClientSecretSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.transactionBuyCoinsPaypalSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.transactionBuyCoinsBitcoinSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.saveProceedToCheckoutSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateTransactionHashMetamaskSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateUserBlockchainAddressSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.publishErc1155Success]:(_, {payload}) => payload.err_code,
    [modelActions.dragAndDropItemsMarketplaceCatalogSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.linkUnlinkItemMarketplaceCatalogSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.dragAndDropMarketplaceCatalogSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateMarketplaceCatalogSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWebSaitPaymentSystemStripSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWebSaitPaymentSystemSquareupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.dragAndDropGallarySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLocationStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeShareSalesProfitSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateShareSalesProfitStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createShareSalesProfitSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateProductListTypeSaitSettingsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWebSaitPaymentSystemPaypalSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWebSaitPaymentSystemBraintreeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWebSaitProductStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateMarketplaceProductStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.cancelDeliverySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateDeliveryStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateDeliveryTrackingSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLocationDeliveryServiceSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLocationProductDeliverySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.payCryptoOrderFromHistorySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateAdditionalPropertyStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateTranslateByCodeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updatePaymentSaitSettingsIconsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updatePaymentSaitSettingsAddressSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updatePaymentSaitSettingsStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updatePaymentSaitSettingsNameSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updatePaymentSaitSettingsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeLegalInfoSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLegalInfoConfirmationSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLegalInfoStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLegalInfoDeclineTextSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateLegalInfoDescriptionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateLegalInfoTitleSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removePartnerSaitSettingsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdatePartnerSaitSettingsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateContactsAndSocialSaitSettingsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createSaitSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateMainSaitSettingsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.getVisibleGroupNameSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateVisibleGroupNameSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.drugAndDropCatalogsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.linkUnlinkSpecialProductsForProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.dragAndDropItemListLinkGroupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.dragAndDropSpecialProductsForProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeManualMeasureSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateManualMeasureSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removePricingAttributeByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.changePricingOptionsFlagSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.dragAndDropPropertiesWidgetByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.addRemovePropertiesWidgetByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateItemConvertationWidgetSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateProductCategorySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeAttributeByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.addAttributeByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.portalRemoveProductByIdSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateSpecialPriceProductOrOptionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeImageFromGallerySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.moveProductToLocationSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.addProductBarcodeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.initProductInLocationSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.linkUnlinkWidgetToCatalogSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateLocationSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updatePriceProductOrOptionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.copyPriceToOptionsSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateGalleryFlagProductOrOptionSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removePropertiesWidgetByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.addPropertiesWidgetByProductSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateItemStartPricingSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeWidgetRecordSuccess]:() => -1,
    [modelActions.updatePageRequest]:() => -1,
    [modelActions.updatePageSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateItemPricingWidgetSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateTranslatorSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeTranslatorSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateWidgetSchemeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateKeywordSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateKeySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeKeywordSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateItemStatusSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateItemSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateGallarySuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWidgetActiveSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpadetWidgetSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createItemGroupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.remoevItemGroupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.linkUnlinkItemGroupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.removeWidgetSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateItemGroupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.createUpdateWidgetRecordSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.changeLevelItemInGroupSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.linkWidgetToWidgetSchemeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateWidgetLevelInWidgetSchemeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.linkProductToWidgetSchemeSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateItemPropertiesSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.updateItemPricingSuccess]:(_, {payload}) => payload.err_code,
    [modelActions.errCode]:(_, {payload}) => payload,
    // [modelActions.getItemsListSuccess]:(_, {payload}) => payload.err_code,
    // [modelActions.getItemsByIdSuccess]:(_, {payload}) => payload.err_code
})
const err_t = createReducer('', {
    [modelActions.clearErrorText]:() => '',
    [modelActions.updatePageSuccess]:(_, {payload}) => payload.err_t,
    [modelActions.moveProductToLocationSuccess]:(_, {payload}) => payload.err_t,
    // [modelActions.getItemsListSuccess]:(_, {payload}) => payload.err_t,
    // [modelActions.getItemsByIdSuccess]:(_, {payload}) => payload.err_t
})

const loading = createReducer(false, {
    [modelActions.getAppPagesListRequest]:() => true,
    [modelActions.getAppPagesListSuccess]:() => false,
    [modelActions.getAppPagesListError]:() => false,

    // [modelActions.getItemsListRequest]:() => true,
    // [modelActions.getItemsListSuccess]:() => false,
    // [modelActions.getItemsListError]:() => false,


    // [modelActions.getItemsByIdRequest]:() => true,
    // [modelActions.getItemsByIdSuccess]:() => false,
    // [modelActions.getItemsByIdError]:() => false
})

const loader = createReducer(false, {
    [modelActions.loader]:(_, {payload}) => payload
})


export default combineReducers({
    result,
    loader,
    err_code,
    err_t,
    loading,
    getSaitSettingsList,
    getOrdersHistory: createReducer([], {
        [modelActions.getOrdersHistorySuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),
    getLegalInfoSaitSettingsList: createReducer({}, {
        [modelActions.getLegalInfoSaitSettingsListSuccess]:(_, {payload}) => payload.result,
    }),
    getLegalInfoDescription: createReducer({}, {
        [modelActions.getLegalInfoDescriptionSuccess]:(_, {payload}) => payload.result,
    }),
    getLegalInfoDeclineText: createReducer({}, {
        [modelActions.getLegalInfoDeclineTextSuccess]:(_, {payload}) => payload.result,
    }),
    getLegalInfoTitle: createReducer({}, {
        [modelActions.getLegalInfoTitleSuccess]:(_, {payload}) => payload.result,
    }),
    getPaymentSaitSettings: createReducer({}, {
        [modelActions.getPaymentSaitSettingsSuccess]:(_, {payload}) => payload.result,
    }),
 
    getTranslateByCode: createReducer({}, { 
        [modelActions.getSaitSettingsListRequest]:() => {},
        [modelActions.getTranslateByCodeSuccess]:(_, {payload}) => payload.result,

        [modelActions.getWidgetRecordsRequest]:() => {}
    }),

    getCryptoOrderForPay: createReducer({}, {
        [modelActions.getCryptoOrderForPaySuccess]:(_, {payload}) => payload.result,
    }),
    getOrderProductsDelivery: createReducer({}, {
        [modelActions.getOrderProductsDeliverySuccess]:(_, {payload}) => payload.result,
    }),
    getLocationsByProduct: createReducer({}, {
        [modelActions.getLocationsByProductSuccess]:(_, {payload}) => payload,
    }),

    getDeliveryPackages: createReducer({}, {
        [modelActions.getDeliveryPackagesSuccess]:(_, {payload}) => payload.result,
    }),

    getDeliveryTypes: createReducer([], {
        [modelActions.getDeliveryTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    updateDeliveryStatus: createReducer(-1, {
        [modelActions.getOrdersHistoryRequest]:() => -1,
        [modelActions.updateDeliveryStatusRequest]:() => -1,
        [modelActions.updateDeliveryStatusSuccess]:(_, {payload}) => payload.err_code,
       
    }),

    cancelDelivery: createReducer(-1, {
        [modelActions.getOrdersHistoryRequest]:() => -1,
        [modelActions.cancelDeliveryRequest]:() => -1,
        [modelActions.cancelDeliverySuccess]:(_, {payload}) => payload.err_code,
       
    }),

    getWebSaitsProductStatus: createReducer({}, {
        [modelActions.getWebSaitsProductStatusSuccess]:(_, {payload}) => payload.result,
    }),
    webSaitPaymentSystemsTypes: createReducer([], {
        [modelActions.webSaitPaymentSystemsTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    webSaitPaymentSystemByType: createReducer({}, {
        [modelActions.webSaitPaymentSystemByTypeSuccess]:(_, {payload}) => payload.result,
    }),

    getProductListTypeSaitSettings: createReducer({}, {
        [modelActions.getProductListTypeSaitSettingsSuccess]:(_, {payload}) => payload.result,
    }),

    currencyList: createReducer([], {
        [modelActions.currencyListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    usersOrdersHistoryList: createReducer([], {
        [modelActions.usersOrdersHistoryListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),
    getUserOrdersHistoryByUser: createReducer([], {
        [modelActions.getUserOrdersHistoryByUserSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getShareSalesProfitList: createReducer([], {
        [modelActions.getShareSalesProfitListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    updateLocationStatus: createReducer([], {
        [modelActions.updateLocationStatusSuccess]:(_, {payload}) => payload.result,
    }),

    getMarketplaceCatalogs: createReducer([], {
        [modelActions.getMarketplaceCatalogsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getMarketplaceCatalogById: createReducer({}, {
        [modelActions.getMarketplaceCatalogByIdSuccess]:(_, {payload}) => payload.result,
    }),

    getItemsLinkMarketplaceCatalog: createReducer([], {
        [modelActions.getItemsLinkMarketplaceCatalogSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getItemsUnlinkMarketplaceCatalog: createReducer([], {
        [modelActions.getItemsUnlinkMarketplaceCatalogSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    publishErc1155: createReducer({}, {
        [modelActions.publishErc1155Success]:(_, {payload}) => payload.result,
    }),

    getUserBlockchainAddress: createReducer({}, {
        [modelActions.getUserBlockchainAddressSuccess]:(_, {payload}) => payload.result,
    }),

    getDataMetamask: createReducer({}, {
        [modelActions.getDataMetamaskSuccess]:(_, {payload}) => payload.result,
    }),

    getGlobalPaymentSystems: createReducer([], {
        [modelActions.getGlobalPaymentSystemsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),
    getGlobalPaymentSystemsHistory: createReducer([], {
        [modelActions.getGlobalPaymentSystemsSuccess]:(_, {payload}) => payload.result['#result-set-2'],
    }),

    getGlobalPaymentSystemsJson: createReducer({}, {
        [modelActions.getGlobalPaymentSystemsSuccess]:(_, {payload}) => payload.result,
    }),

    paymentSystemsCoinsAmount: createReducer({}, {
        [modelActions.paymentSystemsCoinsAmountSuccess]:(_, {payload}) => payload,
    }),
    saveProceedToCheckout: createReducer({}, {
        [modelActions.saveProceedToCheckoutSuccess]:(_, {payload}) => payload.result,
    }),

    getBalanceInfo: createReducer({}, {
        [modelActions.getBalanceInfoSuccess]:(_, {payload}) => payload.result,
    }),

    getPaypalClientId: createReducer('', {
        [modelActions.getPaypalClientIdSuccess]:(_, {payload}) => payload.result,
    }),

    transactionBuyCoinsPaypal: createReducer(0, {
        [modelActions.transactionBuyCoinsPaypalSuccess]:(_, {payload}) => payload.err_code,
    }),

    getStripToken: createReducer('', {
        [modelActions.getStripTokenSuccess]:(_, {payload}) => payload.result,
    }),

    getStripClientSecret: createReducer('', {
        [modelActions.getStripClientSecretSuccess]:(_, {payload}) => payload.result,
    }),

    getVendorInfo: createReducer({}, {
        [modelActions.getVendorInfoSuccess]:(_, {payload}) => payload.result,
    }),

    updateVendorImage: createReducer({}, {
        [modelActions.updateVendorImageSuccess]:(_, {payload}) => payload.result,
    }),
    getPayoutMethodTypes: createReducer([], {
        [modelActions.getPayoutMethodTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),
    getPayoutMethods: createReducer([], {
        [modelActions.getPayoutMethodsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getPayoutMethodNames: createReducer([], {
        [modelActions.getPayoutMethodNamesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getTransactionsPayout: createReducer([], {
        [modelActions.getTransactionsPayoutSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getDspSibscriptionById: createReducer({}, {
        [modelActions.getDspSibscriptionByIdSuccess]:(_, {payload}) => payload.result,
    }),

    getDspTemplates: createReducer([], {
        [modelActions.getDspTemplatesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    initDspTemplateId: createReducer({}, {
        [modelActions.initDspTemplateIdSuccess]:(_, {payload}) => payload.result,
    }),

    getDspTemplateImages: createReducer([], {
        [modelActions.getDspTemplateImagesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getDspTemplateById: createReducer({}, {
        [modelActions.getDspTemplateByIdSuccess]:(_, {payload}) => payload.result,
    }),

    getClientDistributionList: createReducer([], {
        [modelActions.getClientDistributionListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getDistributionTypes: createReducer([], {
        [modelActions.getDistributionTypesSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getDspSubscriptions: createReducer([], {
        [modelActions.getDspSubscriptionsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    initDistribution: createReducer({}, {
        [modelActions.initDistributionSuccess]:(_, {payload}) => payload.result,
    }),

    getMailsFromDistribution: createReducer({}, {
        [modelActions.getMailsFromDistributionSuccess]:(_, {payload}) => payload.result,
    }),

    getClientMail: createReducer({}, {
        [modelActions.getClientMailSuccess]:(_, {payload}) => payload.result,
    }),

    getSystemDistributionList: createReducer([], {
        [modelActions.getSystemDistributionListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getDistributionSystemFunctions: createReducer([], {
        [modelActions.getDistributionSystemFunctionsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getTemplatesDistribution: createReducer([], {
        [modelActions.getTemplatesDistributionSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getSystemDistributionById: createReducer({}, {
        [modelActions.getSystemDistributionByIdSuccess]:(_, {payload}) => payload.result,
    }),

    getMailsWithFilter: createReducer({}, {
        [modelActions.getMailsWithFilterSuccess]:(_, {payload}) => payload.result,
    }),

    getProductsMarketplace: createReducer({}, {
        [modelActions.getProductsMarketplaceSuccess]:(_, {payload}) => payload.result,
    }),

    getDefaultSettingsDiscount: createReducer({}, {
        [modelActions.getDefaultSettingsDiscountSuccess]:(_, {payload}) => payload.result,
    }),

    getRatesManagment: createReducer({}, {
        [modelActions.getRatesManagmentSuccess]:(_, {payload}) => payload.result,
    }),

    getMangmentRatesList: createReducer([], {
        [modelActions.getMangmentRatesListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getExchangeAddress: createReducer({}, {
        [modelActions.getExchangeAddressSuccess]:(_, {payload}) => payload.result,
    }),

    getExchangeAddressTransactions: createReducer([], {
        [modelActions.getExchangeAddressTransactionsSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getProductSettingsDiscount: createReducer({}, {
        [modelActions.getProductSettingsDiscountSuccess]:(_, {payload}) => payload.result,
    }),

    getPartnerByIdSaitSettings,
    getPartnersSaitSettings,
    getContactsAndSocialSaitSettings,
    
    getMainSaitSettings,
    getVisibleGroupNameErr,
    getVisibleGroupName,
    getWidgetRecords,
    getAllProductsInStock,
    getFeedbackList,
    getFeedbackShortInfo,
    getNotLinkedCatalogs,
    getSpecialProductsNotLinkedProduct,
    getItemListNotLinkGroup,
    pageListResult,
    saitListResult,
    getTranslatorResult,
    searchGetRequestsResult,
    searchGetLinksResult,
    getWidgetsSchemeResult,
    getWidgetSchemeById,
    getWidgetSchemeWithWidgets,
    getKeywordLink,
    getKeywordList,
    getKeyword,
    getWidgetList,
    getWidgetList2,
    getItemsListWidgetQty,
    getItemById,
    getGallariesListWidgetQty,
    getGallariesListWidgetQtyItemGallery,
    getGallaryByID,
    getWidgetById,
    getItemGroupList,
    getItemListSortGroup,
    getWidgetConvertationList,
    getItemGroupTypes,
    getWidgetRecordList,
    getWidgetRecordById,
    getItemGroupById,
    getWidgetGroupType,
    getWidgetsNoSchemaWithType,
    getLinkedWidgets,
    getSchemeByProduct,
    getSchemeByItemList,
    getItemWidgetsPricing,
    updateItemPricingWidget,
    getItemsPricing,
    updateItemPricing,
    removeWidgetRecord,
    updateItemPackingWidget,
    convertPrice,
    updateItemPackingPricing,
    updateItemTypePricing,
    updateItemStartPricing,
    getWidgetTypes,
    getPropertiesByProduct,
    getProductListWithOptions,
    getProductListWithOptionsAndPrices,
    updatePriceProductOrOption,
    getLocations,
    getLocationById,
    getWidgetsListNotCatalog,
    getCatalogsList,
    linkUnlinkWidgetToCatalog,
    getProductsInStock,
    getLocationShortList,
    getProductsInStockNotLocation,
    initProductInLocation,
    getProductBarcodesList,
    addProductBarcode,
    moveProductToLocation,
    getLocationsWithProductsQty,
    getMovementProductHistory,
    removeImageFromGallery,
    getProductFromLucene,
    getAttributesByProduct,
    getWidgetsNoAttributesProduct,
    getProductCategories,
    getAttributesByGroupAndProduct,
    getPricingAttributesByProduct,
    addPricingAttributeByProduct,
    getMeasures,
    getMeasureById,
    getItemListLinkGroup,
    getSpecialProductsForProduct,
    getLinkedCatalogs
})
import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';

import Routers from './routers';
import store from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

export default function App() {
  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <Routers />
      </PersistGate>
    </Provider>
  );
}


import React from 'react';
import { Route } from 'react-router-dom';


const AppRoute = ({ children }) => (                       
    <>
      {children}                                     
    </>
);  
  
export default ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={matchProps => (
        <AppRoute>
          <Component {...matchProps} />
        </AppRoute>
      )} />
    )
  };
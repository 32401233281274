import { connect } from "react-redux";
import Sidebar from "../Sidebar";
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import { useEffect, useState } from "react";
import moment from "moment";
import PopupOrder from "./PopupOrder";
import PopupInfoDelivery from "./PopupInfoDelivery";
import { privateUserOperations } from "../../redux/privateUser";
import PriceToken from "../../components/PriceToken/PriceToken";
import { configSelectors } from "../../redux/config";


const Order = ({
    lang,
    currency,
    onGetUserOrdersHistory,
    getUserOrdersHistory,
    getIsAuthenticated
}) => {

    const [popupOrder, setPopupOrder] = useState(false);
    const [popupInfoDelivery, setPopupInfoDelivery] = useState(false);
    const [orderId, setOrderId] = useState(0)
    const [currentRate, setCurrentRate] = useState('')

    useEffect(()=>{
        if(getIsAuthenticated)
        onGetUserOrdersHistory({DOMAIN, DEFAULT_COLUMN:lang})
    }, [getIsAuthenticated])

    return (
        <>
        <div className="container-fluid">

            <div className=" p-2 px-3">
                <h1>Orders</h1>
            </div>

            <div className="row mobile-view">
                <Sidebar/>
                <div className="col-md-9 form-parent">

                    {getUserOrdersHistory.length === 0 && <h3 className="text-center my-5">You dont have any orders</h3>}
                    {getUserOrdersHistory.length !== 0 &&
                        getUserOrdersHistory.map(({
                            ID_ORDER,
                            CREATE_DATE,
                            ID_PAYMENT_SYSTEM,
                            PAYMENT_SYSTEMS,
                            AMOUNT,
                            DISCOUNT,
                            TOTAL_TO_PAY,
                            PRODUCTS_QTY,
                            DELIVERY_STATUS,
                            ORDER_STATUS,
                            LOYALTY_CASHBACK,
                            LOYALTY_DISCOUNT
                        }, k)=>{
                            const getPayment = PAYMENT_SYSTEMS.find(({ID_RECORD})=> ID_RECORD===ID_PAYMENT_SYSTEM)
                            // const CURRENCY_SHORT_NAME = getPayment?.CURRENCY_SHORT_NAME
                            const CURRENCY_SHORT_NAME = currency
                            const paymentName =getPayment?.NAME

                            // const amount = AMOUNT.find(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).PRICE
                            // const discount = DISCOUNT.find(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).PRICE
                            // const totalToPay = TOTAL_TO_PAY.find(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).PRICE
                            // const loyaltyDiscountValue = LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === CURRENCY_SHORT_NAME)?.VALUE
                            // const loyaltyDiscountPoint = LOYALTY_DISCOUNT?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE

                            // const pointsCashbackValue = LOYALTY_CASHBACK?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === CURRENCY_SHORT_NAME)?.VALUE
                            // const pointsCashbackPoint = LOYALTY_CASHBACK?.find(({RATE_SHORT_NAME}) => RATE_SHORT_NAME === 'TOKEN')?.VALUE


                            // const amount = Number(AMOUNT.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).map(({PRICE})=>PRICE).join(' ')) || 0
                            // const amountToken = Number(AMOUNT.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({PRICE})=>PRICE).join(' ')) || 0
                            const discount = Number(DISCOUNT.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).map(({PRICE})=>PRICE).join(' ')) || 0
                            const totalToPay = Number(TOTAL_TO_PAY.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).map(({PRICE})=>PRICE).join(' ')) || 0
                            
                            const loyaltyDiscountValue = Number(LOYALTY_DISCOUNT.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).map(({VALUE})=>VALUE).join(' ')) || 0
                            const loyaltyDiscountPoint = Number(LOYALTY_DISCOUNT.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({VALUE})=>VALUE).join(' ')) || 0
                            const pointsCashbackValue = Number(LOYALTY_CASHBACK.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === CURRENCY_SHORT_NAME).map(({VALUE})=>VALUE).join(' ')) || 0
                            const pointsCashbackPoint = Number(LOYALTY_CASHBACK.filter(({RATE_SHORT_NAME})=>RATE_SHORT_NAME === 'TOKEN').map(({VALUE})=>VALUE).join(' ')) || 0
                            
                            
                            return(<div key={k} className="order mb-3">
                            <div className="order-header d-flex" style={{justifyContent:'space-between'}}>
                                {/* <div className="d-flex" > */}
                                    <div className="mr-3">
                                        <div className="f-weight-5">Create Date</div>
                                        <div>{moment.unix(CREATE_DATE).format('DD MMM YYYY HH:mm')}</div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">Order amount</div>
                                        {/* <div> {amountToken} ({CURRENCY_SHORT_NAME} {!isNaN(amount) && Number(amount.toFixed(2))})</div> */}
                                        <div> <PriceToken price={AMOUNT} /></div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">D<span style={{color:'#4572C2'}}>i</span>scount</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(discount.toFixed(2))}</div>
                                    </div>
                                    <div className="mr-3">
                                        <div className="f-weight-5">Total to pay</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(totalToPay.toFixed(2))}</div>
                                    </div>

                                    {/* <div className="mr-3">
                                        <div className="f-weight-5">Loyalty discount</div>
                                        <div> {CURRENCY_SHORT_NAME} { Number(loyaltyDiscountValue.toFixed(2))} (Points {Number(loyaltyDiscountPoint.toFixed(2))})</div>
                                    </div> */}
                                    <div className="mr-3">
                                        <div className="f-weight-5">Reward</div>
                                        {/* <div> {CURRENCY_SHORT_NAME} { Number(pointsCashbackValue.toFixed(2))} (Points {Number(pointsCashbackPoint.toFixed(2))})</div> */}
                                        <div> <PriceToken price={LOYALTY_CASHBACK} type="VALUE"/> </div>
                                    </div>
                                {/* </div> */}
                                <div  className="f-weight-5">Order#{ID_ORDER}</div>
                            </div>
                            <div className="order-body">
                                <div className="d-flex" style={{justifyContent:'space-between'}}>
                                        <div className="d-flex" >
                                            <div className="mr-3 infoStatus">Products QTY: <span>{PRODUCTS_QTY}</span> <span className="pointer" onClick={()=>{setOrderId(ID_ORDER); setCurrentRate(CURRENCY_SHORT_NAME); setPopupOrder(true)}}>(see products)</span></div>
                                        </div>
                                        <div>
                                        {ORDER_STATUS === 0 &&  <span className="statusOrder"><span style={{color:'#4572C2'}}>i</span>n progress</span>}
                                        {ORDER_STATUS === 1 &&  <span className="statusOrder">Not pa<span style={{color:'#4572C2'}}>i</span>d</span>}
                                        {ORDER_STATUS === 2 &&  <span className="statusOrder" style={{backgroundColor:'green'}}>Pa<span style={{color:'#4572C2'}}>i</span>d</span>}
                                        {ORDER_STATUS === 6 &&  <span className="statusOrder">Need conf<span style={{color:'#4572C2'}}>i</span>rmat<span style={{color:'#4572C2'}}>i</span>on</span>}
                                        {ORDER_STATUS === 7 &&  <span className="statusOrder">Canceled by user</span>}
                                        {ORDER_STATUS === 8 &&  <span className="statusOrder">Exp<span style={{color:'#4572C2'}}>i</span>red</span>}
                                        {ORDER_STATUS === 9 &&  <span className="statusOrder">Refunded</span>}
                                            
                                        </div>
                                </div>
                                <div className="d-flex pt-2">
                                    <div className="mr-3 infoStatus">Payment method: <span>{paymentName}</span></div>
                                    <div className="mr-3 infoStatus">Del<span style={{color:'#4572C2'}}>i</span>very status: {' '}
                                        
                                        {DELIVERY_STATUS === 0 && <span >New order</span>}
                                        {DELIVERY_STATUS === 1 && <span >Pack<span style={{color:'#4572C2'}}>i</span>ng</span>}
                                        {DELIVERY_STATUS === 2 && <span className="pointer" onClick={()=>{setOrderId(ID_ORDER); setCurrentRate(CURRENCY_SHORT_NAME); setPopupInfoDelivery(true)}}>On del<span style={{color:'#4572C2'}}>i</span>very</span>}
                                        {DELIVERY_STATUS === 3 && <span className="pointer" onClick={()=>{setOrderId(ID_ORDER); setCurrentRate(CURRENCY_SHORT_NAME); setPopupInfoDelivery(true)}}>Del<span style={{color:'#4572C2'}}>i</span>vered</span>}

                                        
                                    </div>
                                    <div className="mr-3 infoStatus">Order status: {' '}
                                    <span>
                                        {ORDER_STATUS === 0 && 'In progress'}
                                        {ORDER_STATUS === 1 && 'Not paid'}
                                        {ORDER_STATUS === 2 && 'Paid'}
                                        {ORDER_STATUS === 6 && 'Need confirmation'}
                                        {ORDER_STATUS === 7 && 'Canceled by user'}
                                        {ORDER_STATUS === 8 && 'Expired'}
                                        {ORDER_STATUS === 9 && 'Refunded'}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        })
                    }
                
            
                </div>
            </div>
        </div>
        {popupOrder && <PopupOrder orderId={orderId} currentRate={currentRate} closePopup={()=>setPopupOrder(false)} />}
        {popupInfoDelivery && <PopupInfoDelivery orderId={orderId} currentRate={currentRate} closePopup={()=>setPopupInfoDelivery(false)}  /> }
        </>
    )
}
const mapStateToProps = (state) => ({
    lang:state.config.lang,
    currency:state.config.currency,
    errCode:state.config.errCode,
    getUserOrdersHistory:state.privateUser.getUserOrdersHistory,
    getIsAuthenticated:configSelectors.getIsAuthenticated(state),

})

const mapDispatchToProps = {
    // onProceedToCheckout:userPrivateOperations.proceedToCheckout,
    onGetUserOrdersHistory:privateUserOperations.getUserOrdersHistory,
}
export default connect(mapStateToProps, mapDispatchToProps)(Order);
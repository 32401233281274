import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import publicUserActions from './publicUser-actions';
import { privateUserActions } from '../privateUser';


const arrList = (state, {payload}) =>{
    const arrItemId =  [...payload.result.content.map(({ID_ITEM}) => ID_ITEM)]
    const arr = [...state.filter(({ID_ITEM}) => [...arrItemId].indexOf(ID_ITEM) === -1)]
    
    return [...arr, ...payload.result.content]
}

export default combineReducers({

    // getOrdersHistory: createReducer([], {
    //     [publicUserActions.getOrdersHistorySuccess]:(_, {payload}) => payload.result['#result-set-1'],
    // }),
    // getLegalInfoSaitSettingsList: createReducer({}, {
    //     [publicUserActions.getLegalInfoSaitSettingsListSuccess]:(_, {payload}) => payload.result,
    // }),

    initWebSait: createReducer({}, {
        [publicUserActions.initWebSaitSuccess]:(_, {payload}) => payload.result,
    }),

    getCatalogsList : createReducer([], {
        [publicUserActions.getCatalogsListSuccess]:(_, {payload}) => payload.result['#result-set-1'],
    }),

    getProductsByGroup : createReducer({}, {
        [publicUserActions.getProductsByGroupSuccess]:(_, {payload}) => payload.result,
    }),

    getProducts : createReducer({}, {
        [publicUserActions.getProductsSuccess]:(_, {payload}) => payload.result,
        // [modelActions.getProductFromLuceneSuccess]:() => {}
    }),

    marketplaceGetVendorsList : createReducer({}, {
        [publicUserActions.marketplaceGetVendorsListSuccess]:(_, {payload}) => payload.result,
    }),
    
    marketplaceGetVendorsListList : createReducer([], {
        [publicUserActions.marketplaceGetVendorsListSuccess]:arrList
    }),

    getMarketplaceProductsSpecialOffers : createReducer({}, {
        [publicUserActions.getProductsWithFilterRequest]:() => {return{}},
        [publicUserActions.getProductsWithFilterSuccess]:(_, {payload}) => payload.result,
        [publicUserActions.getMarketplaceProductsSpecialOffersSuccess]:(_, {payload}) => payload.result,
    }),

    getMarketplaceProductsSpecialOffersList : createReducer([], {
        [publicUserActions.getProductsWithFilterRequest]:() => [],
        [publicUserActions.getProductsWithFilterSuccess]:arrList,
        [publicUserActions.getMarketplaceProductsSpecialOffersSuccess]:arrList
    }),

    getMarketplaceProductsNft : createReducer({}, {
        [publicUserActions.getMarketplaceProductsNftSuccess]:(_, {payload}) => payload.result,
    }),

    getMarketplaceProductsNftList : createReducer([], {
        [publicUserActions.getMarketplaceProductsNftSuccess]:arrList
    }),


    marketplaceGetProductById : createReducer({}, {
        [publicUserActions.marketplaceGetProductByIdSuccess]:(_, {payload}) => payload.result,
        [privateUserActions.getProductByPricingSuccess]:(_, {payload}) => payload.result,
        [publicUserActions.getProductByPricingParametersSuccess]:(_, {payload}) => payload.result,
    }),

    getFeedbackList : createReducer({}, {
        [publicUserActions.getFeedbackListSuccess]:(_, {payload}) => payload.result,
    }),

    getProductsByCatalog: createReducer({}, {
        [publicUserActions.getProductsByCatalogSuccess]:(_, {payload}) => payload.result,
    }),

    getProductsSearchByNameCatalog: createReducer({}, {
        [publicUserActions.getProductsSearchByNameCatalogSuccess]:(_, {payload}) => payload.result,
    }),

    getMarketplaceProductsNotNft : createReducer({}, {
        [publicUserActions.getMarketplaceProductsNotNftSuccess]:(_, {payload}) => payload.result,
    }),

    getMarketplaceProductsNotNftList : createReducer([], {
        [publicUserActions.getMarketplaceProductsNotNftSuccess]:arrList
    }),

    getLegalInfoById : createReducer({}, {
        [publicUserActions.getLegalInfoByIdSuccess]:(_, {payload}) => payload.result,
    }),

    getLegalInfoByType : createReducer({}, {
        [publicUserActions.getLegalInfoByTypeSuccess]:(_, {payload}) => payload.result,
    }),

    vendorInfoById : createReducer({}, {
        [publicUserActions.vendorInfoByIdSuccess]:(_, {payload}) => payload.result,
    }),

})
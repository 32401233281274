import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import 'jquery';
// import Popper from 'popper.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

import './asstets/css/bootstrap.min.css'
import './asstets/font-awesome/css/font-awesome.css'


import './index.css';
import './asstets/all.css'

import 'flag-icon-css/css/flag-icons.min.css'
import ScrollToTop from './components/ScrollToTop';

ReactDOM.render(<BrowserRouter><ScrollToTop/><App /></BrowserRouter>, document.querySelector('#root'));

import { useState } from "react";
import { useEffect } from "react"
import { connect } from "react-redux";
import { WEB_PICTURES_FILE, WEB_PICTURES_FILE_RESIZER } from "../../config/Url";
import { configActions } from "../../redux/config";
import InputPass from "../InputPass/InputPass";
// import { authActions } from "../../redux/auth";
// import {WEB_PICTURES_FILE_RESIZER} from './../../config/Url'
// import Button from "./../Button";

import Popup from './../Popup'


const PopupAlert = ({
    onPinCodeStatus,
    pinCodeStatus,
    initWebSait
}) => {

    const [popupAlert, setPopupAlert] = useState(false)

    const [pin, setPin] = useState('');
    const [pinCode, setPinCode] = useState('')

    useEffect(()=>{
       
        // document.body.style.overflow = 'hidden';
        // return () => {
        //     document.body.style.overflow = 'auto';
        // }
    }, [])

    useEffect(()=>{
        console.log({pin});
    }, [pin])


    useEffect(()=>{
        // console.log({initWebSait});
        if(Object.keys(initWebSait).length !== 0){
            setPinCode(initWebSait?.MAIN_INFO?.PIN_CODE)
            if(initWebSait?.MAIN_INFO?.VALIDATE_AGE === 0)
                onPinCodeStatus(1)
            //     setPopupAlert(false)
            // }else if(initWebSait?.MAIN_INFO?.VALIDATE_AGE === 1){
            //     setPopupAlert(true)
            // }
        }
    }, [initWebSait])

    useEffect(()=>{
        if(pinCodeStatus === 1){
            setPopupAlert(false)
            return
        }

        setPopupAlert(true)
    }, [pinCodeStatus])

    const yes = () =>{

        if(initWebSait?.MAIN_INFO?.PIN_CODE_STATUS === 0){
            onPinCodeStatus(1) 
            return;
        }

        if(pin === pinCode) 
            onPinCodeStatus(1) 
        else 
            onPinCodeStatus(2)
    }

    const no = () =>{
        onPinCodeStatus(2)
    }

    const back = () => {
        onPinCodeStatus(-1)
    }

    const handlePin = ({target:{value}}) => {
        const val = value.replace(/●/gi, '')
        if(val.length === 0)
            setPin(prevState => (prevState.length === 0 ? prevState : prevState.slice(0, prevState.length - 1)))
        else
            setPin(prevState => (prevState + value.replace(/●/gi, '')))
    }

    return (

        

        <>
        {initWebSait?.MAIN_INFO?.VALIDATE_AGE === 1 && <>
        {popupAlert && 
        <Popup 
            closePopup={()=>setPopupAlert(false)} 
            backdrop={false}
            backgroundImage={`${WEB_PICTURES_FILE_RESIZER}${initWebSait?.MAIN_INFO?.VALIDATE_AGE_BACKGROUND}/1200`}
            backgroundColorDialog={initWebSait?.MAIN_INFO?.AGE_VALIDATION_BG_POUP}
        >
            {pinCodeStatus === -1 &&
                <>
                    <div className="text-center pt-3 pb-5">
                        <img src={`${WEB_PICTURES_FILE}${initWebSait?.MAIN_INFO?.LOGO_HEADER}`} width="377" height="139" style={{objectFit: 'contain'}} />
                    </div>
                    <div className="text-center pb-3 mt-4" >
                        <h2 className="font-weight-normal">Are you over 18 years of age?</h2>
                    </div>
                    {initWebSait?.MAIN_INFO?.PIN_CODE_STATUS === 1 &&
                        <div className="my-3 mx-3 ">
                            {/* <label for="exampleFormControlInput1" className="form-label"></label> */}
                            <input 
                                type="text" 
                                name="pin" 
                                className="form-control text-center" 
                                autoComplete="nope" 
                                placeholder="PIN" 
                                value={Array.from({length:pin.length}).map((i)=>('●')).join('')} 
                                onChange={handlePin} 
                                // onChange={({target:{value}})=>setPin(value)} 
                                style={{width: 200, margin:'0 auto'}} />
                            {/* <InputPass 
                                name="pin"
                                className="form-control text-center" 
                                autoComplete="nope" 
                                placeholder="PIN" 
                                value={pin} 
                                onPin={handlePin} 
                                style={{width: 200, margin:'0 auto'}} 
                             /> */}
                        </div>
                    }
                    <div className="text-center mb-3">
                        <button className="button" onClick={()=>yes()}>Yes</button>
                        <button className="button-line-b" style={{marginLeft:22}} onClick={()=>no()} >No</button>
                    </div>
                </>
            }

            {pinCodeStatus === 2 &&
                <>
                    <h2 className="text-center my-5">Access denied</h2>
                    <div className="text-center">
                        <button className="button" onClick={()=>back()}>Back</button>
                    </div>
                    <br /> 
                    <br /> 
                </>
            }
        </Popup>}
        </>}
        {/* {initWebSait?.MAIN_INFO?.VALIDATE_AGE === 1 && 
        <div className="popup-cover-div" style={{position: 'fixed', zIndex: 1000000000, top: 0, left: 0, backgroundImage:`url(${WEB_PICTURES_FILE_RESIZER}${initWebSait?.MAIN_INFO?.VALIDATE_AGE_BACKGROUND}/1200)`}}>
        <div className="modal fade show" id="exampleModal-two" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{overflow: 'auto', display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-content" style={{backgroundColor:initWebSait?.MAIN_INFO?.AGE_VALIDATION_BG_POUP === '' ? '#fff' : initWebSait?.MAIN_INFO?.AGE_VALIDATION_BG_POUP}}>
                    <div className="modal-body">
                        <div className="text-center pt-3 pb-5">
                            <img src={`${WEB_PICTURES_FILE_RESIZER}${initWebSait?.MAIN_INFO?.LOGO_HEADER}/400`} width="377" height="139" style={{objectFit: 'contain'}} />
                           

                        </div>
                        {yearsAge18 === -1 &&
                            <>
                                <div className="text-center pb-4">
                                    <h3 className="font-weight-normal">Are you over 18 years of age?</h3>
                                </div>
                                <div className="text-center" >
                                    <Button onClick={()=>yes()} line>Yes</Button>
                                    <Button onClick={()=>setYearsAge18(2)} style={{marginLeft:12}} line>No</Button>
                
                                </div>
                               
                                {initWebSait?.MAIN_INFO?.PIN_CODE_STATUS === 1 &&
                                    <div className="text-center pt-4 pb-4">
                                        <input className="form-control top-search-input" autoComplete="off" type="password" value={pin} onChange={({target:{value}})=>setPin(value)} style={{textAlign:'center', margin: '0 auto'}} />
                                    </div>
                                }
                            </>
                        }
                        {yearsAge18 === 2 &&
                            <>
                                <h3 style={{textAlign:'center'}}>Access denied</h3>
                                <div className="text-center">
                                    <span className="popup-text-size btn" onClick={()=>setYearsAge18(-1)}>Back</span>
                                </div>
                                <br /> 
                                <br /> 
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    } */}
    </>
    ) 
}
const mapStateToProps = (state) => ({
    // lang:state.lang.LANG,
    // errCode:state.model.err_code,
    // yearsAge18:state.auth.yearsAge18,
    // initWebSait:state.user.initWebSait,
    pinCodeStatus:state.config.pinCodeStatus,
    initWebSait:state.publicUser.initWebSait,
})
const mapDispatchToProps = {
    onPinCodeStatus:configActions.pinCodeStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupAlert);

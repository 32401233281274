import axios from 'axios';
import { authOperations } from '../auth';
// import { useSelector } from 'react-redux';
// import { authSelectors } from '../auth';
import modelActions from './model-actions';


const url = 'https://whellli.com:8444/api/private/portal/wpa';
const resetToken = (state, dispatch) => {
    if(Boolean(state().auth.token)){
        // axios.defaults.headers.common.Authorization = `Bearer ${state().auth.token}`;

        axios.interceptors.request.use(config => {
            dispatch(modelActions.loader(true))
            if(state().auth.token !== null)
                config.headers.Authorization = `Bearer ${state().auth.token}`;
            else
                config.headers.Authorization = ``;
            return config;
        
        }, error => {
            dispatch(modelActions.loader(false))
            return Promise.reject(error);
        });
        
        axios.interceptors.response.use(response => {
            dispatch(modelActions.loader(false))

            if([401, 400, 403].indexOf(response?.status) !== -1){
                dispatch(authOperations.logout());
                window.location.href = "/"
            }
            return response;
        }, error => {
            dispatch(modelActions.loader(false))
            if([401, 400, 403].indexOf(error?.response?.status) !== -1){
                dispatch(authOperations.logout());
                window.location.href = "/"
            }
            return Promise.reject(error);
        });
    }
}

const getAppPagesList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getAppPagesListRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_APP_PAGES_LIST_WITH_TYPE",
            parameters:credential
        });
        dispatch(modelActions.getAppPagesListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getAppPagesListError(error));
    }
}

const getSaitList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getSaitListRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_SAIT_PAGES_LIST_WITH_TYPE",
            parameters:credential
        });
        dispatch(modelActions.getSaitListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getSaitListError(error));
    }
}

const getTranslator = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getTranslatorRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_TRANSLATOR",
            parameters:credential
        });
        dispatch(modelActions.getTranslatorSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getTranslatorError(error));
    }
}

const updatePage = (name, credential) => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updatePageRequest());

    try{
        const res = await axios.post(url, {
            name:name,
            parameters:credential
        });
        dispatch(modelActions.updatePageSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updatePageError(error));
    }
}

const updateTranslator = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateTranslatorRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_TRANSLATOR",
            parameters:credential
        });
        dispatch(modelActions.updateTranslatorSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateTranslatorError(error));
    }
}

const removeTranslator = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.removeTranslatorRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_REMOVE_TRANSLATOR",
            parameters:credential
        });
        dispatch(modelActions.removeTranslatorSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeTranslatorError(error));
    }
}


const searchGetRequestsList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.searchGetRequestsRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_SEARCH_GET_REQUESTS_LIST",
            parameters:credential
        });
        dispatch(modelActions.searchGetRequestsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.searchGetRequestsError(error));
    }
}

const searchGetLinksList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.searchGetLinkRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_SEARCH_GET_LINKS_LIST",
            parameters:credential
        });
        dispatch(modelActions.searchGetLinkSuccess(res.data));

    }catch (error){
        dispatch(modelActions.searchGetLinkError(error));
    }
}


const getWidgetsSchemeList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetsSchemeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widgets_scheme_list",
            parameters:credential
        });
        dispatch(modelActions.getWidgetsSchemeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetsSchemeError(error));
    }
}

const createUpdateWidgetScheme = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateWidgetSchemeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_update_widget_scheme",
            parameters:credential
        });
        dispatch(modelActions.createUpdateWidgetSchemeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateWidgetSchemeError(error));
    }
}

const getWidgetSchemeById = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetSchemeByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widget_scheme_by_id",
            parameters:credential
        });
        dispatch(modelActions.getWidgetSchemeByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetSchemeByIdError(error));
    }
}

const getWidgetSchemeWithWidgets = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetSchemeWithWidgetsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widget_scheme_with_widgets",
            parameters:credential
        });
        dispatch(modelActions.getWidgetSchemeWithWidgetsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetSchemeWithWidgetsError(error));
    }
}

const getKeywordLink = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getKeywordLinkRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_KEYWORD_LINK",
            parameters:credential
        });
        dispatch(modelActions.getKeywordLinkSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getKeywordLinkError(error));
    }
}

const createUpdateKeyword = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateKeywordRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_KEYWORD_LINK",
            parameters:credential
        });
        dispatch(modelActions.createUpdateKeywordSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateKeywordError(error));
    }
}


const getKeywordsList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getKeywordsRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_KEYWORDS_LIST",
            parameters:credential
        });
        dispatch(modelActions.getKeywordsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getKeywordsError(error));
    }
}


const getKeyword = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getKeywordRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_KEYWORD",
            parameters:credential
        });
        dispatch(modelActions.getKeywordSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getKeywordError(error));
    }
}

const createUpdateKey = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateKeyRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_KEYWORD",
            parameters:credential
        });
        dispatch(modelActions.createUpdateKeySuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateKeyError(error));
    }
}

const removeKeyword = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.removeKeywordRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_REMOVE_KEYWORD",
            parameters:credential
        });
        dispatch(modelActions.removeKeywordSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeKeywordError(error));
    }
}

const getWidgetList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetListRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_WIDGET_LIST",
            parameters:credential
        });
        dispatch(modelActions.getWidgetListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetListError(error));
    }
}

const getItemsListWidgetQty = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemsListWidgetRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_ITEMS_LIST_WIDGET_QTY",
            parameters:credential
        });
        dispatch(modelActions.getItemsListWidgetSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemsListWidgetError(error));
    }
}

const updateItemStatus = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemStatusRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_status",
            parameters:credential
        });
        dispatch(modelActions.updateItemStatusSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemStatusError(error));
    }
}

const getItemById = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_ITEM_BY_ID",
            parameters:credential
        });
        dispatch(modelActions.getItemByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemByIdError(error));
    }
}

const createUpdateItem = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateItemRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_ITEM",
            parameters:credential
        });
        dispatch(modelActions.createUpdateItemSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateItemError(error));
    }
}

const getGallariesListWidgetQty = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getGallariesListWidgetQtyRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_GALLARIES_LIST_WIDGET_QTY",
            parameters:credential
        });
        dispatch(modelActions.getGallariesListWidgetQtySuccess(res.data));

    }catch (error){
        dispatch(modelActions.getGallariesListWidgetQtyError(error));
    }
}

const getGallaryByID = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getGallaryByIDRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_GALLARY_BY_ID",
            parameters:credential
        });
        dispatch(modelActions.getGallaryByIDSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getGallaryByIDError(error));
    }
}

const createUpdateGallary = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateGallaryRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_GALLARY",
            parameters:credential
        });
        dispatch(modelActions.createUpdateGallarySuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateGallaryError(error));
    }
}

const updateWidgetActive = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateWidgetActiveRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_UPDATE_WIDGET_ACTIVE",
            parameters:credential
        });
        dispatch(modelActions.updateWidgetActiveSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateWidgetActiveError(error));
    }
}

const getWidgetById = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_WIDGET_BY_ID",
            parameters:credential
        });
        dispatch(modelActions.getWidgetByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetByIdError(error));
    }
}

const createUpadetWidget = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpadetWidgetRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_WIDGET",
            parameters:credential
        });
        dispatch(modelActions.createUpadetWidgetSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpadetWidgetError(error));
    }
}

const getItemGroupList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemGroupListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_groups_list",
            parameters:credential
        });
        dispatch(modelActions.getItemGroupListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemGroupListError(error));
    }
}

const createItemGroup = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createItemGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_item_group",
            parameters:credential
        });
        dispatch(modelActions.createItemGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createItemGroupError(error));
    }
}

const remoevItemGroup = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.remoevItemGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_item_group",
            parameters:credential
        });
        dispatch(modelActions.remoevItemGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.remoevItemGroupError(error));
    }
}

const getItemListSortGroup = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemListSortGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_list_sort_group",
            parameters:credential
        });
        dispatch(modelActions.getItemListSortGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemListSortGroupError(error));
    }
}

const linkUnlinkItemGroup = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.linkUnlinkItemGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_link_unlink_item_group",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.linkUnlinkItemGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.linkUnlinkItemGroupError(error));
    }
}


const removeWidget = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.removeWidgetRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_REMOVE_WIDGET",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.removeWidgetSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeWidgetError(error));
    }
}

const getWidgetConvertationList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetConvertationListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widget_convertations_list",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.getWidgetConvertationListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetConvertationListError(error));
    }
}

const getItemGroupTypes = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.GetItemGroupTypesRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_group_types",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.GetItemGroupTypesSuccess(res.data));

    }catch (error){
        dispatch(modelActions.GetItemGroupTypesError(error));
    }
}

const createUpdateItemGroup = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateItemGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_update_item_group",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.createUpdateItemGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateItemGroupError(error));
    }
}

const getWidgetRecordList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetRecordListRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_WIDGET_RECORD_LIST",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.getWidgetRecordListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetRecordListError(error));
    }
}

const getWidgetRecordById = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetRecordByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_GET_WIDGET_RECORD_BY_ID",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.getWidgetRecordByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetRecordByIdError(error));
    }
}

const createUpdateWidgetRecord = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateWidgetRecordRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_CREATE_UPDATE_WIDGET_RECORD",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.createUpdateWidgetRecordSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateWidgetRecordError(error));
    }
}


const changeLevelItemInGroup = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.changeLevelItemInGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_change_level_item_in_group",
            parameters:credential //{ID_GROUP: 2, ID_ITEM: 1, STATUS: 1, LEVEL: 3}
        });
        dispatch(modelActions.changeLevelItemInGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.changeLevelItemInGroupError(error));
    }
}

const getItemGroupById = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemGroupByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_group_by_id",
            parameters:credential
        });
        dispatch(modelActions.getItemGroupByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemGroupByIdError(error));
    }
}

const getWidgetGroupType = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetGroupTypeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widget_group_types",
            parameters:credential
        });
        dispatch(modelActions.getWidgetGroupTypeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetGroupTypeError(error));
    }
}

const getWidgetsNoSchemaWithType = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetsNoSchemaWithTypeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widgets_no_scheme_with_type",
            parameters:credential
        });
        dispatch(modelActions.getWidgetsNoSchemaWithTypeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetsNoSchemaWithTypeError(error));
    }
}

const getWidgetsNoPropertiesProduct = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetsNoPropertiesProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widgets_no_properties_product",
            parameters:credential
        });
        dispatch(modelActions.getWidgetsNoPropertiesProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetsNoPropertiesProductError(error));
    }
}

const linkWidgetToWidgetScheme = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.linkWidgetToWidgetSchemeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_link_widget_to_widget_scheme",
            parameters:credential
        });
        dispatch(modelActions.linkWidgetToWidgetSchemeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.linkWidgetToWidgetSchemeError(error));
    }
}

const addPropertiesWidgetByProduct = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.addPropertiesWidgetByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_add_properties_widget_by_product",
            parameters:credential
        });
        dispatch(modelActions.addPropertiesWidgetByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.addPropertiesWidgetByProductError(error));
    }
}

const getLinkedWidgets = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getLinkedWidgetsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_linked_widgets",
            parameters:credential
        });
        dispatch(modelActions.getLinkedWidgetsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getLinkedWidgetsError(error));
    }
}


const updateWidgetLevelInWidgetScheme = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateWidgetLevelInWidgetSchemeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_widget_level_in_widget_scheme",
            parameters:credential
        });
        dispatch(modelActions.updateWidgetLevelInWidgetSchemeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateWidgetLevelInWidgetSchemeError(error));
    }
}

const linkProductToWidgetScheme = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.linkProductToWidgetSchemeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_link_product_to_widget_scheme",
            parameters:credential
        });
        dispatch(modelActions.linkProductToWidgetSchemeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.linkProductToWidgetSchemeError(error));
    }
}


const getSchemeByProduct = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getSchemeByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_scheme_by_product",
            parameters:credential
        });
        dispatch(modelActions.getSchemeByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getSchemeByProductError(error));
    }
}


const updateItemProperties = credential => async (dispatch, state) => {

    // console.log(credential);
    // return;

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemPropertiesRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_properties",
            parameters:credential
        });
        dispatch(modelActions.updateItemPropertiesSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemPropertiesError(error));
    }
}

const getSchemeByItemList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getSchemeByItemListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_schemes_by_items_list",
            parameters:credential
        });
        dispatch(modelActions.getSchemeByItemListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getSchemeByItemListError(error));
    }
}

const getItemWidgetsPricing = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemWidgetsPricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_widgets_pricing",
            parameters:credential
        });
        dispatch(modelActions.getItemWidgetsPricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemWidgetsPricingError(error));
    }
}

const updateItemPricingWidget = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemPricingWidgetRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_pricing_widget",
            parameters:credential
        });
        dispatch(modelActions.updateItemPricingWidgetSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemPricingWidgetError(error));
    }
}

const getItemsPricing = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getItemsPricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_items_pricing",
            parameters:credential
        });

        console.log(res.data);
        dispatch(modelActions.getItemsPricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemsPricingError(error));
    }
}


const updateItemPricing = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemPricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_pricing",
            parameters:credential
        });
        dispatch(modelActions.updateItemPricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemPricingError(error));
    }
}

const removeWidgetRecord = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.removeWidgetRecordRequest());

    try{
        const res = await axios.post(url, {
            name:"PORTAL_REMOVE_WIDGET_RECORD",
            parameters:credential
        });
        dispatch(modelActions.removeWidgetRecordSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeWidgetRecordError(error));
    }
}


const updateItemPackingWidget = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemPackingWidgetRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_packing_widget",
            parameters:credential
        });
        dispatch(modelActions.updateItemPackingWidgetSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemPackingWidgetError(error));
    }
}

const updateItemConvertationWidget = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemConvertationWidgetRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_convertation_widget",
            parameters:credential
        });
        dispatch(modelActions.updateItemConvertationWidgetSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemConvertationWidgetError(error));
    }
}

const enableDisablePackagesPricing = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.enableDisablePackagesPricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_enable_disable_packages_pricing",
            parameters:credential
        });
        dispatch(modelActions.enableDisablePackagesPricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.enableDisablePackagesPricingError(error));
    }
}

const convertPrice = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.convertPriceRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_convert_price",
            parameters:credential
        });
        dispatch(modelActions.convertPriceSuccess(res.data));

    }catch (error){
        dispatch(modelActions.convertPriceError(error));
    }
}

const updateItemPackingPricing = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemPackingPricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_packing_pricing",
            parameters:credential
        });
        dispatch(modelActions.updateItemPackingPricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemPackingPricingError(error));
    }
}

const updateItemTypePricing = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.updateItemTypePricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_type_pricing",
            parameters:credential
        });
        dispatch(modelActions.updateItemTypePricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemTypePricingError(error));
    }
}

const updateItemStartPricing = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.updateItemStartPricingRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_item_start_pricing",
            parameters:credential
        });
        dispatch(modelActions.updateItemStartPricingSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateItemStartPricingError(error));
    }
}

const getWidgetTypes = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetTypesRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widget_types",
            parameters:credential
        });
        dispatch(modelActions.getWidgetTypesSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetTypesError(error));
    }
}

const getPropertiesByProduct = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getPropertiesByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_properties_by_product",
            parameters:credential
        });
        dispatch(modelActions.getPropertiesByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getPropertiesByProductError(error));
    }
}


const removePropertiesWidgetByProduct = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.removePropertiesWidgetByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_properties_widget_by_product",
            parameters:credential
        });
        dispatch(modelActions.removePropertiesWidgetByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removePropertiesWidgetByProductError(error));
    }
}

const getProductListWithOptions = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getProductListWithOptionsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_product_list_with_options",
            parameters:credential
        });
        dispatch(modelActions.getProductListWithOptionsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductListWithOptionsError(error));
    }
}

const updateGalleryFlagProductOrOption = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.updateGalleryFlagProductOrOptionRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_gallery_flag_product_or_option",
            parameters:credential
        });
        dispatch(modelActions.updateGalleryFlagProductOrOptionSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateGalleryFlagProductOrOptionError(error));
    }
}


const getProductListWithOptionsAndPrices = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getProductListWithOptionsAndPricesRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_product_list_with_options_and_prices",
            parameters:credential
        });
        dispatch(modelActions.getProductListWithOptionsAndPricesSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductListWithOptionsAndPricesError(error));
    }
}


const copyPriceToOptions = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.copyPriceToOptionsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_copy_price_to_options",
            parameters:credential
        });
        dispatch(modelActions.copyPriceToOptionsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.copyPriceToOptionsError(error));
    }
}


const updatePriceProductOrOption = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.updatePriceProductOrOptionRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_price_product_or_option",
            parameters:credential
        });
        dispatch(modelActions.updatePriceProductOrOptionSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updatePriceProductOrOptionError(error));
    }
}


const createUpdateLocation = credential => async (dispatch, state) => {

    // console.log(credential);
    // return;


    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateLocationRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_update_location",
            parameters:credential
        });
        dispatch(modelActions.createUpdateLocationSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateLocationError(error));
    }
}

const getLocations = credential => async (dispatch, state) => {

    // console.log(credential);
    // return;


    resetToken(state, dispatch)
    dispatch(modelActions.getLocationsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_locations",
            parameters:credential
        });
        dispatch(modelActions.getLocationsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getLocationsError(error));
    }
}

const getLocationById = credential => async (dispatch, state) => {

    // console.log(credential);
    // return;


    resetToken(state, dispatch)
    dispatch(modelActions.getLocationByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_location_by_id",
            parameters:credential
        });
        dispatch(modelActions.getLocationByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getLocationByIdError(error));
    }
}

const getWidgetsListNotCatalog = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetsListNotCatalogRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widgets_list_not_catalog",
            parameters:credential
        });
        dispatch(modelActions.getWidgetsListNotCatalogSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetsListNotCatalogError(error));
    }
}

const getCatalogsList = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getCatalogsListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_catalogs_list",
            parameters:credential
        });
        dispatch(modelActions.getCatalogsListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getCatalogsListError(error));
    }
}


const linkUnlinkWidgetToCatalog = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.linkUnlinkWidgetToCatalogRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_link_unlink_widget_to_catalog",
            parameters:credential
        });
        dispatch(modelActions.linkUnlinkWidgetToCatalogSuccess(res.data));

    }catch (error){
        dispatch(modelActions.linkUnlinkWidgetToCatalogError(error));
    }
}

const getProductsInStock = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getProductsInStockRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_products_in_stock",
            parameters:credential
        });
        dispatch(modelActions.getProductsInStockSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductsInStockError(error));
    }
}


const getLocationShortList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getLocationShortListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_location_short_list",
            parameters:{}
        });
        dispatch(modelActions.getLocationShortListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getLocationShortListError(error));
    }
}


const getProductsInStockNotLocation = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getProductsInStockNotLocationRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_products_in_stock_not_location",
            parameters:credential
        });
        dispatch(modelActions.getProductsInStockNotLocationSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductsInStockNotLocationError(error));
    }
}

const initProductInLocation = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.initProductInLocationRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_init_product_in_location",
            parameters:credential
        });
        dispatch(modelActions.initProductInLocationSuccess(res.data));

    }catch (error){
        dispatch(modelActions.initProductInLocationError(error));
    }
}


const getProductBarcodesList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getProductBarcodesListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_product_barcodes_list",
            parameters:credential
        });
        dispatch(modelActions.getProductBarcodesListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductBarcodesListError(error));
    }
}

const addProductBarcode = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.addProductBarcodeRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_add_product_barcode",
            parameters:credential
        });
        dispatch(modelActions.addProductBarcodeSuccess(res.data));

    }catch (error){
        dispatch(modelActions.addProductBarcodeError(error));
    }
}

const moveProductToLocation = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.moveProductToLocationRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_move_product_to_location",
            parameters:credential
        });
        dispatch(modelActions.moveProductToLocationSuccess(res.data));

    }catch (error){
        dispatch(modelActions.moveProductToLocationError(error));
    }
}

const getLocationsWithProductsQty = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getLocationsWithProductsQtyRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_locations_with_products_qty",
            parameters:credential
        });
        dispatch(modelActions.getLocationsWithProductsQtySuccess(res.data));

    }catch (error){
        dispatch(modelActions.getLocationsWithProductsQtyError(error));
    }
}

const getMovementProductHistory = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getMovementProductHistoryRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_movement_product_history",
            parameters:credential
        });
        dispatch(modelActions.getMovementProductHistorySuccess(res.data));

    }catch (error){
        dispatch(modelActions.getMovementProductHistoryError(error));
    }
}


const removeImageFromGallery = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.removeImageFromGalleryRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_image_from_gallery",
            parameters:credential
        });
        dispatch(modelActions.removeImageFromGallerySuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeImageFromGalleryError(error));
    }
}

const updateSpecialPriceProductOrOption = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.updateSpecialPriceProductOrOptionRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_special_price_product_or_option",
            parameters:credential
        });
        dispatch(modelActions.updateSpecialPriceProductOrOptionSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateSpecialPriceProductOrOptionError(error));
    }
}

const getProductFromLucene = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getProductFromLuceneRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_product_from_lucene",
            parameters:credential
        });
        dispatch(modelActions.getProductFromLuceneSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductFromLuceneError(error));
    }
}

const portalRemoveProductById = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.portalRemoveProductByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_product_by_id",
            parameters:credential
        });
        dispatch(modelActions.portalRemoveProductByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.portalRemoveProductByIdError(error));
    }
}


const getAttributesByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getAttributesByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_attributes_by_product",
            parameters:credential
        });
        dispatch(modelActions.getAttributesByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getAttributesByProductError(error));
    }
}

const getWidgetsNoAttributesProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetsNoAttributesProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widgets_no_attributes_product",
            parameters:credential
        });
        dispatch(modelActions.getWidgetsNoAttributesProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetsNoAttributesProductError(error));
    }
}

const addAttributeByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.addAttributeByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_add_attribute_by_product",
            parameters:credential
        });
        dispatch(modelActions.addAttributeByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.addAttributeByProductError(error));
    }
}

const removeAttributeByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.removeAttributeByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_attribute_by_product",
            parameters:credential
        });
        dispatch(modelActions.removeAttributeByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeAttributeByProductError(error));
    }
}

const getProductCategories = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getProductCategoriesRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_product_categories",
            parameters:credential
        });
        dispatch(modelActions.getProductCategoriesSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getProductCategoriesError(error));
    }
}

const updateProductCategory = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.updateProductCategoryRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_product_category",
            parameters:credential
        });
        dispatch(modelActions.updateProductCategorySuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateProductCategoryError(error));
    }
}

const addRemovePropertiesWidgetByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.addRemovePropertiesWidgetByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_add_remove_properties_widget_by_product",
            parameters:credential
        });
        dispatch(modelActions.addRemovePropertiesWidgetByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.addRemovePropertiesWidgetByProductError(error));
    }
}

const getAttributesByGroupAndProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getAttributesByGroupAndProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_attributes_by_group_and_product",
            parameters:credential
        });
        dispatch(modelActions.getAttributesByGroupAndProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getAttributesByGroupAndProductError(error));
    }
}

const dragAndDropPropertiesWidgetByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.dragAndDropPropertiesWidgetByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_drag_and_drop_properties_widget_by_product",
            parameters:credential
        });
        dispatch(modelActions.dragAndDropPropertiesWidgetByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.dragAndDropPropertiesWidgetByProductError(error));
    }
}

const getPricingAttributesByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getPricingAttributesByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_pricing_attributes_by_product",
            parameters:credential
        });
        dispatch(modelActions.getPricingAttributesByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getPricingAttributesByProductError(error));
    }
}

const changePricingOptionsFlag = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.changePricingOptionsFlagRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_change_pricing_options_flag",
            parameters:credential
        });
        dispatch(modelActions.changePricingOptionsFlagSuccess(res.data));

    }catch (error){
        dispatch(modelActions.changePricingOptionsFlagError(error));
    }
}

const notLinkedPricingAttributesByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.notLinkedPricingAttributesByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_not_linked_pricing_attributes_by_product",
            parameters:credential
        });
        dispatch(modelActions.notLinkedPricingAttributesByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.notLinkedPricingAttributesByProductError(error));
    }
}

const addPricingAttributeByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.addPricingAttributeByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_add_pricing_attribute_by_product",
            parameters:credential
        });
        dispatch(modelActions.addPricingAttributeByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.addPricingAttributeByProductError(error));
    }
}


const removePricingAttributeByProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.removePricingAttributeByProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_pricing_attribute_by_product",
            parameters:credential
        });
        dispatch(modelActions.removePricingAttributeByProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removePricingAttributeByProductError(error));
    }
}

const getMeasures = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getMeasuresRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_measures",
            parameters:credential
        });
        dispatch(modelActions.getMeasuresSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getMeasuresError(error));
    }
}

const createUpdateManualMeasure = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdateManualMeasureRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_update_manual_measure",
            parameters:credential
        });
        dispatch(modelActions.createUpdateManualMeasureSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdateManualMeasureError(error));
    }
}

const getMeasureById = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getMeasureByIdRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_measure_by_id",
            parameters:credential
        });
        dispatch(modelActions.getMeasureByIdSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getMeasureByIdError(error));
    }
}

const removeManualMeasure = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.removeManualMeasureRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_manual_measure",
            parameters:credential
        });
        dispatch(modelActions.removeManualMeasureSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removeManualMeasureError(error));
    }
}

const getItemListLinkGroup = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getItemListLinkGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_list_link_group",
            parameters:credential
        });
        dispatch(modelActions.getItemListLinkGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemListLinkGroupError(error));
    }
}

const getItemListNotLinkGroup = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getItemListNotLinkGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_item_list_not_link_group",
            parameters:credential
        });
        dispatch(modelActions.getItemListNotLinkGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getItemListNotLinkGroupError(error));
    }
}

const dragAndDropSpecialProductsForProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.dragAndDropSpecialProductsForProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_drag_and_drop_special_products_for_product",
            parameters:credential
        });
        dispatch(modelActions.dragAndDropSpecialProductsForProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.dragAndDropSpecialProductsForProductError(error));
    }
}

const dragAndDropItemListLinkGroup = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.dragAndDropItemListLinkGroupRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_drag_and_drop_item_list_link_group",
            parameters:credential
        });
        dispatch(modelActions.dragAndDropItemListLinkGroupSuccess(res.data));

    }catch (error){
        dispatch(modelActions.dragAndDropItemListLinkGroupError(error));
    }
}

const getSpecialProductsForProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getSpecialProductsForProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_special_products_for_product",
            parameters:credential
        });
        dispatch(modelActions.getSpecialProductsForProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getSpecialProductsForProductError(error));
    }
}

const getSpecialProductsNotLinkedProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getSpecialProductsNotLinkedProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_special_products_not_linked_product",
            parameters:credential
        });
        dispatch(modelActions.getSpecialProductsNotLinkedProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getSpecialProductsNotLinkedProductError(error));
    }
}

const linkUnlinkSpecialProductsForProduct = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.linkUnlinkSpecialProductsForProductRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_link_unlink_special_products_for_product",
            parameters:credential
        });
        dispatch(modelActions.linkUnlinkSpecialProductsForProductSuccess(res.data));

    }catch (error){
        dispatch(modelActions.linkUnlinkSpecialProductsForProductError(error));
    }
}

const getLinkedCatalogs = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getLinkedCatalogsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_linked_catalogs",
            parameters:credential
        });
        dispatch(modelActions.getLinkedCatalogsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getLinkedCatalogsError(error));
    }
}

const getNotLinkedCatalogs = credential => async (dispatch, state) => {

    // console.log(credential);
    // return

    resetToken(state, dispatch)
    dispatch(modelActions.getNotLinkedCatalogsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_not_linked_catalogs",
            parameters:credential
        });
        dispatch(modelActions.getNotLinkedCatalogsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getNotLinkedCatalogsError(error));
    }
}

const drugAndDropCatalogs = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.drugAndDropCatalogsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_drug_and_drop_catalogs",
            parameters:credential
        });
        dispatch(modelActions.drugAndDropCatalogsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.drugAndDropCatalogsError(error));
    }
}

const getFeedbackShortInfo = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getFeedbackShortInfoRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_feedback_short_info",
            parameters:credential
        });
        dispatch(modelActions.getFeedbackShortInfoSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getFeedbackShortInfoError(error));
    }
}

const getFeedbackList = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getFeedbackListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_feedback_list",
            parameters:credential
        });
        dispatch(modelActions.getFeedbackListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getFeedbackListError(error));
    }
}

const getAllProductsInStock = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getAllProductsInStockRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_all_products_in_stock",
            parameters:credential
        });
        dispatch(modelActions.getAllProductsInStockSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getAllProductsInStockError(error));
    }
}

const getWidgetRecords = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.getWidgetRecordsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_widget_records",
            parameters:credential
        });
        dispatch(modelActions.getWidgetRecordsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getWidgetRecordsError(error));
    }
}

const updateVisibleGroupName = credential => async (dispatch, state) => {


    resetToken(state, dispatch)
    dispatch(modelActions.updateVisibleGroupNameRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_visible_group_name",
            parameters:credential
        });
        dispatch(modelActions.updateVisibleGroupNameSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateVisibleGroupNameError(error));
    }
}

const getVisibleGroupName = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getVisibleGroupNameRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_visible_group_name",
            parameters:credential
        });
        dispatch(modelActions.getVisibleGroupNameSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getVisibleGroupNameError(error));
    }
}

const getMainSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getMainSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_main_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.getMainSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getMainSaitSettingsError(error));
    }
}

const updateMainSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateMainSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_main_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.updateMainSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateMainSaitSettingsError(error));
    }
}

const createSait = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createSaitRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_sait",
            parameters:credential
        });
        dispatch(modelActions.createSaitSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createSaitError(error));
    }
}

const getSaitSettingsList = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getSaitSettingsListRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_sait_settings_list",
            parameters:credential
        });
        dispatch(modelActions.getSaitSettingsListSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getSaitSettingsListError(error));
    }
}

const getContactsAndSocialSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getContactsAndSocialSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_contacts_and_social_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.getContactsAndSocialSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getContactsAndSocialSaitSettingsError(error));
    }
}

const updateContactsAndSocialSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.updateContactsAndSocialSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_update_contacts_and_social_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.updateContactsAndSocialSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.updateContactsAndSocialSaitSettingsError(error));
    }
}

const getPartnersSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getPartnersSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_partners_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.getPartnersSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getPartnersSaitSettingsError(error));
    }
}

const getPartnerByIdSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.getPartnerByIdSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_get_partner_by_id_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.getPartnerByIdSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.getPartnerByIdSaitSettingsError(error));
    }
}

const createUpdatePartnerSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.createUpdatePartnerSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_create_update_partner_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.createUpdatePartnerSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.createUpdatePartnerSaitSettingsError(error));
    }
}

const removePartnerSaitSettings = credential => async (dispatch, state) => {

    resetToken(state, dispatch)
    dispatch(modelActions.removePartnerSaitSettingsRequest());

    try{
        const res = await axios.post(url, {
            name:"portal_remove_partner_sait_settings",
            parameters:credential
        });
        dispatch(modelActions.removePartnerSaitSettingsSuccess(res.data));

    }catch (error){
        dispatch(modelActions.removePartnerSaitSettingsError(error));
    }
}


export default {

    getLegalInfoSaitSettingsList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getLegalInfoSaitSettingsListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_legal_info_sait_settings_list",
                parameters:credential
            });
            dispatch(modelActions.getLegalInfoSaitSettingsListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getLegalInfoSaitSettingsListError(error));
        }
    },

    createUpdateLegalInfoTitle : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createUpdateLegalInfoTitleRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_update_legal_info_title",
                parameters:credential
            });
            dispatch(modelActions.createUpdateLegalInfoTitleSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createUpdateLegalInfoTitleError(error));
        }
    },
    updateLegalInfoDescription : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLegalInfoDescriptionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_legal_info_description",
                parameters:credential
            });
            dispatch(modelActions.updateLegalInfoDescriptionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLegalInfoDescriptionError(error));
        }
    },

    updateLegalInfoDeclineText : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLegalInfoDeclineTextRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_legal_info_decline_text",
                parameters:credential
            });
            dispatch(modelActions.updateLegalInfoDeclineTextSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLegalInfoDeclineTextError(error));
        }
    },

    getLegalInfoDescription : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getLegalInfoDescriptionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_legal_info_description",
                parameters:credential
            });
            dispatch(modelActions.getLegalInfoDescriptionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getLegalInfoDescriptionError(error));
        }
    },

    getLegalInfoDeclineText : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getLegalInfoDeclineTextRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_legal_info_decline_text",
                parameters:credential
            });
            dispatch(modelActions.getLegalInfoDeclineTextSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getLegalInfoDeclineTextError(error));
        }
    },

    getLegalInfoTitle : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getLegalInfoTitleRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_legal_info_title",
                parameters:credential
            });
            dispatch(modelActions.getLegalInfoTitleSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getLegalInfoTitleError(error));
        }
    },

    updateLegalInfoStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLegalInfoStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_legal_info_status",
                parameters:credential
            });
            dispatch(modelActions.updateLegalInfoStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLegalInfoStatusError(error));
        }
    },

    updateLegalInfoConfirmation : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLegalInfoConfirmationRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_legal_info_confirmation",
                parameters:credential
            });
            dispatch(modelActions.updateLegalInfoConfirmationSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLegalInfoConfirmationError(error));
        }
    },

    removeLegalInfo : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeLegalInfoRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_legal_info",
                parameters:credential
            });
            dispatch(modelActions.removeLegalInfoSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeLegalInfoError(error));
        }
    },

    getPaymentSaitSettings : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getPaymentSaitSettingsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_payment_sait_settings",
                parameters:credential
            });
            dispatch(modelActions.getPaymentSaitSettingsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getPaymentSaitSettingsError(error));
        }
    },

    updatePaymentSaitSettings : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updatePaymentSaitSettingsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_payment_sait_settings",
                parameters:credential
            });
            dispatch(modelActions.updatePaymentSaitSettingsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updatePaymentSaitSettingsError(error));
        }
    },

    updatePaymentSaitSettingsName : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updatePaymentSaitSettingsNameRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_payment_sait_settings_name",
                parameters:credential
            });
            dispatch(modelActions.updatePaymentSaitSettingsNameSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updatePaymentSaitSettingsNameError(error));
        }
    },

    updatePaymentSaitSettingsStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updatePaymentSaitSettingsStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_payment_sait_settings_status",
                parameters:credential
            });
            dispatch(modelActions.updatePaymentSaitSettingsStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updatePaymentSaitSettingsStatusError(error));
        }
    },

    updatePaymentSaitSettingsAddress : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updatePaymentSaitSettingsAddressRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_payment_sait_settings_address",
                parameters:credential
            });
            dispatch(modelActions.updatePaymentSaitSettingsAddressSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updatePaymentSaitSettingsAddressError(error));
        }
    },

    updatePaymentSaitSettingsIcons : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updatePaymentSaitSettingsIconsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_payment_sait_settings_icons",
                parameters:credential
            });
            dispatch(modelActions.updatePaymentSaitSettingsIconsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updatePaymentSaitSettingsIconsError(error));
        }
    },

    getTranslateByCode : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getTranslateByCodeRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_translate_by_code",
                parameters:credential
            });
            dispatch(modelActions.getTranslateByCodeSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getTranslateByCodeError(error));
        }
    },

    updateTranslateByCode : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateTranslateByCodeRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_translate_by_code",
                parameters:credential
            });
            dispatch(modelActions.updateTranslateByCodeSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateTranslateByCodeError(error));
        }
    },

    updateAdditionalPropertyStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateAdditionalPropertyStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_additional_property_status",
                parameters:credential
            });
            dispatch(modelActions.updateAdditionalPropertyStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateAdditionalPropertyStatusError(error));
        }
    },

    getOrdersHistory : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getOrdersHistoryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_orders_history",
                parameters:credential
            });
            dispatch(modelActions.getOrdersHistorySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getOrdersHistoryError(error));
        }
    },

    getCryptoOrderForPay : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getCryptoOrderForPayRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_crypto_order_for_pay",
                parameters:credential
            });
            dispatch(modelActions.getCryptoOrderForPaySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getCryptoOrderForPayError(error));
        }
    },

    payCryptoOrderFromHistory : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.payCryptoOrderFromHistoryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_pay_crypto_order_from_history",
                parameters:credential
            });
            dispatch(modelActions.payCryptoOrderFromHistorySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.payCryptoOrderFromHistoryError(error));
        }
    },

    getOrderProductsDelivery : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getOrderProductsDeliveryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_order_products_delivery",
                parameters:credential
            });
            dispatch(modelActions.getOrderProductsDeliverySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getOrderProductsDeliveryError(error));
        }
    },

    getLocationsByProduct : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getLocationsByProductRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_locations_by_product",
                parameters:credential
            });
            dispatch(modelActions.getLocationsByProductSuccess({ ID_ITEM:credential.ID_ITEM, result:res.data.result['#result-set-1'] }));
    
        }catch (error){
            dispatch(modelActions.getLocationsByProductError(error));
        }
    },

    updateLocationProductDelivery : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLocationProductDeliveryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_location_product_delivery",
                parameters:credential
            });
            dispatch(modelActions.updateLocationProductDeliverySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLocationProductDeliveryError(error));
        }
    },
    
    getDeliveryPackages : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDeliveryPackagesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_delivery_packages",
                parameters:credential
            });
            dispatch(modelActions.getDeliveryPackagesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDeliveryPackagesError(error));
        }
    },

    getDeliveryTypes : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDeliveryTypesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_delivery_types",
                parameters:credential
            });
            dispatch(modelActions.getDeliveryTypesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDeliveryTypesError(error));
        }
    },

    updateLocationDeliveryService : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLocationDeliveryServiceRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_delivery_types",
                parameters:credential
            });
            dispatch(modelActions.updateLocationDeliveryServiceSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLocationDeliveryServiceError(error));
        }
    },
    updateDeliveryTracking : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateDeliveryTrackingRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_delivery_tracking",
                parameters:credential
            });
            dispatch(modelActions.updateDeliveryTrackingSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateDeliveryTrackingError(error));
        }
    },

    updateDeliveryStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateDeliveryStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_delivery_status",
                parameters:credential
            });
            dispatch(modelActions.updateDeliveryStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateDeliveryStatusError(error));
        }
    },

    cancelDelivery : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.cancelDeliveryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_cancel_delivery",
                parameters:credential
            });
            dispatch(modelActions.cancelDeliverySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.cancelDeliveryError(error));
        }
    },

    updateWebSaitProductStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateWebSaitProductStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_web_sait_product_status",
                parameters:credential
            });
            dispatch(modelActions.updateWebSaitProductStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateWebSaitProductStatusError(error));
        }
    },

    updateMarketplaceProductStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateMarketplaceProductStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_marketplace_product_status",
                parameters:credential
            });
            dispatch(modelActions.updateMarketplaceProductStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateMarketplaceProductStatusError(error));
        }
    },
    getWebSaitsProductStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getWebSaitsProductStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_web_saits_product_status",
                parameters:credential
            });
            dispatch(modelActions.getWebSaitsProductStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getWebSaitsProductStatusError(error));
        }
    },
    webSaitPaymentSystemsTypes : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.webSaitPaymentSystemsTypesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_web_sait_payment_systems_types",
                parameters:credential
            });
            dispatch(modelActions.webSaitPaymentSystemsTypesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.webSaitPaymentSystemsTypesError(error));
        }
    },

    webSaitPaymentSystemByType : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.webSaitPaymentSystemByTypeRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_web_sait_payment_system_by_type",
                parameters:credential
            });
            dispatch(modelActions.webSaitPaymentSystemByTypeSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.webSaitPaymentSystemByTypeError(error));
        }
    },

    updateWebSaitPaymentSystemBraintree : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateWebSaitPaymentSystemBraintreeRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_web_sait_payment_system_braintree",
                parameters:credential
            });
            dispatch(modelActions.updateWebSaitPaymentSystemBraintreeSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateWebSaitPaymentSystemBraintreeError(error));
        }
    },
    updateWebSaitPaymentSystemPaypal : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateWebSaitPaymentSystemPaypalRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_web_sait_payment_system_paypal",
                parameters:credential
            });
            dispatch(modelActions.updateWebSaitPaymentSystemPaypalSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateWebSaitPaymentSystemPaypalError(error));
        }
    },

    getProductListTypeSaitSettings : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getProductListTypeSaitSettingsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_product_list_type_sait_settings",
                parameters:credential
            });
            dispatch(modelActions.getProductListTypeSaitSettingsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getProductListTypeSaitSettingsError(error));
        }
    },

    updateProductListTypeSaitSettings : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateProductListTypeSaitSettingsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_product_list_type_sait_settings",
                parameters:credential
            });
            dispatch(modelActions.updateProductListTypeSaitSettingsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateProductListTypeSaitSettingsError(error));
        }
    },
    currencyList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.currencyListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_currency_list",
                parameters:credential
            });
            dispatch(modelActions.currencyListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.currencyListError(error));
        }
    },
    usersOrdersHistoryList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.usersOrdersHistoryListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_users_orders_history_list",
                parameters:credential
            });
            dispatch(modelActions.usersOrdersHistoryListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.usersOrdersHistoryListError(error));
        }
    },

    getUserOrdersHistoryByUser : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getUserOrdersHistoryByUserRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_user_orders_history_by_user",
                parameters:credential
            });
            dispatch(modelActions.getUserOrdersHistoryByUserSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getUserOrdersHistoryByUserError(error));
        }
    },
    createShareSalesProfit : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createShareSalesProfitRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_share_sales_profit",
                parameters:credential
            });
            dispatch(modelActions.createShareSalesProfitSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createShareSalesProfitError(error));
        }
    },

    updateShareSalesProfitStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateShareSalesProfitStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_share_sales_profit_status",
                parameters:credential
            });
            dispatch(modelActions.updateShareSalesProfitStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateShareSalesProfitStatusError(error));
        }
    },
    getShareSalesProfitList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getShareSalesProfitListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_share_sales_profit_list",
                parameters:credential
            });
            dispatch(modelActions.getShareSalesProfitListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getShareSalesProfitListError(error));
        }
    },

    removeShareSalesProfit : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeShareSalesProfitRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_share_sales_profit",
                parameters:credential
            });
            dispatch(modelActions.removeShareSalesProfitSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeShareSalesProfitError(error));
        }
    },

    updateLocationStatus : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateLocationStatusRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_location_status",
                parameters:credential
            });
            dispatch(modelActions.updateLocationStatusSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateLocationStatusError(error));
        }
    },
    dragAndDropGallary : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.dragAndDropGallaryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_drag_and_drop_gallary",
                parameters:credential
            });
            dispatch(modelActions.dragAndDropGallarySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.dragAndDropGallaryError(error));
        }
    },
    updateWebSaitPaymentSystemSquareup : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateWebSaitPaymentSystemSquareupRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_web_sait_payment_system_squareup",
                parameters:credential
            });
            dispatch(modelActions.updateWebSaitPaymentSystemSquareupSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateWebSaitPaymentSystemSquareupError(error));
        }
    },

    updateWebSaitPaymentSystemStrip : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateWebSaitPaymentSystemStripRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_web_sait_payment_system_strip",
                parameters:credential
            });
            dispatch(modelActions.updateWebSaitPaymentSystemStripSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateWebSaitPaymentSystemStripError(error));
        }
    },

    getMarketplaceCatalogs : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getMarketplaceCatalogsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_marketplace_catalogs",
                parameters:credential
            });
            dispatch(modelActions.getMarketplaceCatalogsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getMarketplaceCatalogsError(error));
        }
    },

    createUpdateMarketplaceCatalog : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createUpdateMarketplaceCatalogRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_update_marketplace_catalog",
                parameters:credential
            });
            dispatch(modelActions.createUpdateMarketplaceCatalogSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createUpdateMarketplaceCatalogError(error));
        }
    },

    getMarketplaceCatalogById : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getMarketplaceCatalogByIdRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_marketplace_catalog_by_id",
                parameters:credential
            });
            dispatch(modelActions.getMarketplaceCatalogByIdSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getMarketplaceCatalogByIdError(error));
        }
    },

    dragAndDropMarketplaceCatalog : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.dragAndDropMarketplaceCatalogRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_drag_and_drop_marketplace_catalog",
                parameters:credential
            });
            dispatch(modelActions.dragAndDropMarketplaceCatalogSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.dragAndDropMarketplaceCatalogError(error));
        }
    },

    getItemsLinkMarketplaceCatalog : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getItemsLinkMarketplaceCatalogRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_items_link_marketplace_catalog",
                parameters:credential
            });
            dispatch(modelActions.getItemsLinkMarketplaceCatalogSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getItemsLinkMarketplaceCatalogError(error));
        }
    },

    getItemsUnlinkMarketplaceCatalog : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getItemsUnlinkMarketplaceCatalogRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_items_unlink_marketplace_catalog",
                parameters:credential
            });
            dispatch(modelActions.getItemsUnlinkMarketplaceCatalogSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getItemsUnlinkMarketplaceCatalogError(error));
        }
    },

    linkUnlinkItemMarketplaceCatalog : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.linkUnlinkItemMarketplaceCatalogRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_link_unlink_item_marketplace_catalog",
                parameters:credential
            });
            dispatch(modelActions.linkUnlinkItemMarketplaceCatalogSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.linkUnlinkItemMarketplaceCatalogError(error));
        }
    },

    dragAndDropItemsMarketplaceCatalog : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.dragAndDropItemsMarketplaceCatalogRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_drag_and_drop_items_marketplace_catalog",
                parameters:credential
            });
            dispatch(modelActions.dragAndDropItemsMarketplaceCatalogSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.dragAndDropItemsMarketplaceCatalogError(error));
        }
    },

    publishErc1155 : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.publishErc1155Request());
    
        try{
            const res = await axios.post(url, {
                name:"portal_publish_erc1155",
                parameters:credential
            });
            dispatch(modelActions.publishErc1155Success(res.data));
    
        }catch (error){
            dispatch(modelActions.publishErc1155Error(error));
        }
    },

    updateUserBlockchainAddress : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateUserBlockchainAddressRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_user_blockchain_address",
                parameters:credential
            });
            dispatch(modelActions.updateUserBlockchainAddressSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateUserBlockchainAddressError(error));
        }
    },

    getUserBlockchainAddress : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getUserBlockchainAddressRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_user_blockchain_address",
                parameters:credential
            });
            dispatch(modelActions.getUserBlockchainAddressSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getUserBlockchainAddressError(error));
        }
    },

    getDataMetamask : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDataMetamaskRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_data_metamask",
                parameters:credential
            });
            dispatch(modelActions.getDataMetamaskSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDataMetamaskError(error));
        }
    },

    updateTransactionHashMetamask : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateTransactionHashMetamaskRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_transaction_hash_metamask",
                parameters:credential
            });
            dispatch(modelActions.updateTransactionHashMetamaskSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateTransactionHashMetamaskError(error));
        }
    },

    getGlobalPaymentSystems : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getGlobalPaymentSystemsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_global_payment_systems",
                parameters:credential
            });
            dispatch(modelActions.getGlobalPaymentSystemsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getGlobalPaymentSystemsError(error));
        }
    },

    paymentSystemsCoinsAmount : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.paymentSystemsCoinsAmountRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_payment_systems_coins_amount",
                parameters:credential
            });
            dispatch(modelActions.paymentSystemsCoinsAmountSuccess({credential, data:res.data.result}));
    
        }catch (error){
            dispatch(modelActions.paymentSystemsCoinsAmountError(error));
        }
    },

    saveProceedToCheckout : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.saveProceedToCheckoutRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_save_proceed_to_checkout",
                parameters:credential
            });
            dispatch(modelActions.saveProceedToCheckoutSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.saveProceedToCheckoutError(error));
        }
    },

    getBalanceInfo : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getBalanceInfoRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_balance_info",
                parameters:credential
            });
            dispatch(modelActions.getBalanceInfoSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getBalanceInfoError(error));
        }
    },
    transactionBuyCoinsBitcoin : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.transactionBuyCoinsBitcoinRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_transaction_buy_coins_bitcoin",
                parameters:credential
            });
            dispatch(modelActions.transactionBuyCoinsBitcoinSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.transactionBuyCoinsBitcoinError(error));
        }
    },

    getPaypalClientId : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getPaypalClientIdRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_paypal_client_id",
                parameters:credential
            });
            dispatch(modelActions.getPaypalClientIdSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getPaypalClientIdError(error));
        }
    },

    transactionBuyCoinsPaypal : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.transactionBuyCoinsPaypalRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_transaction_buy_coins_paypal",
                parameters:credential
            });
            dispatch(modelActions.transactionBuyCoinsPaypalSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.transactionBuyCoinsPaypalError(error));
        }
    },

    getStripClientSecret : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getStripClientSecretRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_strip_client_secret",
                parameters:credential
            });
            dispatch(modelActions.getStripClientSecretSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getStripClientSecretError(error));
        }
    },

    getStripToken : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getStripTokenRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_strip_token",
                parameters:credential
            });
            dispatch(modelActions.getStripTokenSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getStripTokenError(error));
        }
    },

    transactionBuyCoinsStrip : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.transactionBuyCoinsStripRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_transaction_buy_coins_strip",
                parameters:credential
            });
            dispatch(modelActions.transactionBuyCoinsStripSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.transactionBuyCoinsStripError(error));
        }
    },

    getVendorInfo : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getVendorInfoRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_vendor_info",
                parameters:credential
            });
            dispatch(modelActions.getVendorInfoSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getVendorInfoError(error));
        }
    },

    updateVendorImage : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateVendorImageRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_vendor_image",
                parameters:credential
            });
            dispatch(modelActions.updateVendorImageSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateVendorImageError(error));
        }
    },

    updateVendorNameAndCountry : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateVendorNameAndCountryRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_vendor_name_and_country",
                parameters:credential
            });
            dispatch(modelActions.updateVendorNameAndCountrySuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateVendorNameAndCountryError(error));
        }
    },

    getPayoutMethodTypes : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getPayoutMethodTypesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_payout_method_types",
                parameters:credential
            });
            dispatch(modelActions.getPayoutMethodTypesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getPayoutMethodTypesError(error));
        }
    },

    getPayoutMethods : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getPayoutMethodsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_payout_methods",
                parameters:credential
            });
            dispatch(modelActions.getPayoutMethodsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getPayoutMethodsError(error));
        }
    },

    createUpdatePayoutMethod : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createUpdatePayoutMethodRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_update_payout_method",
                parameters:credential
            });
            dispatch(modelActions.createUpdatePayoutMethodSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createUpdatePayoutMethodError(error));
        }
    },

    getPayoutMethodNames : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getPayoutMethodNamesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_payout_method_names",
                parameters:credential
            });
            dispatch(modelActions.getPayoutMethodNamesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getPayoutMethodNamesError(error));
        }
    },

    createPayout : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createPayoutRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_payout",
                parameters:credential
            });
            dispatch(modelActions.createPayoutSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createPayoutError(error));
        }
    },

    getTransactionsPayout : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getTransactionsPayoutRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_transactions_payout",
                parameters:credential
            });
            dispatch(modelActions.getTransactionsPayoutSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getTransactionsPayoutError(error));
        }
    },

    getDspSubscriptions : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDspSubscriptionsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_dsp_subscriptions",
                parameters:credential
            });
            dispatch(modelActions.getDspSubscriptionsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDspSubscriptionsError(error));
        }
    },

    createUpdateDspSibscriptions : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createUpdateDspSibscriptionsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_update_dsp_sibscriptions",
                parameters:credential
            });
            dispatch(modelActions.createUpdateDspSibscriptionsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createUpdateDspSibscriptionsError(error));
        }
    },

    removeDspSubscriptions : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeDspSubscriptionsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_dsp_subscriptions",
                parameters:credential
            });
            dispatch(modelActions.removeDspSubscriptionsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeDspSubscriptionsError(error));
        }
    },

    getDspSibscriptionById : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDspSibscriptionByIdRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_dsp_sibscription_by_id",
                parameters:credential
            });
            dispatch(modelActions.getDspSibscriptionByIdSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDspSibscriptionByIdError(error));
        }
    },

    getDspTemplates : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDspTemplatesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_dsp_templates",
                parameters:credential
            });
            dispatch(modelActions.getDspTemplatesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDspTemplatesError(error));
        }
    },

    initDspTemplateId : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.initDspTemplateIdRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_init_dsp_template_id",
                parameters:credential
            });
            dispatch(modelActions.initDspTemplateIdSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.initDspTemplateIdError(error));
        }
    },

    getDspTemplateImages : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDspTemplateImagesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_dsp_template_images",
                parameters:credential
            });
            dispatch(modelActions.getDspTemplateImagesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDspTemplateImagesError(error));
        }
    },

    addDspTemplateImage : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.addDspTemplateImageRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_add_dsp_template_image",
                parameters:credential
            });
            dispatch(modelActions.addDspTemplateImageSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.addDspTemplateImageError(error));
        }
    },

    removeDspTemplateImage : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeDspTemplateImageRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_dsp_template_image",
                parameters:credential
            });
            dispatch(modelActions.removeDspTemplateImageSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeDspTemplateImageError(error));
        }
    },

    createUpdateDspTemplates : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createUpdateDspTemplatesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_update_dsp_templates",
                parameters:credential
            });
            dispatch(modelActions.createUpdateDspTemplatesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createUpdateDspTemplatesError(error));
        }
    },

    removeDspTemplates : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeDspTemplatesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_dsp_templates",
                parameters:credential
            });
            dispatch(modelActions.removeDspTemplatesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeDspTemplatesError(error));
        }
    },

    getDspTemplateById : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDspTemplateByIdRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_dsp_template_by_id",
                parameters:credential
            });
            dispatch(modelActions.getDspTemplateByIdSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDspTemplateByIdError(error));
        }
    },

    getClientDistributionList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getClientDistributionListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_client_distribution_list",
                parameters:credential
            });
            dispatch(modelActions.getClientDistributionListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getClientDistributionListError(error));
        }
    },

    getDistributionTypes : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDistributionTypesRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_distribution_types",
                parameters:credential
            });
            dispatch(modelActions.getDistributionTypesSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDistributionTypesError(error));
        }
    },


    createClientDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createClientDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_client_distribution",
                parameters:credential
            });
            dispatch(modelActions.createClientDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createClientDistributionError(error));
        }
    },

    initDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.initDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_init_distribution",
                parameters:credential
            });
            dispatch(modelActions.initDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.initDistributionError(error));
        }
    },

    removeDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_distribution",
                parameters:credential
            });
            dispatch(modelActions.removeDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeDistributionError(error));
        }
    },

    getMailsFromDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getMailsFromDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_mails_from_distribution",
                parameters:credential
            });
            dispatch(modelActions.getMailsFromDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getMailsFromDistributionError(error));
        }
    },

    getClientMail : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getClientMailRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_client_mail",
                parameters:credential
            });
            dispatch(modelActions.getClientMailSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getClientMailError(error));
        }
    },

    resendClientMail : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.resendClientMailRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_resend_client_mail",
                parameters:credential
            });
            dispatch(modelActions.resendClientMailSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.resendClientMailError(error));
        }
    },

    getSystemDistributionList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getSystemDistributionListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_system_distribution_list",
                parameters:credential
            });
            dispatch(modelActions.getSystemDistributionListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getSystemDistributionListError(error));
        }
    },

    getDistributionSystemFunctions : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDistributionSystemFunctionsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_distribution_system_functions",
                parameters:credential
            });
            dispatch(modelActions.getDistributionSystemFunctionsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDistributionSystemFunctionsError(error));
        }
    },

    createUpdateSystemDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.createUpdateSystemDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_create_update_system_distribution",
                parameters:credential
            });
            dispatch(modelActions.createUpdateSystemDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.createUpdateSystemDistributionError(error));
        }
    },

    setTemplateDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.setTemplateDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_set_template_distribution",
                parameters:credential
            });
            dispatch(modelActions.setTemplateDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.setTemplateDistributionError(error));
        }
    },

    getTemplatesDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getTemplatesDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_templates_distribution",
                parameters:credential
            });
            dispatch(modelActions.getTemplatesDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getTemplatesDistributionError(error));
        }
    },

    getSystemDistributionById : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getSystemDistributionByIdRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_system_distribution_by_id",
                parameters:credential
            });
            dispatch(modelActions.getSystemDistributionByIdSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getSystemDistributionByIdError(error));
        }
    },

    removeTemplateDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.removeTemplateDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_remove_template_distribution",
                parameters:credential
            });
            dispatch(modelActions.removeTemplateDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.removeTemplateDistributionError(error));
        }
    },

    stopStartDistribution : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.stopStartDistributionRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_stop_start_distribution",
                parameters:credential
            });
            dispatch(modelActions.stopStartDistributionSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.stopStartDistributionError(error));
        }
    },

    getMailsWithFilter : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getMailsWithFilterRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_mails_with_filter",
                parameters:credential
            });
            dispatch(modelActions.getMailsWithFilterSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getMailsWithFilterError(error));
        }
    },

    getProductsMarketplace : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getProductsMarketplaceRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_products_marketplace",
                parameters:credential
            });
            dispatch(modelActions.getProductsMarketplaceSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getProductsMarketplaceError(error));
        }
    },

    setProductPercentDiscount : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.setProductPercentDiscountRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_set_product_percent_discount",
                parameters:credential
            });
            dispatch(modelActions.setProductPercentDiscountSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.setProductPercentDiscountError(error));
        }
    },

    getDefaultSettingsDiscount : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getDefaultSettingsDiscountRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_default_settings_discount",
                parameters:credential
            });
            dispatch(modelActions.getDefaultSettingsDiscountSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getDefaultSettingsDiscountError(error));
        }
    },


    setCashbackPointsMaxLimit : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.setCashbackPointsMaxLimitRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_set_cashback_points_max_limit",
                parameters:credential
            });
            dispatch(modelActions.setCashbackPointsMaxLimitSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.setCashbackPointsMaxLimitError(error));
        }
    },

    setPointDiscountMaxLimit : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.setPointDiscountMaxLimitRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_set_point_discount_max_limit",
                parameters:credential
            });
            dispatch(modelActions.setPointDiscountMaxLimitSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.setPointDiscountMaxLimitError(error));
        }
    },

    setCashbackPointsDefaultValue : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.setCashbackPointsDefaultValueRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_set_cashback_points_default_value",
                parameters:credential
            });
            dispatch(modelActions.setCashbackPointsDefaultValueSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.setCashbackPointsDefaultValueError(error));
        }
    },

    setPointDiscountDefaultValue : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.setPointDiscountDefaultValueRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_set_point_discount_default_value",
                parameters:credential
            });
            dispatch(modelActions.setPointDiscountDefaultValueSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.setPointDiscountDefaultValueError(error));
        }
    },

    getRatesManagment : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getRatesManagmentRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_rates_managment",
                parameters:credential
            });
            dispatch(modelActions.getRatesManagmentSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getRatesManagmentError(error));
        }
    },

    getMangmentRatesList : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getMangmentRatesListRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_mangment_rates_list",
                parameters:credential
            });
            dispatch(modelActions.getMangmentRatesListSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getMangmentRatesListError(error));
        }
    },

    getExchangeAddress : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getExchangeAddressRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_exchange_address",
                parameters:credential
            });
            dispatch(modelActions.getExchangeAddressSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getExchangeAddressError(error));
        }
    },

    updateExchangeAddress : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateExchangeAddressRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_exchange_address",
                parameters:credential
            });
            dispatch(modelActions.updateExchangeAddressSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateExchangeAddressError(error));
        }
    },

    updateRatesManagment : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.updateRatesManagmentRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_update_rates_managment",
                parameters:credential
            });
            dispatch(modelActions.updateRatesManagmentSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.updateRatesManagmentError(error));
        }
    },

    
    getExchangeAddressTransactions : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getExchangeAddressTransactionsRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_exchange_address_transactions",
                parameters:credential
            });
            dispatch(modelActions.getExchangeAddressTransactionsSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getExchangeAddressTransactionsError(error));
        }
    },

    getProductSettingsDiscount : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.getProductSettingsDiscountRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_get_product_settings_discount",
                parameters:credential
            });
            dispatch(modelActions.getProductSettingsDiscountSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.getProductSettingsDiscountError(error));
        }
    },

    sendTestEmail : credential => async (dispatch, state) => {

        resetToken(state, dispatch)
        dispatch(modelActions.sendTestEmailRequest());
    
        try{
            const res = await axios.post(url, {
                name:"portal_send_test_email",
                parameters:credential
            });
            dispatch(modelActions.sendTestEmailSuccess(res.data));
    
        }catch (error){
            dispatch(modelActions.sendTestEmailError(error));
        }
    },



    removePartnerSaitSettings,
    createUpdatePartnerSaitSettings,
    getPartnerByIdSaitSettings,
    getPartnersSaitSettings,
    updateContactsAndSocialSaitSettings,
    getContactsAndSocialSaitSettings,
    getSaitSettingsList,
    createSait,
    updateMainSaitSettings,
    getMainSaitSettings,
    getVisibleGroupName,
    updateVisibleGroupName,
    getWidgetRecords,
    getAllProductsInStock,
    getFeedbackList,
    getFeedbackShortInfo,
    drugAndDropCatalogs,
    linkUnlinkWidgetToCatalog,
    getNotLinkedCatalogs,
    getLinkedCatalogs,
    linkUnlinkSpecialProductsForProduct,
    getSpecialProductsNotLinkedProduct,
    getSpecialProductsForProduct,
    dragAndDropItemListLinkGroup,
    dragAndDropSpecialProductsForProduct,
    getItemListNotLinkGroup,
    getItemListLinkGroup,
    removeManualMeasure,
    getMeasureById,
    createUpdateManualMeasure,
    removePricingAttributeByProduct,
    addPricingAttributeByProduct,
    notLinkedPricingAttributesByProduct,
    changePricingOptionsFlag,
    getPricingAttributesByProduct,
    dragAndDropPropertiesWidgetByProduct,
    getAppPagesList,
    getSaitList,
    getTranslator,
    updatePage,
    updateTranslator,
    removeTranslator,
    searchGetRequestsList,
    searchGetLinksList,
    getWidgetsSchemeList,
    createUpdateWidgetScheme,
    getWidgetSchemeById,
    getWidgetSchemeWithWidgets,
    getKeywordLink,
    createUpdateKeyword,
    getKeywordsList,
    getKeyword,
    createUpdateKey,
    removeKeyword,
    getWidgetList,
    getItemsListWidgetQty,
    updateItemStatus,
    getItemById,
    createUpdateItem,
    getGallariesListWidgetQty,
    getGallaryByID,
    createUpdateGallary,
    updateWidgetActive,
    getWidgetById,
    createUpadetWidget,
    getItemGroupList,
    createItemGroup,
    remoevItemGroup,
    getItemListSortGroup,
    linkUnlinkItemGroup,
    removeWidget,
    getWidgetConvertationList,
    getItemGroupTypes,
    createUpdateItemGroup,
    getWidgetRecordList,
    getWidgetRecordById,
    createUpdateWidgetRecord,
    changeLevelItemInGroup,
    getItemGroupById,
    getWidgetGroupType,
    getWidgetsNoSchemaWithType,
    linkWidgetToWidgetScheme,
    getLinkedWidgets,
    updateWidgetLevelInWidgetScheme,
    linkProductToWidgetScheme,
    getSchemeByProduct,
    updateItemProperties,
    getSchemeByItemList,
    getItemWidgetsPricing,
    updateItemPricingWidget,
    getItemsPricing,
    updateItemPricing,
    removeWidgetRecord,
    updateItemPackingWidget,
    updateItemConvertationWidget,
    enableDisablePackagesPricing,
    convertPrice,
    updateItemPackingPricing,
    updateItemTypePricing,
    updateItemStartPricing,
    getWidgetTypes,
    getPropertiesByProduct,
    getWidgetsNoPropertiesProduct,
    addPropertiesWidgetByProduct,
    removePropertiesWidgetByProduct,
    getProductListWithOptions,
    updateGalleryFlagProductOrOption,
    getProductListWithOptionsAndPrices,
    copyPriceToOptions,
    updatePriceProductOrOption,
    createUpdateLocation,
    getLocations,
    getLocationById,
    getWidgetsListNotCatalog,
    getCatalogsList,
    linkUnlinkWidgetToCatalog,
    getProductsInStock,
    getLocationShortList,
    getProductsInStockNotLocation,
    initProductInLocation,
    getProductBarcodesList,
    addProductBarcode,
    moveProductToLocation,
    getLocationsWithProductsQty,
    getMovementProductHistory,
    removeImageFromGallery,
    updateSpecialPriceProductOrOption,
    getProductFromLucene,
    portalRemoveProductById,
    // getItemsList,
    // getItemsById
    getAttributesByProduct,
    getWidgetsNoAttributesProduct,
    addAttributeByProduct,
    removeAttributeByProduct,
    getProductCategories,
    updateProductCategory,
    addRemovePropertiesWidgetByProduct,
    getAttributesByGroupAndProduct,
    getMeasures
}

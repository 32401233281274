import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { DOMAIN } from "../config/Url"
import { configActions, configSelectors } from "../redux/config"
import { privateUserOperations } from "../redux/privateUser"
import { publicUserOperations } from "../redux/publicUser"
import {authSelectors} from './../redux/auth'
import Footer from "./Footer"
import Header from "./Header"
import Loading from "./Loading"
import PopupAlert from "./PopupAlert/PopupAlert"


function MainLayout({ children }) {

  const loading = useSelector(configSelectors.getLoading)
  const errCode = useSelector(configSelectors.getErrCode)
  const lang = useSelector(configSelectors.getLang)
  const isAuthenticated = useSelector(configSelectors.getIsAuthenticated)

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(publicUserOperations.initWebSait({
        DEFAULT_COLUMN:lang,
        DOMAIN
    }))

    // if(isAuthenticated){
    //   dispatch(privateUserOperations.getCart({DOMAIN, DEFAULT_COLUMN:lang}))
    // }
  }, [])

  useEffect(()=>{
    if(errCode === 1){
      dispatch(publicUserOperations.initWebSait({
          DEFAULT_COLUMN:lang,
          DOMAIN
      }))

      // if(isAuthenticated){
      //   dispatch(privateUserOperations.getCart({DOMAIN, DEFAULT_COLUMN:lang}))
      // }
    }
  }, [errCode, isAuthenticated])

  return(
    <>
      <Header />
      {children}
      <Footer />
      {loading && <Loading />}

      <PopupAlert />
    </>
  
  );
}; 

const PrivateRoute = ({component:Component, redirectTo, ...routeProps}) =>{
    const isAuthenticated = useSelector(configSelectors.getIsAuthenticated)
    const flag = useSelector(configSelectors.getFlag)
    const dispatch = useDispatch()

    if(!isAuthenticated){
      dispatch(configActions.showLoginRegister(true))
    }else{
      // dispatch(configActions.flag(0))
    }
   
    return <Route 
        {...routeProps} 
        render={props => 
            isAuthenticated ? (<MainLayout><Component {...props} /></MainLayout>) : <Redirect to={redirectTo} />
        }
    />
}

PrivateRoute.defaultProps = {
    redirectTo:'/'
}



export default PrivateRoute
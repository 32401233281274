import { useEffect } from "react";

const Loading = () => {


    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [])

    return (
        <div style={{    
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex:9999999999,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff52'}}>
            <h1 style={{fontSize:100}}>Loading...</h1>
        </div>
    ) 
}

export default Loading
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {DOMAIN} from '../../config/Url'
import PopupEditAddress from "./PopupEditAddress";
import { privateUserOperations } from "../../redux/privateUser";
import { replaceII } from "../../modules/helper";
import { configSelectors } from "../../redux/config";



const Address = ({
    onGetUserAddress,
    getUserAddress,
    errCode,
    isAuthenticated
}) => {

    const [popupEditAddress, setPopupEditAddress] = useState(false);
    const [title, setTitle] = useState('')
    const [type, setType] = useState(0)

    const [billAddress, setBillAddress] = useState({});
    const [shipAddress, setShipAddress] = useState({});

    useEffect(()=>{
        if(isAuthenticated)
        onGetUserAddress({DOMAIN})
    }, [isAuthenticated])

    useEffect(()=>{
        if(errCode === 1){
            onGetUserAddress({DOMAIN}) 
        }
    }, [errCode])

    useEffect(()=>{
        console.log(getUserAddress);

        if(getUserAddress.length !== 0){

            getUserAddress.forEach((v) => {
                const {ADDRESS_TYPE} = v
                if(ADDRESS_TYPE === 0){
                    setBillAddress(v)
                }else if(ADDRESS_TYPE === 1){
                    setShipAddress(v)
                }
            });
        }

    }, [getUserAddress])

    return (
        <>
        <div className="container-fluid">
            <div className="p-2 px-3">
                <h1>Addresses</h1>
            </div>
            <div className="row mobile-view">
                <Sidebar/>
                <div className="col-md-9">
                    <p>The follow<span style={{color:'#4572C2'}}>i</span>ng addresses w<span style={{color:'#4572C2'}}>i</span>ll be used on the checkout page by default.</p>
                    <div className="row py-3">
                        <div className="col-md-6">
                            <div className="pr-2">
                                <div className="border-bottom py-2">
                                    <span className="text-size-custom">b<span style={{color:'#4572C2'}}>i</span>ll<span style={{color:'#4572C2'}}>i</span>ng address</span>
                                   <a className="float-right py-2 color-from-theme pointer" onClick={()=>{setTitle('billing address'); setType(0); setPopupEditAddress(true)}}>{Object.keys(billAddress).length === 0 ? 'Add' : 'Edit'}</a>
                                </div>
                                <div className="py-3">
                                    {Object.keys(billAddress).length === 0 &&  <p>You have not set up th<span style={{color:'#4572C2'}}>i</span>s type of address yet.</p>}
                                    {Object.keys(billAddress).length !== 0 && 
                                    <div>
                                        <div dangerouslySetInnerHTML={{__html:replaceII(billAddress.STREET_ADDRESS)}}></div>
                                        <div dangerouslySetInnerHTML={{__html:replaceII(`${billAddress.TOWN_CITY}, ${billAddress.STATE}`)}}></div>
                                        <div dangerouslySetInnerHTML={{__html:replaceII(`${billAddress.COUNTRY_REGION}, ${billAddress.ZIP_POSTAL_CODE}`)}}></div>
                                    </div>}
                                </div>
                                <div className="py-2">
                                    <input type='checkbox' /> <span>ship to different address</span>
                                </div>
                            </div>  
                        </div>
                        <div className="col-md-6">
                            <div className="pl-2">
                                <div className="border-bottom py-2">
                                    <span className="text-size-custom">sh<span style={{color:'#4572C2'}}>i</span>pp<span style={{color:'#4572C2'}}>i</span>ng address</span>
                                    <a  className="float-right py-2 color-from-theme pointer" onClick={()=>{setTitle('shipping address'); setType(1); setPopupEditAddress(true)}}>{Object.keys(shipAddress).length === 0 ? 'Add' : 'Edit'}</a>
                                </div>
                                <div className="py-3">
                                    {Object.keys(shipAddress).length === 0 &&  <p>You have not set up th<span style={{color:'#4572C2'}}>i</span>s type of address yet.</p>}
                                    {Object.keys(shipAddress).length !== 0 && <div>
                                        <div dangerouslySetInnerHTML={{__html:replaceII(shipAddress.STREET_ADDRESS)}}></div>
                                        <div dangerouslySetInnerHTML={{__html:replaceII(`${shipAddress.TOWN_CITY}, ${shipAddress.STATE} `)}}></div>
                                        <div dangerouslySetInnerHTML={{__html:replaceII(`${shipAddress.COUNTRY_REGION}, ${shipAddress.ZIP_POSTAL_CODE}`)}}></div>
                                    </div>}
                                </div>
                                <div className="py-2">
                                    <input type='checkbox' /> <span>ship address same as billing address</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {popupEditAddress && <PopupEditAddress billAddress={billAddress} shipAddress={shipAddress} type={type} title={title} closePopup={()=>setPopupEditAddress(false)} />}
        </>
    )
}
const mapStateToProps = (state) => ({
    lang: state.config.lang,
    errCode: state.config.errCode,
    getUserAddress: state.privateUser.getUserAddress,
    isAuthenticated: configSelectors.getIsAuthenticated(state),
})

const mapDispatchToProps = {
    onGetUserAddress: privateUserOperations.getUserAddress,
    // onUpdateUserProfile: userPrivateOperations.updateUserProfile,
    // onUpdateUserPassword: userPrivateOperations.updateUserPassword,
}
export default connect(mapStateToProps, mapDispatchToProps)(Address);

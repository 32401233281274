import { combineReducers } from 'redux';
import {createReducer} from '@reduxjs/toolkit';

import authActions from './auth-actions';


const token = createReducer(null, {
    // [authActions.registerSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.loginSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.registrationVendorSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.registrationUserOrVenderSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.logoutSuccess]:() => null
});

const fullAdminName = createReducer(null, {
    // [authActions.registerSuccess]:(_, {payload}) => payload.token?.access_token,
    [authActions.loginSuccess]:(_, {payload}) => payload.result?.FULL_NAME_ADMIN,
    [authActions.registrationUserOrVenderSuccess]:(_, {payload}) => payload.result?.FULL_NAME_ADMIN,
    [authActions.logoutSuccess]:() => null
});

const balance = createReducer({}, {
    [authActions.loginSuccess]:(_, {payload}) =>{ 
        return({
            BALANCE:payload.result?.BALANCE,
            BALANCE_DECIMALS:payload.result?.BALANCE_DECIMALS,
            PUBLICK_ADDRESS:payload.result?.PUBLICK_ADDRESS
        })
    }
})

// const project_id = createReducer(0, {
//     [authActions.registerSuccess]:(_, {payload}) => payload.result?.project_id,
//     [authActions.loginSuccess]:(_, {payload}) => payload.result?.project_id,
//     [authActions.logoutSuccess]:() => 0,

// });

const errCode = createReducer(-1, {
    // [authActions.registerSuccess]:(_, {payload}) => payload.err_code,
    [authActions.loginSuccess]:(_, {payload}) => payload.err_code,
    [authActions.logoutSuccess]:() => -1,

    [authActions.registrationVendorRequest]:() => -1,
    [authActions.registrationUserOrVenderRequest]:() => -1,
    [authActions.forgotPasswordVendorRequest]:() => -1,

    
    [authActions.forgotPasswordVendorSuccess]:(_, {payload}) => payload.err_code,
    [authActions.registrationUserOrVenderSuccess]:(_, {payload}) => payload.err_code,
    [authActions.registrationVendorSuccess]:(_, {payload}) => payload.err_code,
})

const errMessage = createReducer('', {
    // [authActions.registerSuccess]:(_, {payload}) => payload.err_t,
    [authActions.loginSuccess]:(_, {payload}) => payload.err_t,
    [authActions.logoutSuccess]:() => '',
})

const setError = (_, {payload}) => payload;

const error = createReducer(null, {
    // [authActions.registerError]: setError,
    [authActions.loginError]: setError,
});

const lodaing = createReducer(false, {
    // [authActions.registerRequest]:()=> true,
    [authActions.loginRequest]:()=> true,


    // [authActions.registerSuccess]:()=> false,
    [authActions.loginSuccess]:()=> false,

    // [authActions.registerError]:()=> false,
    [authActions.loginError]:()=> false,
})



export default combineReducers({
    token,
    error,
    errCode,
    errMessage,
    lodaing,
    fullAdminName,
    balance,
    isMarketplace: createReducer({}, {
        [authActions.loginSuccess]:(_, {payload}) => payload.result.IS_MARKETPLACE,
        [authActions.registrationVendorSuccess]:(_, {payload}) => payload.result.IS_MARKETPLACE,
    }),
})
import {createAction} from '@reduxjs/toolkit';

export default{
    updateLang:createAction('config/updateLang'),
    updateCurrency:createAction('config/updateCurrency'),
    showFilter:createAction('config/showFilter'),
    showLoginRegister:createAction('config/showLoginRegister'),
    pinCodeStatus:createAction('config/pinCodeStatus'),
    flag:createAction('config/flag'),
    // loading:createAction('config/loading'),
    // errCode:createAction('config/errCode'),
    // errText:createAction('config/errText'),
    claerErrorCode:createAction('model/claerErrorCode'),
    configHttpRequest:createAction('model/configHttpRequest'),
    configHttpSuccess:createAction('model/configHttpSuccess'),
    configHttpError:createAction('model/configHttpError'),
    configErrorCode:createAction('model/configHttpError'),
    logout:createAction('model/logout'),
    loader:createAction('model/loader'),
    status401:createAction('model/status401'),

}
import axios from 'axios';
import authActions from './auth-actions';

axios.defaults.baseURL = 'https://whellli.com:8444/api/public/portal/wpa';

const token = {
    set(token){
        if(token !== null)
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
        else
            axios.defaults.headers.common.Authorization = '';
    },
    unset(){
        axios.defaults.headers.common.Authorization = '';
    }
}

const notToken = () =>{
    axios.defaults.headers.common.Authorization = '';
}

// const register = credential => async dispatch => {

//     dispatch(authActions.registerRequest());

//     try{
//         const res = await axios.post('/', {
//             name:"web_client_registration",
//             parameters:credential
//         });
//         if(res.data.token?.access_token) token.set(res.data.token.access_token);
//         dispatch(authActions.registerSuccess(res.data));

//     }catch (error){
//         dispatch(authActions.registerError(error));
//     }
// }

const login = credential => async dispatch => {
    notToken();

    dispatch(authActions.loginRequest());

    try{
        const res = await axios.post('/', {
            name:"PORTAL_ADMIN_AUTHENTIFICATION",
            parameters:credential
        });
        if(res.data.token?.access_token) token.set(res.data.token.access_token);
        dispatch(authActions.loginSuccess(res.data));
        // dispatch(authActions.loginError(''));
    }catch (err){
        dispatch(authActions.loginError(err));
    }
}

const logout = () => dispatch => {
    // dispatch(authActions.logoutReguest());
    token.unset();
    dispatch(authActions.logoutSuccess());
}


export default {
    // register,
    login,
    logout,
    registrationVendor : credential => async (dispatch, state) => {

        dispatch(authActions.registrationVendorRequest());
    
        try{
            const res = await axios.post('/', {
                name:"portal_registration_vendor",
                parameters:credential
            });
            dispatch(authActions.registrationVendorSuccess(res.data));
    
        }catch (error){
            dispatch(authActions.registrationVendorError(error));
        }
    },

    forgotPasswordVendor : credential => async (dispatch, state) => {

        dispatch(authActions.forgotPasswordVendorRequest());
    
        try{
            const res = await axios.post('/', {
                name:"portal_forgot_password_vendor",
                parameters:credential
            });
            dispatch(authActions.forgotPasswordVendorSuccess(res.data));
    
        }catch (error){
            dispatch(authActions.forgotPasswordVendorError(error));
        }
    },
}

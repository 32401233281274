import React, { lazy, Suspense, useEffect } from 'react';
import {Route, Switch } from 'react-router-dom';

import PublicRoute from './../components/PublicRoute';
import PrivateRoute from './../components/PrivateRoute';
import AppRoute from './../components/AppRoute'

import Loader from './../components/Loader';
import MyAccount from '../views/MyAccount/MyAccount';
import Order from '../views/Order/Order';
import Address from '../views/Address';

const Home = lazy (() => import('./../views/Home'))
const Product = lazy (() => import('./../views/Product'))
const Products = lazy (() => import('./../views/Products'))
const AccountProducts = lazy (() => import('./../views/AccountProducts'))
const Categories = lazy (() => import('./../views/Categories'))
const TopWeeklyVendors = lazy (() => import('./../views/TopWeeklyVendors'))
const DealsOfTheDay = lazy (() => import('./../views/DealsOfTheDay'))
const ConsumerNFT = lazy (() => import('./../views/ConsumerNFT'))
const ConsumerNotNFT = lazy (() => import('./../views/ConsumerNotNFT'))
const Cart = lazy (() => import('./../views/Cart'))
const Thanks = lazy (() => import('./../views/Thanks'))
const Checkout = lazy (() => import('./../views/Checkout'))
const Sales = lazy (() => import('../views/Sales'))
const Delivery = lazy (() => import('../views/Delivery'))
const Dashboard = lazy (() => import('../views/Dashboard'))
const MyWallet = lazy (() => import('../views/MyWallet'))
const WishList = lazy (() => import('../views/WishList'))
const ProductsByCatalog = lazy (() => import('../views/ProductsByCatalog'))
const Search = lazy (() => import('../views/Search'))
const About = lazy (() => import('../views/About'))
const HiiCoin = lazy (() => import('../views/HiiCoin'))
const HiiProtocol = lazy (() => import('../views/HiiProtocol'))
const VendorDetails = lazy (() => import('../views/VendorDetails'))
const ComingSoon = lazy (() => import('./../views/ComingSoon'))
const ComingSoon2 = lazy (() => import('./../views/ComingSoon2'))
const NotFound = lazy (() => import('./../views/NotFound'))
const Privacypolicy = lazy (() => import('./../views/Privacypolicy'))
const Contactus = lazy (() => import('./../views/Contact-us'))


const router = {
    home:'/',
    product:'/product/:id',
    comingSoon:'/coming-soon',
    comingSoon2:'/coming-soon-2',
    myAccount:'/my-account',
    order:'/orders',
    address:'/address',
    products:'/products',
    accountProducts:'/account-products',
    сatalogs:'/сatalogs/:id',
    topWeeklyVendors:'/top-weekly-vendors',
    dealsOfTheDay:'/deals-of-the-day',
    consumerNFT:'/consumer-nft',
    consumerNotNft:'/consumer-not-nft',
    cart:'/cart',
    checkout:'/checkout',
    thanks:'/thanks',
    sales:'/sales',
    delivery:'/delivery',
    dashboard:'/dashboard',
    myWallet:'/my-wallet',
    wishList:'/wish-list',
    about:'/about',
    hiiCoin:'/hiiCoin',
    hiiProtocol:'/hii-protocol',
    productsByCatalog:'/products-by-catalog/:id',
    vendorDetails:'/vendor-details/:id',
    search:'/search',
    privacypolicy:'/privacy-policy',
    Contactus:'/contact-us'

}

export default function Routers(){

    useEffect(()=>{
      window.addEventListener('error', e => {
          if (/Loading chunk [\d]+ failed/.test(e.message)) {
            window.location.reload();
          }
      });
    }, [])

    return(
     
    <Suspense fallback={<Loader/>}>
      <Switch>
        <AppRoute exact path={router.comingSoon}  component={ComingSoon}  />
        <AppRoute exact path={router.comingSoon2}  component={ComingSoon2}  />

        <PublicRoute exact path={router.home}  component={Home}  />
        <PublicRoute exact path={router.product}  component={Product}  />
        <PublicRoute exact path={router.products}  component={Products}  />
        <PublicRoute exact path={router.сatalogs}  component={Categories}  />
        <PublicRoute exact path={router.topWeeklyVendors}  component={TopWeeklyVendors}  />
        <PublicRoute exact path={router.dealsOfTheDay}  component={DealsOfTheDay}  />
        <PublicRoute exact path={router.consumerNFT}  component={ConsumerNFT}  />
        <PublicRoute exact path={router.consumerNotNft}  component={ConsumerNotNFT}  />
        <PublicRoute exact path={router.thanks}  component={Thanks}  />
        <PublicRoute exact path={router.productsByCatalog}  component={ProductsByCatalog}  />
        <PublicRoute exact path={router.search}  component={Search}  />
        <PublicRoute exact path={router.about}  component={About}  />
        <PublicRoute exact path={router.privacypolicy}  component={Privacypolicy} />
        <PublicRoute exact path={router.Contactus}  component={Contactus} />
        <PublicRoute exact path={router.hiiCoin}  component={HiiCoin}  />
        <PublicRoute exact path={router.hiiProtocol}  component={HiiProtocol}  />
        <PublicRoute exact path={router.vendorDetails}  component={VendorDetails}  />

        <PrivateRoute  path={router.myAccount}  component={MyAccount} redirectTo={router.home} />
        <PrivateRoute  path={router.order}  component={Order} redirectTo={router.home} />
        <PrivateRoute  path={router.address}  component={Address} redirectTo={router.home} />
        <PrivateRoute  path={router.accountProducts}  component={AccountProducts} redirectTo={router.home} />
        <PrivateRoute  path={router.cart}  component={Cart} redirectTo={router.home} />
        <PrivateRoute  path={router.checkout}  component={Checkout} redirectTo={router.home} />
        <PrivateRoute  path={router.sales}  component={Sales} redirectTo={router.home} />
        <PrivateRoute  path={router.delivery}  component={Delivery} redirectTo={router.home} />
        <PrivateRoute  path={router.dashboard}  component={Dashboard} redirectTo={router.home} />
        <PrivateRoute  path={router.myWallet}  component={MyWallet} redirectTo={router.home} />
        <PrivateRoute  path={router.wishList}  component={WishList} redirectTo={router.home} />
        
        {/* <PrivateRoute 
            exact
            path={router.home} 
            component={Home} 
            redirectTo={router.login}
        />  */}
        {/* <PublicRoute 
            path={router.auth} 
            component={Auth} 
            restricted
            redirectTo={router.home} 
        /> */}


        <AppRoute component={NotFound} />
          

      </Switch>
      </Suspense>
    );
}

import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { DOMAIN, WEB_PICTURES_FILE, WEB_PICTURES_FILE_RESIZER } from "../../config/Url"
import { configActions, configSelectors } from "../../redux/config"
import { publicUserOperations } from "../../redux/publicUser"
import Button from "../Button/Button"
import Popup from "../Popup"
import {Base64Decode, replaceII} from './../../modules/helper'
import Logo from './../../asstets/img/header/Logo.png'

import bit from './../../asstets/svg/bitcion.svg'
import eth from './../../asstets/svg/eth.svg'
import mastr from './../../asstets/svg/master.svg'
import pay from './../../asstets/svg/paypal.svg'
import visa from './../../asstets/svg/visa.svg'

import discord from './../../asstets/svg/discord-1.svg'
import reddit from './../../asstets/svg/reddit-1.svg'
import slack from './../../asstets/svg/slack.svg'
import twitter from './../../asstets/svg/tiwer.svg'




const infoContacts = ['FACEBOOK', 'INSTAGRAM', 'TIKTOK', 'TWITTER']

const Footer = ({
    initWebSait,
    isAuthenticated,
    onShowLoginRegister,
    onCreateSubscribe,
    errCode
}) => {

    const [payment, setPayment] = useState([])
    const [legal, setLegal] = useState([])
    const [partners, setPartner] = useState([])

    const [flag, setFlag] = useState(0)
    const [flagValid, setFlagValid] = useState(0)
    const [email, setEmail] = useState('')
    const [show, setShow] = useState(false)


    const [contacts, setContacts] = useState({
        EMAIL_SALES: "",
        EMAIL_SUPPORT: "",
        ID_WEB_SITE: 0,
        LOGO_FACEBOOK: "",
        LOGO_INSTAGRAM: "",
        LOGO_TIKTOK: "",
        LOGO_TWITTER: "",
        PHONE_SALES: "",
        PHONE_SUPPORT: "",
        STATUS_FACEBOOK: 0,
        STATUS_INSTAGRAM: 0,
        STATUS_TIKTOK: 0,
        STATUS_TWITTER: 0,
        URL_FACEBOOK: "",
        URL_INSTAGRAM: "",
        URL_TIKTOK: "",
        URL_TWITTER: "",
        FOLLOW_US_STATUS: 0,
        FOLLOW_US_TEXT: "",
        FOLLOW_US_TEXT_CODE: 0,
        SALES_CONTACTS_STATUS: 0,
        SALES_CONTACTS_TEXT: "",
        SALES_CONTACTS_TEXT_CODE: 0,
    })

    useEffect(()=>{
        if(Object.keys(initWebSait).length !== 0){
            const { MAIN_INFO, CONTACTS_INFO, LEGAL_INFO, PARTNERS_INFO, PAYMENT_INFO } = initWebSait

            // setMainInfo(MAIN_INFO)
            setPayment(PAYMENT_INFO['#result-set-1'])
            setLegal(LEGAL_INFO['#result-set-1'])
            setPartner(PARTNERS_INFO['#result-set-1'])
            setContacts(CONTACTS_INFO)
        }
    }, [initWebSait])

    useEffect(()=>{
        if(errCode === 1 && flag === 1){
            setFlag(0);
            setFlagValid(0)
            setEmail('')
            setShow(true)
        }
    }, [errCode])

    const subscribe = () => {
        setFlag(1);

        if(email === ''){
            setFlagValid(1)
            return
        }

        onCreateSubscribe({
            DOMAIN,
            email
        }) 
    }

    return (
        <>
       <footer id="subscribe">

            <Container  fluid >
                <Row style={{padding:0}}>
                    <Col md={6} style={{paddingLeft:0, paddingTop:0}}>
                        <Container style={{padding:0}}>
                            <Row>
                                <Col md={8} style={{paddingLeft:15}}>
        
                                    <div className="emptyspace"></div>
        
                                    <img src={Logo} style={{objectFit: 'contain', width:'100%', maxWidth: 348}}  />
                                    <br/>
                                    <i className="lowercase" dangerouslySetInnerHTML={{__html:replaceII('hiiroad metamarket, brought to you by the hiiroad foundation.  Making all lives a little bit better through your generous support')}} ></i>
                                    
                                    {contacts.FOLLOW_US_STATUS === 1 &&  
                                        <>
                                            {/* 
                                            <br/>
                                            <br/>
                                            <br/> 
                                            */}
                                            <div className="emptyspace"></div>
                                        
                                            <div className="footerUlTitle" style={{position: 'relative'}}>
                                                <div className="footerHead lowercase" dangerouslySetInnerHTML={{__html:replaceII(Base64Decode(contacts.FOLLOW_US_TEXT))}}></div>
                                                <ul style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: `repeat(${infoContacts.length}, 36px)`,
                                                    gap: '10px'
                                                }}>
                                                   
                                                    {/* <a href="https://discord.gg/5UThQSw5ux">
                                                    <img src={discord} width="36px" height="36px" />
                                                    </a>
                                                    <a href="https://www.reddit.com/r/hiiroad/comments/s00w2k/rhiiroad_lounge/">
                                                    <img src={reddit} width="36px" height="36px" />
                                                    </a>
                                                    <a href="https://slack.com/intl/en-in/">
                                                    <img src={slack} width="36px" height="36px" />
                                                    </a>
                                                    <a href="https://twitter.com/i/flow/login">
                                                    <img src={twitter} width="36px" height="36px" />
                                                    </a> */}

                                                    {infoContacts.length !== 0 &&
                                                        infoContacts.map((v, k)=>{
                                                            if(contacts[`STATUS_${v}`] === 1 && contacts[`LOGO_${v}`] !== '')
                                                                return(<li key={k}>
                                                                            <a href={`${contacts[`URL_${v}`]}`} target='_blank' className="px-2 py-2">
                                                                                <img src={`${WEB_PICTURES_FILE}${contacts[`LOGO_${v}`]}`} width="36px" height="36px" />
                                                                                
                                                                            </a>
                                                                        </li>)
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </>
                                    }
                                </Col>
                                <Col md={4}>

                                <div className="emptyspace"></div>

                                <div className="footerUlTitle">
                                    <span>metamarket</span>
                                    <ul className="lowercase">
                                        {initWebSait?.CATALOGS_MARKETPLACE?.map(({CATEGORY_ID, CATEGORY_NAME}, k)=>{
                                            return(<li key={k}><Link to={`/products-by-catalog/${CATEGORY_ID}`} style={{fontWeight:'normal', lineHeight:'normal'}} dangerouslySetInnerHTML={{__html:replaceII(Base64Decode(CATEGORY_NAME))}} ></Link></li>)
                                        })}
                                    </ul>
                                </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={6} style={{paddingLeft:0, paddingTop:0}}>
                        <Container style={{padding:0}}>
                            <Row>
                                <Col md={6}>

                                <div className="emptyspace"></div>

                                    <div className="footerUlTitle lowercase">
                                        <span>My Account</span>
                                        <ul>

                                            <li>
                                                {isAuthenticated && <Link to="/my-account" className="linkfoo" dangerouslySetInnerHTML={{__html:replaceII('My Profile')}}></Link>}
                                                {!isAuthenticated && <Link to="#" onClick={()=>onShowLoginRegister(true)} className="linkfoo" dangerouslySetInnerHTML={{__html:replaceII('My Profile')}}></Link>}
                                            </li>
                                            {/* <li>
                                                {isAuthenticated &&  <Link to="/my-account" className="linkfoo">My Collections</Link>}
                                                {!isAuthenticated &&  <Link to="#" onClick={()=>onShowLoginRegister(true)} className="linkfoo">My Collections</Link>}
                                            </li> */}
                                            <li>
                                                <NavLink to="/wish-list" className="cartView" style={{fontWeight:'400'}}>
                                                    <span dangerouslySetInnerHTML={{__html:replaceII('following')}}></span>
                                                </NavLink>
                                            </li>
                                            {/* <li>
                                                {isAuthenticated && <Link to="/my-account" className="linkfoo">My Account Settings</Link>}
                                                {!isAuthenticated && <Link to="#" onClick={()=>onShowLoginRegister(true)} className="linkfoo">My Account Settings</Link>}
                                            </li> */}

                                        </ul>

                                        {/* <a href="tel:0(800)123-456" className="phoneLink mt-5">
                                            <i className="phone"></i>
                                            <div>
                                                <div>Live Chat or:</div>
                                                <b style={{fontSize:13}}>0 (800) 123-456</b>
                                            </div>
                                        </a> */}
                                    </div>
                                </Col>
                                <Col md={6}>
                                <div className="emptyspace"></div>
                                    <div className="footerUlTitle lowercase">
                                        <span>Resources</span>
                                        {/* <ul>
                                            <li dangerouslySetInnerHTML={{__html:replaceII('privacy policy')}}></li>
                                        </ul> */}
                                        <ul>
                                            <li>
                                            <Link  style={{fontWeight:'400'}} className="navbar-brand" to="/privacy-policy">Privacy Policy
                                            </Link>
                                            </li>
                                        </ul>
                                        

                                        {/* <a className="phoneLink mt-2" style={{width:'fit-content'}}>
                                                <i className="comp"></i>
                                            <div>
                                            <div>Live Chat or:</div>
                                                <b style={{fontSize:13}} dangerouslySetInnerHTML={{__html:replaceII('hii chat')}}></b>
                                            </div>
                                        </a> */}
                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col className="lowercase" style={{paddingRight:0}}>
                                <div className="emptyspace"></div>
                                    <div className="footerHead" dangerouslySetInnerHTML={{__html:replaceII('Stay in the loop')}}></div>
                                    <p className="footerTitle" dangerouslySetInnerHTML={{__html:replaceII('Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks for navigating HiiRoad metamarket')}}></p>
                            
                                    <div className="d-flex">
                                        <div style={{width:'75%'}}>
                                            <input type="email" className={["input-email", email === '' && flagValid === 1 ? 'errorValid' : ''].join(' ')} style={{height: 57}} placeholder="Enter Your Email" value={email} onChange={({target:{value}})=>setEmail(value)} />
                                            {email === '' && flagValid === 1 && <small style={{color:'red'}}>Required field</small>}
                                        </div>
                                        <button className="SubscribeEmail" onClick={()=>subscribe()}>join us</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

            </Container>
            <div style={{marginTop: 45, width:'100%',  height: 1, background: '#131921', opacity: 0.2}}></div>
            <Container fluid>
                <Row style={{margin: '12px 0'}}>
                    <Col md={8} style={{padding:0}}>
                            <div style={{display: 'flex', alignContent: 'center', flexWrap: "wrap"}}>
                                <div className="paySecurely" style={{marginRight:12}} dangerouslySetInnerHTML={{__html:replaceII(Base64Decode(initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_TEXT))}}></div>
                                {/* <div className="paySecurely" style={{marginRight:12}} > {initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_STATUS === 1 && Base64Decode(initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_TEXT)} </div> */}
                                <div>
            
                                    {Object.keys(initWebSait).length !== 0 && initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_STATUS === 1 && payment?.map(({STATUS, TYPE, ICON_NAME, ICON_1, ICON_2}, k)=>{
                                
                                        if(STATUS === 1 && TYPE !== 1 && ICON_NAME !== '')
                                            return(<img key={k} src={`${WEB_PICTURES_FILE}${ICON_NAME}`} height='38' style={{marginRight:12}} />)
                                        
                                        if(STATUS === 1 && TYPE === 1)
                                            return(
                                            <span key={k} >
                                                {ICON_1 !== '' && <img  src={`${WEB_PICTURES_FILE}${ICON_1}`} height='38' style={{marginRight:12}} />}
                                                {ICON_2 !== '' && <img  src={`${WEB_PICTURES_FILE}${ICON_2}`} height='38' style={{marginRight:12}} />}
                                            </span>)
                                    
                                    })}
                                </div>
                                {/* <img src={bit} style={{marginRight:10}} height='38' />
                                <img src={eth} style={{marginRight:10}} height='38' />
                                <img src={mastr} style={{marginRight:10}} height='38' />
                                <img src={pay} style={{marginRight:10}} height='38' />
                                <img src={visa} height='38' /> */}
                            </div>

                        
                    </Col>
                    <Col md={4} style={{textAlign:'end', paddingTop: 6}} dangerouslySetInnerHTML={{__html:replaceII(initWebSait?.MAIN_INFO?.COPYRIGHT_FOOTER)}}></Col>
                </Row>
            </Container>

           {/* <Container className="widthHeader" >
               <Row>
                   <Col md={9}>
                        <div className="footerHead">Stay in the loop</div>
                        <p className="footerTitle">Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks for navigating hiiRoad.</p>
                   
                        <div className="d-flex">
                            <div style={{width:'70%'}}>
                                <input type="email" className={["input-email", email === '' && flagValid === 1 ? 'errorValid' : ''].join(' ')} style={{height: 57}} placeholder="Enter Your Email" value={email} onChange={({target:{value}})=>setEmail(value)} />
                                {email === '' && flagValid === 1 && <small style={{color:'red'}}>Required field</small>}
                            </div>
                            <button className="SubscribeEmail" onClick={()=>subscribe()}>Subscribe</button>
                        </div>
                   </Col>
                   {contacts.FOLLOW_US_STATUS === 1 &&  
                        <Col md={3}>
                            <div className="footerUlTitle" style={{position: 'relative'}}>
                                <div className="footerHead">{Base64Decode(contacts.FOLLOW_US_TEXT)}</div>
                                <ul style={{
                                    display: 'grid',
                                    gridTemplateColumns: `repeat(${infoContacts.length}, 36px)`,
                                    gap: '10px'
                                }}>

                                    {infoContacts.length !== 0 &&
                                        infoContacts.map((v, k)=>{
                                            if(contacts[`STATUS_${v}`] === 1 && contacts[`LOGO_${v}`] !== '')
                                                return(<li key={k}>
                                                            <a href={`${contacts[`URL_${v}`]}`} target='_blank' className="px-2 py-2">
                                                                <img src={`${WEB_PICTURES_FILE}${contacts[`LOGO_${v}`]}`} width="36px" height="36px" />
                                                            </a>
                                                        </li>)
                                        })
                                    }

                                </ul>
                            </div>
                        </Col>
                    }
               </Row>
               <Row style={{marginTop:70}}>

                    <Col md={3} >
                        <i className="logo" />
                        <div className="imgName">take the <span>h</span>iiroad to happiness</div>
                        <p className="logoText">hiiroad metamarket, brought to you by the hiiroad foundation.  Making all lives a little bit better through your generous support</p>
                   </Col>

                   <Col md={3} style={{paddingLeft:65}}>
                            <div className="footerUlTitle">
                                <span>metamarket</span>
                                <ul>

                                    {initWebSait?.CATALOGS_MARKETPLACE?.map(({CATEGORY_ID, CATEGORY_NAME}, k)=>{
                                        return(<li key={k}><Link to={`/products-by-catalog/${CATEGORY_ID}`} style={{fontWeight:'normal', lineHeight:'normal'}} >{Base64Decode(CATEGORY_NAME)}</Link></li>)
                                    })}

                      

                                </ul>
                            </div>
                   </Col>
                   <Col md={3} style={{paddingLeft:65}}>
                            <div className="footerUlTitle">
                                <span>My Account</span>
                                <ul>

                                    <li>
                                       {isAuthenticated && <Link to="/my-account" className="linkfoo">My Profile</Link>}
                                       {!isAuthenticated && <Link to="#" onClick={()=>onShowLoginRegister(true)} className="linkfoo">My Profile</Link>}
                                    </li>
                                    <li>
                                        {isAuthenticated &&  <Link to="/my-account" className="linkfoo">My Collections</Link>}
                                        {!isAuthenticated &&  <Link to="#" onClick={()=>onShowLoginRegister(true)} className="linkfoo">My Collections</Link>}
                                    </li>
                                    <li>My Favorites</li>
                                    <li>
                                        {isAuthenticated && <Link to="/my-account" className="linkfoo">My Account Settings</Link>}
                                        {!isAuthenticated && <Link to="#" onClick={()=>onShowLoginRegister(true)} className="linkfoo">My Account Settings</Link>}
                                    </li>

                                </ul>

                                <a href="tel:0(800)123-456" className="phoneLink mt-5">
                                    <i className="phone"></i>
                                    <div>
                                        <div>Live Chat or:</div>
                                        <b style={{fontSize:13}}>0 (800) 123-456</b>
                                    </div>
                                </a>
                            </div>
                   </Col>
                   <Col md={3} style={{paddingLeft:65}}>
                            <div className="footerUlTitle">
                                <span>Resources</span>
                                <ul>

                                    <li>Help Center</li>
                                    <li>Partners</li>
                                    <li>Suggestions</li>
                                    <li>Discord Community</li>
                                    <li>Blog</li>
                                    <li>Docs</li>
                                    <li>Newsletter</li>


                                </ul>
                            </div>
                   </Col>

           
                    
               </Row>
           </Container>
           <div style={{marginTop: 45, width:'100%',  height: 1, background: '#131921', opacity: 0.2}}></div>
           <Container className="widthHeader" style={{padding:'12px 130px'}}>
               <Row>
                   <Col md={9}>
                        <div style={{display: 'flex', alignContent: 'center'}}>
                            <div className="paySecurely" style={{marginRight:12}}> {initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_STATUS === 1 && Base64Decode(initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_TEXT)}</div>
                            <div>
        
                                {Object.keys(initWebSait).length !== 0 && initWebSait?.PAYMENT_INFO?.PAYMENT_METHOD_STATUS === 1 && payment?.map(({STATUS, TYPE, ICON_NAME, ICON_1, ICON_2}, k)=>{
                               
                                    if(STATUS === 1 && TYPE !== 1 && ICON_NAME !== '')
                                        return(<img key={k} src={`${WEB_PICTURES_FILE}${ICON_NAME}`} height='38' style={{marginRight:12}} />)
                                    
                                    if(STATUS === 1 && TYPE === 1)
                                        return(
                                        <span key={k} >
                                            {ICON_1 !== '' && <img  src={`${WEB_PICTURES_FILE}${ICON_1}`} height='38' style={{marginRight:12}} />}
                                            {ICON_2 !== '' && <img  src={`${WEB_PICTURES_FILE}${ICON_2}`} height='38' style={{marginRight:12}} />}
                                        </span>)
                                
                                })}
                            </div>
                        </div>

                       
                   </Col>
                   <Col md={3} style={{textAlign:'end', paddingTop: 12}}>{initWebSait?.MAIN_INFO?.COPYRIGHT_FOOTER}</Col>
               </Row>
           </Container> */}
       </footer>

       {show && <Popup  width={'600px'} closePopup={()=>setShow(false)}>
            <p style={{margin: '27px 20px', textAlign:'center', fontSize:25}}>You has been subscribed </p>
            <div className="text-center">
                <Button className="button-16"  onClick={()=>{setShow(false)}}>close</Button>
            </div>
        </Popup>}
       </>
    ) 
}
const mapStateToProps = (state) => ({
    // currency:state.config.currency,
    // lang:state.config.lang,
    errCode:state.config.errCode,
    initWebSait:state.publicUser.initWebSait,
    isAuthenticated: configSelectors.getIsAuthenticated(state),
})
const mapDispatchToProps = {
    // onUpdateCurrency:configActions.updateCurrency,
    // onUpdateLang:configActions.updateLang,
    onCreateSubscribe:publicUserOperations.createSubscribe,
    onShowLoginRegister:configActions.showLoginRegister,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
// export default Footer
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { configActions, configSelectors } from "../../redux/config";
import privateUserOperations from "../../redux/privateUser/privateUser-operations";
import {DOMAIN} from './../../config/Url'

const Sidebar = ({
    onLogoutUser,
    errCode,
    isAuthenticated,
    lang,
    isVendor,
    onFlag,
    // onLogoutSuccess,
    logoutUser,
    // onGetCartClear
}) => {

    const history = useHistory();
    const dispatch = useDispatch()

    const [flag, setFlag] = useState(0)
    

    useEffect(()=>{
        if(errCode === 1 && flag === 1){
            // history.push(`/`)
            // setTimeout(()=>{
                // window.location.href = '/'
            // }, 500)
            setFlag(0)
        }
    }, [errCode, isAuthenticated])

    useEffect(()=>{
        if(Object.keys(logoutUser).length > 0){
            const {err_code} = logoutUser;
            if(err_code === 1){
                // window.location.href = '/'
            }
        }
    }, [logoutUser])

    const logout = () => {
        dispatch(configActions.flag(1))
        onLogoutUser({DOMAIN, DEFAULT_COLUMN:lang})
        setFlag(0)

    }

    // useEffect(()=>{
    //     if(logoutUser === 1){
    //         // onGetCartClear({})
    //         onLogoutSuccess()
    //         history.push(`/`)
    //     }
    // }, [logoutUser])

    return (
        <div className="col-md-3" style={{display: 'inline', flexWrap: 'wrap', justifyContent: 'space-between', fontSize: 15}}>
            {/* <div className="border-top">

                  <NavLink activeStyle={{fontWeight:'bold'}} to="/dashboard">  <button type="button" className="bg-transparent border-0 py-2">Dashboard</button></NavLink>

                <i className="fa fa-tachometer float-right mt-2"></i>
            </div> */}

            {isVendor === 0 && <>

                {/* <div className="border-bottom mr-2" style={{width:160}}>
                <NavLink activeStyle={{fontWeight:'bold'}} to="/dashboard" style={{color:'#000'}}>   <button type="button" className="bg-transparent border-0 py-2">Dashboard</button></NavLink>
                    <i className="fa fa-tachometer float-right mt-2"></i>
                </div> */}

                <div className="border-bottom mr-2" style={{width:160}}>
                <NavLink activeStyle={{fontWeight:'bold'}} to="/orders" style={{color:'#000'}}>   <button type="button" className="bg-transparent border-0 py-2">orders</button></NavLink>
                    <i className="fa fa-shopping-bag float-right mt-2"></i>
                </div>


                <div className="border-bottom mr-2 abc" style={{width:160}}>
                    <NavLink activeStyle={{fontWeight:'bold'}} to="/address" style={{color:'#000'}}>  <button type="button" className="bg-transparent border-0 py-2">addresses</button></NavLink>
                    <i className="fa fa-map-marker float-right mt-2"></i>
                </div>

                <div className="border-bottom mr-2" style={{width:160}}>
                    <NavLink activeStyle={{fontWeight:'bold'}} to="/my-account" style={{color:'#000'}}>   <button type="button" className="bg-transparent border-0 py-2">account deta<span style={{color:'#4572C2'}}>i</span>ls</button></NavLink>
                    <i className="fa fa-user float-right mt-2"></i>

                </div>

                <div className=" border-bottom mr-2" style={{width:160}} onClick={()=>logout()}>

                    <button type="button" className="bg-transparent border-0 py-2">logout</button>
                    <i className="fa fa-sign-out float-right mt-2"></i>

                </div>

            </>}

            {isVendor === 1 && <>

                <div className="border-bottom mr-2" style={{width:"100%", borderTop: "1px solid #dee2e6"}}>
                <NavLink activeStyle={{fontWeight:'bold'}} to="/dashboard" style={{color:'#4a4a4a'}}>   <button type="button" className="bg-transparent border-0 py-2">dashboard</button></NavLink>
                    <i className="fa fa-tachometer float-right mt-2"></i>
                </div>
                <div className="border-bottom mr-2" style={{width:"100%"}}>
                <NavLink activeStyle={{fontWeight:'bold'}} to="/orders" style={{color:'#4a4a4a'}}>   <button type="button" className="bg-transparent border-0 py-2">orders</button></NavLink>
                    <i className="fa fa-shopping-bag float-right mt-2"></i>
                </div>
                <div className="border-bottom mr-2" style={{width:"100%"}}>
                    <NavLink activeStyle={{fontWeight:'bold'}} to="/address" style={{color:'#4a4a4a'}}>  <button type="button" className="bg-transparent border-0 py-2">addresses</button></NavLink>
                    <i className="fa fa-map-marker float-right mt-2"></i>
                </div>

                {/* <div className="border-bottom mr-2" style={{width:160}}>
                <NavLink activeStyle={{fontWeight:'bold'}} to="/account-products" style={{color:'#000'}}>   <button type="button" className="bg-transparent border-0 py-2">Products</button></NavLink>
                    <i className="fa fa-shopping-bag float-right mt-2"></i>
                </div> */}



                <div className="border-bottom mr-2" style={{width:"100%"}}>
                    <NavLink activeStyle={{fontWeight:'bold'}} to="/my-account" style={{color:'#4a4a4a'}}>   <button type="button" className="bg-transparent border-0 py-2">account deta<span style={{color:'#4572C2'}}>i</span>ls</button></NavLink>
                    <i className="fa fa-user float-right mt-2"></i>

                    </div>

                <div className="border-bottom mr-2"  style={{width:"100%"}} onClick={()=>logout()}>

                    <button type="button" className="bg-transparent border-0 py-2">logout</button>
                    <i className="fa fa-sign-out float-right mt-2"></i>

                </div>

            </>}


        </div>
    )
}
const mapStateToProps = (state) => ({
    lang:state.config.lang,
    errCode:state.config.errCode,
    logoutUser:state.privateUser.logoutUser,
    isAuthenticated: configSelectors.getIsAuthenticated(state),
    isVendor:state.config.resultUser.IS_VENDOR || 0
})

const mapDispatchToProps = {
    onLogoutUser:privateUserOperations.logoutUser,
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

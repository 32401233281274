import { Base64 } from "js-base64"
import { useEffect } from "react"
import { connect } from "react-redux"
import Popup from "../../components/Popup/Popup"
import PriceToken from "../../components/PriceToken/PriceToken"
import {DOMAIN, WEB_PICTURES_FILE_RESIZER} from '../../config/Url'
import { privateUserOperations } from "../../redux/privateUser"



const PopupOrder = ({
    lang,
    onGetUserOrderById,
    getUserOrderById,
    closePopup,
    orderId:ID_ORDER,
    currency
}) => {

    useEffect(()=>{
        onGetUserOrderById({
            DOMAIN,
            DEFAULT_COLUMN:lang,
            ID_ORDER
        })
    }, [])

    return (
        <Popup  title={`Order ${ID_ORDER} products list`} closePopup={closePopup} overflow width="80vw">

            <span onClick={()=>closePopup()} style={{position: 'absolute', top: -38, right: 21, fontSize: 40, cursor: 'pointer'}}>&times;</span>


            <div className="tablee mx-3 my-2">
                <table className="table border-bottom table-cart" style={{paddingBottom: 0}}>
                    <thead className="tbl-head-border">
                    <tr>
                        <th width="12%"></th>
                        <th>Product</th>
                        <th>Pr<span style={{color:'#4572C2'}}>i</span>ce</th>
                        <th>D<span style={{color:'#4572C2'}}>i</span>scount</th>
                        <th>Quant<span style={{color:'#4572C2'}}>i</span>ty</th>
                        <th>Reward </th>
                        <th>Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                        {getUserOrderById?.PRODUCTS?.length !== 0 &&
                            getUserOrderById?.PRODUCTS?.map(({IMAGE, NAME, QTY, PRODUCT_PRICE, DISCOUNT, AMOUNT, LOYALTY_DISCOUNT, NAME_RECORD, LOYALTY_CASHBACK}, k)=>{

                                return(<tr>
                                    <td>
                                        <img src={`${WEB_PICTURES_FILE_RESIZER}${IMAGE}/100`} alt="" className="img-class" width={100}   />
                                    </td>
                                    <td>
                                        {Base64.decode(NAME)}
                                        {NAME_RECORD !== '' && <><br/> <span>Opt<span style={{color:'#4572C2'}}>i</span>on: {Base64.decode(NAME_RECORD)}</span></>}
                                    </td>
                                    <td><PriceToken price={PRODUCT_PRICE} /></td>
                                    <td><PriceToken price={DISCOUNT} /></td>
                                    <td>{QTY}</td>
                                    <td><PriceToken price={LOYALTY_CASHBACK} type="VALUE" /></td>
                                    <td><PriceToken price={AMOUNT} /></td>
                                </tr>)
                            })
                        }

                        {getUserOrderById?.PRODUCTS?.length === 0 && <tr><td colSpan="6" style={{textAlign: 'center', fontSize: 36}}> Cart <span style={{color:'#4572C2'}}>i</span>s empty </td></tr>}
   
                    </tbody>
                </table>
            </div>
        </Popup>
    ) 
}
const mapStateToProps = (state) => ({
    lang:state.config.lang,
    currency:state.config.currency,
    errCode:state.config.errCode,

    getUserOrderById:state.privateUser.getUserOrderById,

})

const mapDispatchToProps = {
    onGetUserOrderById:privateUserOperations.getUserOrderById,
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupOrder);
